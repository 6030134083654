import React from 'react';
import Ustkisim from './inc/Header';
import Footer from './inc/Footer';
import {seo} from '../helpers';

 
class Websites extends React.Component {
	constructor(props) {
		super(props);
		this.state = {websites: []};
		this.headers = [
			{ key: 'id', label: 'Id'},
			{ key: 'title', label: 'Title' },
			{ key: 'url', label: 'URL' }
		];
		this.deleteWebsite = this.deleteWebsite.bind(this);
	}
	
	componentDidMount() {

			seo({
			  title: 'Maya Dashboard',
			  metaDescription: 'Maya Ana Ekranı'
			});


	}
	
	deleteWebsite(id) {
		if(window.confirm("Are you sure want to delete?")) {
			fetch('http://localhost/maya3/index.php/websiterestcontroller/delete_website/' + id, {
                                method : 'DELETE'
                                   }).then(response => { 
					if(response.status === 200) {
						alert("Website deleted successfully");
                                                fetch('http://localhost/maya3/index.php/websiterestcontroller/websites')
						.then(response => {
							return response.json();
						}).then(result => {
							console.log(result);
							this.setState({
								websites:result
							});
						});
					} 
			 });
		}
	}
	
	render() {



		return (

			<div>
		
				<Ustkisim />

				<div className="main-content side-content pt-0">
    <div className="container-fluid">

    <div id="global-loader-page">
        <div className="loader-img-page">
        <img src="../assets/img/loader.svg" />
        <div className="LoaderText">Lütfen Bekleyiniz...</div>
        </div>
			
		</div>

      <div className="inner-body">
        {/* Page Header */}
        <div className="page-header">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Merhaba, Cemal</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="#">Atlas</a></li>
              <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
            
              <button type="button" className="btn btn-white btn-icon-text my-2 mr-2">
                <i className="fe fe-filter mr-2" /> Filtrele
              </button>
              <button type="button" className="btn btn-primary my-2 btn-icon-text">
                <i className="fe fe-download-cloud mr-2" /> Raporu İndir
              </button>
            </div>
          </div>
        </div>
    </div>
    </div>
  </div>
  <Footer />
				
			</div>
		)
	}
}

export default Websites;