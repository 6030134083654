import React, { button } from "react";
import { Link } from "react-router-dom";
import Ustkisim from "../inc/Header";
import Footer from "../inc/Footer";
import { seo } from "../../helpers";
import { config } from "../../config";
import Moment from "moment";
import "moment/locale/tr";
import MaterialTable from "material-table";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Loader from "react-loaders";
import cogoToast from "cogo-toast";
import Dropzone from "react-dropzone";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
const tableRef = React.createRef();
class FaqCategorys extends React.Component {
  constructor(props, context) {
    super(props);
    const datePickerId = moment(new Date()).format("YYYY-MM-DD[T]HH:mm");

    this.state = {
      edit: {
        status: false,
        id: "",
        name: ""
      },
      create: { status: false },
      formdata: { name: "" },
      mindate: datePickerId,
      load_detail: false,
    };
    this.state.BASE_URL = config().BASE_URL;

    this.handleChange = this.handleChange.bind(this);
    this.UpdateChange = this.UpdateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.UpdateSubmit = this.UpdateSubmit.bind(this);

    this.onDrop = (file) => {
      this.setState({
        file: file,
      }, () => {
        this.addImage();
    });
     
    };

    this.onDropc = (file) => {
      this.setState({
        file: file,
      }, () => {
        this.addImagec();
    });
     
    };
  }

  componentDidMount() {
      
    seo({
      title: "S.S.S. Kategorileri | Maya",
      metaDescription: "Atlas Ana Ekranı",
    });
  }

  addCategory = () => {
    this.setState({ create: { status: true } });
    this.setState({
      edit: {
        id: "",
        name: "",
        image: "",
        status: false,
      },
    });
  };

  kaldir = () => {
    const slidem = this.state.edit ; 
    slidem.image = "" ; 
    this.setState({
      slidem
    });


  }
    
  kaldirc = () => {
    const slidem = this.state.formdata ; 
    slidem.image = "" ; 
    this.setState({
      slidem
    });


  }

  addImage = () => {

    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/slide", {
       method: 'POST',
       body: data
         }).then((response) => {
          return response.json();
        })
        .then((result) => {



          if (!result.success) {
            cogoToast.warn(
              "Resim Yüklenirken Sorun Oluştu.",
              { position: "top-right" }
            );
          }
          else 
          {
            cogoToast.success(
              "Resim Başarılı Bir Şekilde Yüklendi.",
              { position: "top-right" }
            );
            const slider = this.state.edit ;
            slider.image = result.url ;
            this.setState({
              slider
            });
            
          }
          this.setState({
            loadImage: false,
          });
            console.log(result);
        });
    }

    addImagec = () => {

      this.setState({
        loadImage: true,
      });
      const file = this.state.file[0];
      const file_name = file.name;
      let data = new FormData();
      data.append('image', file);
      data.append('image_name', file_name);
  
      fetch(this.state.BASE_URL + "/api/upPro/slide", {
         method: 'POST',
         body: data
           }).then((response) => {
            return response.json();
          })
          .then((result) => {
  
  
  
            if (!result.success) {
              cogoToast.warn(
                "Resim Yüklenirken Sorun Oluştu.",
                { position: "top-right" }
              );
            }
            else 
            {
              cogoToast.success(
                "Resim Başarılı Bir Şekilde Yüklendi.",
                { position: "top-right" }
              );
              const slider = this.state.formdata ;
              slider.image = result.url ;
              this.setState({
                slider
              });
              
            }
            this.setState({
              loadImage: false,
            });
              console.log(result);
          });
      }
  

    

  RemoteData = () => {
    let promisem;

    const deleteStatus = (id) => {
      fetch(this.state.BASE_URL + "/api/faq_category_delete/" + id, {
        method: "GET",
      }).then((response) => {
        if (response.status === 200) {
          tableRef.current && tableRef.current.onQueryChange();

          cogoToast.success("Kategori Başarılı Bir Şekilde Silindi", {
            position: "top-right",
          });
        } else {
          cogoToast.warn("Kategori Silinemedi Hata Oluştu", {
            position: "top-right",
          });
        }
      });
    };

    const getStatus = (id) => {
      this.setState({ create: { status: false } });
      this.setState({
        load_detail: true,
      });
      this.setState({
        edit: {
          id: "",
          name: "",  
        },
      });
      fetch(this.state.BASE_URL + "/api/get_faq_category_single/" + id)
        .then((response) => {
          return response.json();
        })
        .then((resp) => {
            
            var sonuc = resp.data ;
           this.setState({
              edit: {
                id: sonuc.id,
                name: sonuc.name,  
                image: sonuc.image,  
                status: true,
              },
            });
          
          this.setState({
            load_detail: false,
          });
        });
    };

    return (
      <MaterialTable
        title="Tüm S.S.S. Kategorileri"
        tableRef={tableRef}
        actions={[
          (rowData) => ({
            icon: "edit",
            tooltip: "Düzenle",
            onClick: (event, rowData) => getStatus(rowData.id),
            disabled: rowData.is_admin == true,
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "Kategoriyi  Sil",
            onClick: (event, rowData) =>
              confirmAlert({
                title: "Kategori Silinecek",
                message:
                  "Kategoriyi Silmek İstediğinize Emin misiniz?",
                buttons: [
                  {
                    label: "Hayır",
                    onClick: () => {
                      return true;
                    },
                  },
                  {
                    label: "Evet",
                    onClick: () => {
                      deleteStatus(rowData.id);
                      console.log(rowData.id);
                    }, //tableRef.current && tableRef.current.onQueryChange() ;
                  },
                ],
              }),
            disabled: rowData.count > 0,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          filtering: false,
          pageSizeOptions: [1, 2, 3, 4, 5, 50, 100, 200],
          pageSize: 50,
          headerStyle: {
            height: "25px !important",
            padding: "5px 20px",
          },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{count} sonuçtan {from}-{to} arası sonuçlar",
            labelRowsSelect: "data göster ",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: "İşlem",
          },
          body: {
            emptyDataSourceMessage: "Gösterilecek Data Bulunamadı",
            filterRow: {
              filterTooltip: "Filtre",
            },
          },
        }}
        columns={[
          {
            title: "Resim",
            field: "image",
            render: (rowData) => (
              <div className="main-img-user avatar-md">
                <img alt="Resim" className="square" src={rowData.image} />
              </div>
            ),
          },
          { title: "Kategori ID", field: "id" },
          { title: "Kategori Adı", field: "name" },
          { title: "Toplam Soru", field: "count" },
         
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            if (!query.orderDirection) {
              query.orderBy = { field: "id" };
              query.orderDirection = "desc";
            }
            let url = config().BASE_URL + "/api/faq_Categorys?";
            url += "limit=" + query.pageSize;
            url +=
              "&order[" + query.orderBy.field + "]=" + query.orderDirection;
            url += "&page=" + (query.page + 1);
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                if (result.error) {
                  cogoToast.warn(
                    "Webservis Kaynaklı Sorun Oluşmuştur, Lütfen Sistem Yöneticisine Bilgi Veriniz...",
                    { position: "bottom-right" }
                  );
                }

                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    );
  };

  handleChange = (e) => {
    console.log(e.target.name);

    var someProperty = this.state.formdata;
    someProperty[e.target.name] = e.target.value;

    this.setState({ someProperty });
  };
  UpdateChange = (e) => {
    var someProperty = this.state.edit;
    someProperty[e.target.name] = e.target.value;

    this.setState({ someProperty });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      load_detail: true,
    });

    fetch(this.state.BASE_URL + "/api/faq_category_create", {
      method: "POST",
      body: JSON.stringify(this.state.formdata),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          load_detail: false,
        });
        if (result.success) {
          this.setState({
            create: { status: false , name : ''  , image : '' },
          });
          tableRef.current && tableRef.current.onQueryChange();
          this.setState({
            create: { status: false , name : '' , image : '' },
          });
          cogoToast.success("Kategori Başarılı Bir Şekilde Oluşturuldu", {
            position: "top-right",
          });
        } else {
          cogoToast.warn("Kategori Oluşturulurken Hata Oluştu", {
            position: "top-right",
          });
        }
      });
  };

  UpdateSubmit = (e) => {
    e.preventDefault();

    this.setState({
      load_detail: true,
    });

    fetch(this.state.BASE_URL + "/api/faq_category_edit", {
      method: "POST",
      body: JSON.stringify(this.state.edit),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          load_detail: false,
        });
        if (result.success) {
          this.setState({
            edit: { id : "", name : '', image : '', status: false },
          });
          tableRef.current && tableRef.current.onQueryChange();

          cogoToast.success("Kategori Başarılı Bir Şekilde Güncellendi", {
            position: "top-right",
          });
        } else {
          cogoToast.warn("Kategori Güncellenirken Hata Oluştu", {
            position: "top-right",
          });
        }
      });
  };

  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="../assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    S.S.S. Yazı Kategorileri
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Atlas</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    S.S.S. Yazı Kategorileri
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                      onClick={this.addCategory}
                    >
                      <i className="fe fe-plus mr-2" /> Yeni Kategori
                    </button>
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                    <div className="card-body pt-0">{this.RemoteData()}</div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card custom-card">
                    <BlockUi
                      loader={
                        <div className="loader-img-page-div">
                          <img src="../assets/img/loader.svg" />
                          <div className="LoaderText-div">
                            Lütfen Bekleyiniz...
                          </div>
                        </div>
                      }
                      tag="div"
                      className="card-body"
                      blocking={this.state.load_detail}
                    >
                      {this.state.create.status == true ? (
                        <div>
                          <div class="card-header pb-0 border-bottom-0">
                            <label class="main-content-label mb-2 pt-1">
                              Yeni Kategori Ekle
                            </label>
                            <p class="tx-12 mb-0 text-muted">
                              Yeni Kategori Eklemek İçin Formu Doldurun
                            </p>
                          </div>
                          <div class="card-body">
                            <div class="d-flex flex-column">
                              <form
                                id="main-login"
                                action={
                                  this.state.BASE_URL + "/api/create_status"
                                }
                                method="post"
                                onSubmit={this.handleSubmit}
                              >
                                <div class="form-group">
                                  <input
                                    class="form-control"
                                    name="name"
                                    defaultValue={
                                      this.state.formdata.name || ""
                                    }
                                    onChange={this.handleChange}
                                    placeholder="Kategori Adı"
                                    type="text"
                                  />
                                </div>


                                
                                <BlockUi
                              loader={
                                <div className="loader-img-page-div">
                                  <img
                                    alt="Resim"
                                    src="/assets/img/loader.svg"
                                  />
                                  <div className="LoaderText-div">
                                    Resim Yüklenirken Lütfen Bekleyiniz...
                                  </div>
                                </div>
                              }
                              tag="div"
                              className="form-group"
                              blocking={this.state.loadImage}
                            >
            
                                <label className>Öne Çıkan Resim </label>
                                <div className="pos-relative">
                                <Dropzone onDrop={this.onDropc}>
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="container">
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p>
                                            Kategori Resmini Yüklemek İçin Tıklayın <br /> veya <br /> Resmi Buraya Sürükleyin
                                          </p>
                                        </div>
                                        {this.state.formdata.image ? (
                                        <div class="media d-block mt-3 text-center">
                                          <img
                                            src={this.state.formdata.image}
                                            className="wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0"
                                          />
                                          <button type="button" className="btn btn-warning wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 " onClick={this.kaldirc}>Resmi Kaldır</button>
                                        </div>) : (<></>
                                        )}
                                      </section>
                                    )}
                                  </Dropzone>


                                </div>
                           
                            </BlockUi>

                                <div class="form-group"></div>

                                <button
                                  class="btn ripple btn-main-primary btn-block"
                                  disabled={!this.state.formdata.name}
                                >
                                  Kategori Ekle
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {!this.state.edit.id ? (
                        <div class="bg-white box-shadow custom-card card">
                          <div class="alert text-center fade show p-3">
                            <i class="fe fe-download-cloud fs-50 text-primary"></i>
                            <h5 class="mt-2 mb-1">Henüz Seçim Yapmadınız!</h5>
                            <p class="mb-3 mb-3 tx-inverse">
                              Lütfen Önce Tablodan Bir Kayıt Seçiniz
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div class="card-header pb-0 border-bottom-0">
                            <label class="main-content-label mb-2 pt-1">
                            Kategoriyi Düzenle
                            </label>
                            <p class="tx-12 mb-0 text-muted">
                            Kategoriyi Düzenlemek İçin Formu Doldurun
                            </p>
                          </div>
                          <div class="card-body">
                            <div class="d-flex flex-column">
                              <form
                                id="main-login"
                                action={
                                  this.state.BASE_URL + "/api/edit_status"
                                }
                                method="post"
                                onSubmit={this.UpdateSubmit}
                              >
                                <div class="form-group">
                                  <input
                                    class="form-control"
                                    name="name"
                                    defaultValue={this.state.edit.name || ""}
                                    onChange={this.UpdateChange}
                                    placeholder="Kategori Adı"
                                    type="text"
                                  />
                                </div>


                                <BlockUi
                              loader={
                                <div className="loader-img-page-div">
                                  <img
                                    alt="Resim"
                                    src="/assets/img/loader.svg"
                                  />
                                  <div className="LoaderText-div">
                                    Resim Yüklenirken Lütfen Bekleyiniz...
                                  </div>
                                </div>
                              }
                              tag="div"
                              className="form-group"
                              blocking={this.state.loadImage}
                            >
            
                                <label className>Öne Çıkan Resim </label>
                                <div className="pos-relative">
                                <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="container">
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p>
                                            Kategori Resmini Yüklemek İçin Tıklayın <br /> veya <br /> Resmi Buraya Sürükleyin
                                          </p>
                                        </div>
                                        {this.state.edit.image ? (
                                        <div class="media d-block mt-3 text-center">
                                          <img
                                            src={this.state.edit.image}
                                            className="wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0"
                                          />
                                          <button type="button" className="btn btn-warning wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 " onClick={this.kaldir}>Resmi Kaldır</button>
                                        </div>) : (<></>
                                        )}
                                      </section>
                                    )}
                                  </Dropzone>


                                </div>
                           
                            </BlockUi>
                        

                                <button
                                  class="btn ripple btn-main-primary btn-block"
                                  disabled={
                                    !this.state.edit.id ||
                                    !this.state.edit.name 
                                  }
                                >
                                  Kategoriyi Güncelle
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                    </BlockUi>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FaqCategorys;
