import firebase from 'firebase/app'
import "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyD_2UdL4Dot3i-iwSxaJi30ve_MW6kuZYs",
    authDomain: "abonesepeti-maya.firebaseapp.com",
    databaseURL: "https://abonesepeti-maya-default-rtdb.firebaseio.com",
    projectId: "abonesepeti-maya",
    storageBucket: "abonesepeti-maya.appspot.com",
    messagingSenderId: "21593657531",
    appId: "1:21593657531:web:6b1ee8eb821d3c51fa199e",
    measurementId: "G-GBWDFTTHK2"
};

const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = initializedFirebaseApp.messaging();
export { messaging };