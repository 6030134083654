import React from "react";
import Ustkisim from "../inc/Header";
import { seo } from "../../helpers";
import { config } from "../../config";
import "moment/locale/tr";
import MaterialTable from "material-table";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import cogoToast from 'cogo-toast';
import CurrencyFormat from 'react-currency-format';
import { Link } from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from "moment";
 const tableRef = React.createRef();
class allProducts extends React.Component {
  constructor(props,context) {
    super(props);
    const datePickerId = moment(new Date()).format("YYYY-MM-DD[T]HH:mm") ;
 
    this.state = { edit: {status : false , id : '' , topic : '' , start_time: '' , duration : '40' , password : ''}, create : {status : false } , formdata : {topic : '' , start_time: '' , duration : '40' , password : ''} , mindate : datePickerId ,  load_detail: false };
    this.state.BASE_URL = config().BASE_URL;

    this.handleChange = this.handleChange.bind(this);
    this.UpdateChange = this.UpdateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.UpdateSubmit = this.UpdateSubmit.bind(this);
    
  }



  componentDidMount() {
    seo({
      title: "Tüm Ürünler | Atlas",
      metaDescription: "Atlas Ana Ekranı",
    });
  }

  comparison_update = () => {
    // .loading sınıfını ekleyerek dönen animasyonu başlat
    document.querySelector('.rotateanimation').classList.add('loadinga');
  
    // Butonu devre dışı bırak
    document.querySelector('.fbutton').disabled = true;
  
    fetch('https://webservice.abonesepeti.net/api/comparison_update', {
      method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      // .loading sınıfını kaldırarak dönen animasyonu durdur
      document.querySelector('.rotateanimation').classList.remove('loadinga');
      // Butonu etkinleştir
      document.querySelector('.fbutton').disabled = false;
    })
    .catch(error => {
      console.error('Error:', error);
      // Hata durumunda da aynı işlemleri yaparak animasyonu ve butonu geri getir
      document.querySelector('.rotateanimation').classList.remove('loadinga');
      document.querySelector('.fbutton').disabled = false;
    });
  }
  


  RemoteData = () => {
   
    let  promisem;

    const deleteZoomMeeting = (id) => { 

			fetch(this.state.BASE_URL + '/api/delete_meeting/' + id , {
                                method : 'DELETE'
                                   }).then(response => { 
					if(response.status === 200) {
            
            

              tableRef.current && tableRef.current.onQueryChange() ;
                               
                cogoToast.success('Toplantı Başarılı Bir Şekilde Silindi',{position : 'top-right'});             	
					
          } 
          else 
          {
            cogoToast.warn('Toplantı Silinemedi Hata Oluştu',{position : 'top-right'});    
          }
			 });
		
  }
  

  const getZoomMeeting = (id) => { 


    this.setState({create: {status : false}}) ;
    this.setState({
      load_detail: true,
    });
                
    fetch(this.state.BASE_URL + '/api/getSingleMeet/' + id )
    .then(response => {
        return response.json();
    }).then(sonuc => {
      console.log(sonuc);
           if(sonuc.success)
           {
            const datePickerId = moment(sonuc.start_time).format("YYYY-MM-DD[T]HH:mm") ;

            this.setState({edit: {id : sonuc.id , duration : sonuc.duration , password : sonuc.password , topic : sonuc.topic , start_time : datePickerId , status : true}}) ;
           }
           
           console.log(this.state.edit);
           
          this.setState({
            load_detail: false,
          });
     
    });

  
}


    return (
      <MaterialTable
        title="Tüm Ürünler"

        tableRef={tableRef}
        actions={[
          rowData => ({
            icon: 'edit',
            tooltip: 'Düzenle',
            onClick: (event, rowData) =>
            {
              const win = window.open("/#/content/products/product/"+rowData.id+"/", "_blank");
              win.focus();
            }
          
          }),
          rowData => ({
            icon: 'content_copy',
            tooltip: 'Kopyala',
            onClick: (event, rowData) =>
            {
              const win = window.open("/#/content/products/clone/"+rowData.id+"/", "_blank");
              win.focus();
            }
  
          }),
             
        ]}

        options={{
            grouping: true,
            actionsColumnIndex: -1,
            search: false,
            filtering: false,
            pageSizeOptions: [25,50,100, 200,500,1000,1500],
            pageSize : 25 , 
            
          headerStyle: {
       
            height: "25px !important",
            padding: "5px 20px"
          }
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{count} sonuçtan {from}-{to} arası sonuçlar",
            labelRowsSelect: "data göster ",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: "İşlem",
          },
          grouping : {
            placeholder : 'Gruplamak İstediğiniz Tablo Başlıklarını Buraya Sürükleyin',
            groupedBy : "Gruplanan : "
          },
          body: {
            emptyDataSourceMessage: "Gösterilecek Data Bulunamadı",
            filterRow: {
              filterTooltip: "Filtre",
            },
          },
        }}
        columns={[
          { title: 'Kurum Resmi', field: 'makeImage', render: rowData => <img src={rowData.makeImage} style={{width: 40, borderRadius: '50%'}}/> },
          { title: "Kategori ", field: "catname" },
          { title: "Marka ", field: "makename" },
          { title: "Ürün Adı ", field: "productname" },
          { title: "Ürün Fiyatı ", field: "price_sale" ,
          render: (rowData) => <CurrencyFormat value={rowData.price_sale} displayType={'text'} thousandSeparator={true} suffix={' ₺'} renderText={value => <div>{value}</div>} />
          
         

        
        },
          {
            title: "Gösterim",
            field: "maya",
            render: (rowData) =>
            <div>
              {
              rowData.maya == 1 ? <span class="badge badge-pill badge-success-light">Maya</span> :''
           
              }
               {
              rowData.web == 1 ? <span class="badge badge-pill badge-success-light">Web</span> :''
           
              }
               {
              rowData.app == 1 ? <span class="badge badge-pill badge-success-light">App</span> :''
           
              }
             </div>
          },
          {
            title: "Durumu",
            field: "status",
            render: (rowData) =>
            rowData.status == 1 ? <span class="badge badge-pill badge-success-light">Aktif</span> : <span class="badge badge-pill badge-danger-light">Pasif</span>
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
 
            if (!query.orderDirection) {
              query.orderBy = { field: "siralama" };
              query.orderDirection = "asc";
            }
            let url = config().BASE_URL + "/api/getallproducts?";
            url += "limit=" + query.pageSize;
            url += "&order[" + query.orderBy.field + "]=" + query.orderDirection;
            url += "&page=" + (query.page + 1);
            fetch(url)
              .then((response) => response.json())
              .then((result) => {

                if(result.error)
                {
                  cogoToast.warn('Webservis Kaynaklı Sorun Oluşmuştur, Lütfen Sistem Yöneticisine Bilgi Veriniz...',{position : 'bottom-right'});

                }
            
            
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    );
  };



  handleChange = (e) => {
        console.log(e.target.name);

        var someProperty = this.state.formdata ;
        someProperty[e.target.name] = e.target.value;
       
        this.setState({someProperty});
      }
      UpdateChange = (e) => {
      
        var someProperty = this.state.edit ;
        someProperty[e.target.name] = e.target.value;
       
        this.setState({someProperty});
      }
      

      handleSubmit = (e) => {
          e.preventDefault();
 
          this.setState({
            load_detail: true,
          });


          fetch(this.state.BASE_URL + "/api/create_meeting", {
              method: 'POST',
              body: JSON.stringify(this.state.formdata)
            })
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              this.setState({
                load_detail: false,
              });
              if(result.success) {
            
            
                this.setState({
                  create: {status : false},
                 
                });
                tableRef.current && tableRef.current.onQueryChange() ;
                                 
                  cogoToast.success('Toplantı Başarılı Bir Şekilde Oluşturuldu',{position : 'top-right'});             	
            
            } 
            else 
            {
              cogoToast.warn('Toplantı Oluşturulurken Hata Oluştu',{position : 'top-right'});    
            }


             
            });
      
      }


      UpdateSubmit = (e) => {
        e.preventDefault();

        this.setState({
          load_detail: true,
        });


        fetch(this.state.BASE_URL + "/api/edit_meeting", {
            method: 'POST',
            body: JSON.stringify(this.state.edit)
          })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              load_detail: false,
            });
            if(result.success) {
          
          
              this.setState({
                edit: {status : false},
               
              });
              tableRef.current && tableRef.current.onQueryChange() ;
                               
                cogoToast.success('Toplantı Başarılı Bir Şekilde Güncellendi',{position : 'top-right'});             	
          
          } 
          else 
          {
            cogoToast.warn('Toplantı Güncellenirken Hata Oluştu',{position : 'top-right'});    
          }


           
          });
    
    }





  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="../assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                   Tüm Ürünler
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Atlas</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Tüm Ürünler
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="d-flex justify-content-center">
                  <button
                      type="button"
                      className="fbutton btn btn-success btn-icon-text my-2 mr-2"
                      onClick={this.comparison_update}
                     
                    >
                      
                      <div className="rotateanimation  mr-2"><i className="fe fe-loader" />    </div> Sitedeki Kampanyaları Güncelle
                  
                    </button>
                    <Link className="btn btn-white btn-icon-text my-2 mr-2" to="/content/products/addProduct">
                    <i className="fe fe-plus mr-2" />  Yeni Kampanya Ekle
                    </Link>
                    
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-md-12 col-xl-12">
                  <div className="card custom-card overflow-hidden">
                    <div className="card-body pt-0">{this.RemoteData()}</div>
                  </div>
                </div>

               
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default allProducts;
