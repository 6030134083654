import React , {button , useState} from "react"; 
import { Link } from "react-router-dom";
import Ustkisim from "../inc/Header";
import Footer from "../inc/Footer";
import { seo } from "../../helpers";
import { config } from "../../config";
import Moment from "moment";
import "moment/locale/tr"; 
import MaterialTable from 'material-table';
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Loader from 'react-loaders'
import Checkbox from "../../bilesenler/Checkbox";
import { Scrollbars } from 'react-custom-scrollbars';

const tableRef = React.createRef();
class Active extends React.Component {
  constructor(props) {
    super(props);
    this.state = { faks: {} ,  load_detail : false  , total_seller : 0 , citys : [] ,  towns : [] ,  towns : [] , types : []  ,    selected_types: [],  selected_citys: [] ,   selected_towns: [] };
    this.state.BASE_URL = config().BASE_URL;
    this.handleCheckTypes = this.handleCheckTypes.bind(this);
    this.handleCheckCitys = this.handleCheckCitys.bind(this);
    this.handleCheckTowns = this.handleCheckTowns.bind(this);
    this.setState({
      load_detail : true,
    });
    fetch(this.state.BASE_URL + "/sellers/types")
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      this.setState({
        types: result,
        load_detail : false
      });
    });
  
    fetch(this.state.BASE_URL + "/sellers/citys")
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      console.log(result);
      this.setState({
        citys: result
      });
    });


    
  
  }

  componentDidMount() {
 

    seo({
      title: "Aktif Bayi Listesi | Atlas",
      metaDescription: "Atlas Ana Ekranı",
    });
  }


  filtrele = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };
  
  handleCheckTowns = (event) => {

    let towns = this.state.towns;
    towns.forEach((type) => {
   
      if (type.id === event.target.value)
      type.isChecked = event.target.checked;
    });

    this.setState(
      {
        towns: towns,
      },
      () => {
        let selected = [];
        let towns = this.state.towns;
        towns.forEach((type) => {
          if (type.isChecked == true) selected.push(type);
        });
        this.setState({ selected_towns: selected }, () => {
    
          this.filtrele();
        
        });
      }
    );
  };



  handleCheckCitys = (event) => {

    let citys = this.state.citys;
    citys.forEach((type) => {
   
      if (type.id === event.target.value)
      type.isChecked = event.target.checked;
    });

    this.setState(
      {
        citys: citys,
      },
      () => {
        let selected = [];
        let citys = this.state.citys;
        citys.forEach((type) => {
          if (type.isChecked == true) selected.push(type);
        });
        this.setState({ selected_citys: selected }, () => {
          let selected_c = [];
          this.state.selected_citys.forEach((city) => {
            selected_c.push(city.id);
          });
          if(selected_c.length)
          {
            fetch(this.state.BASE_URL + "/sellers/towns?citys=" + selected_c.join(","))
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              this.setState({
                towns: result
              });
            });
          }
          else 
          {
            this.setState({
              towns: [],
              selected_towns : []
            });
          }
      

          this.filtrele();
        
        });
      }
    );
  };


  handleCheckTypes = (event) => {

    let types = this.state.types;
    types.forEach((type) => {
   
      if (type.id === event.target.value)
      type.isChecked = event.target.checked;
    });

    this.setState(
      {
        types: types,
      },
      () => {
        let selected = [];
        let types = this.state.types;
        types.forEach((type) => {
          if (type.isChecked == true) selected.push(type);
        });
        this.setState({ selected_types: selected }, () => {
          console.log(selected);
          this.filtrele();
          this.setState(
            {
              allcats: false,
            });
        });
      }
    );
  };
 
  RemoteData = () => {

   
    

    return (
      <MaterialTable
        title="Tüm Aktif Bayiler"
        tableRef={tableRef}
        options={{
          pageSizeOptions: [10, 50,100, 200],
          pageSize : 50 ,
          search: true,
          filtering: false,
          headerStyle: {
       
            height: "25px !important",
            padding: "5px 20px"
          }
        
        }}

              
            localization={{
              pagination: {
                  labelDisplayedRows: '{count} sonuçtan {from}-{to} arası sonuçlar',
                  labelRowsSelect : "data göster "
              },
              toolbar: {
                  nRowsSelected: '{0} row(s) selected',
                  searchPlaceholder : "Arama",
                  searchTooltip : "Arama"
              },
              header: {
                  actions: 'Actions'
              },
              body: {
                  emptyDataSourceMessage: 'Gösterilecek Data Bulunamadı',
                  filterRow: {
                      filterTooltip: 'Filtre'
                  }
              }
              

          }}

        columns={[
          {
            title: 'Bayi Adı',
            field: 'dealers_name',
            render: rowData => (
              rowData.dealers_name
            ),
          },
          { title: 'Bayi Tipi', field: 'seller_type' },
          {
            title: "İl/İlçe",
            field: "city",
            render: (rowData) =>
            (
              rowData.city ? <small>{rowData.city+" / "+rowData.town}</small> : '-'
            )
          },
      
          { title: 'Yetkili kişi', field: 'name_surname' },
       
          { title: 'Telefon Numarası', field: 'phone' },
          { title: 'E-Posta', field: 'email' },

          { title: 'Toplam Satışı', field: 'total' , orderBy : false },
          {
            title: "Durumu",
            field: "status",
            render: (rowData) =>
            rowData.status == "1" ? <span className="badge badge-pill badge-success-light">Aktif</span> : <span className="badge badge-pill badge-danger-light">Pasif</span>
          },
   
          {
            title: 'İşlem', 
            field: 'id',
            render: rowData => (
              
              <button className="btn btn-primary btn-sm" onClick={(e) => this.getfaks(rowData.id)}  >
              Göster
            </button>
          
            ),
          },

        ]}
        data={query =>
          new Promise((resolve, reject) => {
            console.log(query);

              if(!query.orderDirection)
              {
                query.orderBy = {field : 'id' };
                query.orderDirection = 'asc';
              }
              let selected = [];
              this.state.selected_types.forEach((type) => {
                selected.push(type.id);
              });

              let selected_c = [];
              this.state.selected_citys.forEach((city) => {
                selected_c.push(city.id);
              });

              let selected_t = [];
              this.state.selected_towns.forEach((town) => {
                selected_t.push(town.id);
              });

              
              
            let url = config().BASE_URL + '/sellers/list/1?'
            url += 'limit=' + query.pageSize
            url += '&search=' + query.search
            url += '&order['+query.orderBy.field+']=' + query.orderDirection
            url += '&page=' + (query.page + 1)
            
            if(selected.length)
            {
              url += "&types=" + selected.join(",");
            }
            if(selected_c.length)
            {
              url += "&citys=" + selected_c.join(",");
            }
            if(selected_t.length)
            {
              url += "&towns=" + selected_t.join(",");
            }
          
            
            fetch(url)
              .then(response => response.json())
              .then(result => {
                this.setState({
                  total_seller : result.total
                });
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                })
              })
          })
        }
      />
    )
  }

  render() {

   
    
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="../assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                  Aktif Bayi Listesi ({this.state.total_seller})
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Atlas</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Aktif Bayi Listesi
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-download mr-2" /> Faks Gönder
                    </button>
               
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                   
                    <div className="card-body pt-0">
                      {this.RemoteData()}
                  
                    </div>
                  </div>
                </div>

           
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
           
                <div className="card custom-card">
                    <div className="card-body">
                      <div className="card-header pb-0 border-bottom-0 pb-1">
                        <label className="main-content-label pb-2  ">
                         Bayi Tipleri
                        </label>
                      </div>
                   
                    <div className="form-group">
                            {this.state.types.map((type) => {
                              return (
                                <Checkbox
                                  key={"type_" + type.id}
                                  value={type.id}
                                  handleCheckChieldElement={
                                    this.handleCheckTypes
                                  }
                                  {...type}
                                />
                              );
                            })}
                          </div>
                                       
                 
                    </div>
                  </div>
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="card-header pb-0 border-bottom-0 pb-1">
                        <label className="main-content-label pb-2  ">
                         İllere Göre 
                        </label>
                      </div>
                      <div className="card-body pb-0 pt-0">
                        <div className="d-flex flex-column">
                        <Scrollbars style={{ height: 300 }}>
                        <div className="form-group">
                            {this.state.citys.map((city) => {
                              return (
                                <Checkbox
                                  key={"citys_" + city.key}
                                  value={city.id}
                                  handleCheckChieldElement={
                                    this.handleCheckCitys
                                  }
                                  {...city}
                                />
                              );
                            })}
                          </div>
                          </Scrollbars>

                         
                        </div>
                      </div>
                    </div>
                  </div>
                    {this.state.selected_citys.length > 0 && this.state.towns.length > 0 ?         
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="card-header pb-0 border-bottom-0 pb-1">
                        <label className="main-content-label pb-2  ">
                         İlçelere Göre 
                        </label>
                      </div>
                      <div className="card-body pb-0 pt-0">
                        <div className="d-flex flex-column">
                        <Scrollbars style={{ height: 300 }}>
                        <div className="form-group">
                            {this.state.towns.map((town) => {
                              return (
                                <Checkbox
                                  key={"town_" + town.key}
                                  value={town.id}
                                  handleCheckChieldElement={
                                    this.handleCheckTowns
                                  }
                                  {...town}
                                />
                              );
                            })}
                          </div>
                          </Scrollbars>

                     
                        </div>
                      </div>
                    </div>
                  </div> : <></>}
              
                </div>
        
        
         </div>
       </div>
          </div>
        </div>
     
      </div>
    );
  }
}

export default Active;
 