import React, { button, } from "react";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "moment/locale/tr";
import MaterialTable from "material-table";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader"
import GlobalLoader from "../../bilesenler/GlobalLoader"
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { Link , Switch, Route, useRouteMatch } from "react-router-dom";
import {Select as RSelect} from 'react-select';
const tableRef = React.createRef();
class KiBrands extends React.Component {
  constructor(props, context) {
    super(props);
    const datePickerId = moment(new Date()).format("YYYY-MM-DD[T]HH:mm");

    this.state = {
      edit: {
        status: false,
        id: "",
        name: "",
        type : ""
      },
      create: { status: false },
      formdata: { name: "" , type : "" },
      mindate: datePickerId,
      load_detail: false,
    };
    this.state.BASE_URL = config().BASE_URL;

 
  }

 
  RemoteData = () => {
    let promisem;

    return (
      <MaterialTable
        title="Tüm Kolay İptal Kurumları"
        tableRef={tableRef}
        actions={[
          (rowData) => ({
            icon: "edit",
            tooltip: "Düzenle",
            onClick: (event, rowData) => {},
            disabled: rowData.is_admin == true,
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "Özel Alanı  Sil",
            onClick: (event, rowData) =>
              confirmAlert({
                title: "Özel Alan Silinecek",
                message: "Özel Alanı Silmek İstediğinize Emin misiniz?",
                buttons: [
                  {
                    label: "Hayır",
                    onClick: () => {
                      return true;
                    },
                  },
                  {
                    label: "Evet",
                    onClick: () => {
                      
                      console.log(rowData.id);
                    }, //tableRef.current && tableRef.current.onQueryChange() ;
                  },
                ],
              }),
            disabled: rowData.count > 0,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          filtering: false,
          pageSizeOptions: [1, 2, 3, 4, 5, 50, 100, 200],
          pageSize: 50,
          headerStyle: {
            height: "25px !important",
            padding: "5px 20px",
          },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{count} sonuçtan {from}-{to} arası sonuçlar",
            labelRowsSelect: "data göster ",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: "İşlem",
          },
          body: {
            emptyDataSourceMessage: "Gösterilecek Data Bulunamadı",
            filterRow: {
              filterTooltip: "Filtre",
            },
          },
        }}
        columns={[
          { title: "Özel Alan ID", field: "id" },
          { title: "Özel Alan Adı", field: "name" },
          { title: "Özel Alan Tipi", field: "type" },
          {
            title: "Durumu",
            field: "status",
            render: (rowData) =>
              rowData.status == "1" ? (
                <span className="badge badge-pill badge-success-light">
                  Aktif
                </span>
              ) : (
                <span className="badge badge-pill badge-danger-light">
                  Pasif
                </span>
              ),
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            if (!query.orderDirection) {
              query.orderBy = { field: "id" };
              query.orderDirection = "desc";
            }
            let url = config().BASE_URL + "/api/ki_get_brands?";
            url += "limit=" + query.pageSize;
            url +=
              "&order[" + query.orderBy.field + "]=" + query.orderDirection;
            url += "&page=" + (query.page + 1);
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                if (result.error) {
                  cogoToast.warn(
                    "Webservis Kaynaklı Sorun Oluşmuştur, Lütfen Sistem Yöneticisine Bilgi Veriniz...",
                    { position: "bottom-right" }
                  );
                }

                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    );
  };



  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <GlobalLoader />
            <div className="inner-body">
            
              <PageHeader title="Kolay İptal Kurumları" html={  <Link
                     to="/settings/KiNewBrand"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-plus mr-2" /> Yeni Kurum Ekle
                    </Link>} />

              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                    <div className="card-body pt-0">{this.RemoteData()}</div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card custom-card">
                    <BlockUi
                      loader={
                        <div className="loader-img-page-div">
                          <img src="../assets/img/loader.svg" />
                          <div className="LoaderText-div">
                            Lütfen Bekleyiniz...
                          </div>
                        </div>
                      }
                      tag="div"
                      className="card-body"
                      blocking={this.state.load_detail}
                    >
              

                   
                    </BlockUi>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KiBrands;
