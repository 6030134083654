import $ from "jquery";
import { Component } from "react";
import { Link } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

import { config } from "../../config";

class Sidemenu extends Component {
  constructor(props) {
    super(props);
    const CurUrl = window.location.href.substr(1).split("#")[1];
    const currentRoute = CurUrl.substr(1).split("/");

    if (typeof currentRoute[1] === "undefined") {
      currentRoute[1] = "";
    }

    console.log(currentRoute);

    this.state = {
      leads: [],
      leads_count: 0,
      sales: [],
      sales_count: 0,
      assign_sales: [],
      assign_sales_count: 0,
      ulak: [],
      ulak_count: 0,
      ki_status: [],
      ki_status_count: 0,
      current_page: currentRoute[0],
      current_subpage: currentRoute[1],
    };

  }


  getWithExpiry = () => {

    const itemStr = localStorage.getItem("AtlasToken");
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)


    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      //localStorage.removeItem(key)
      return null
    }
    return item.value
  }


  fetchAllMenus = () => {
    let url = config().BASE_URL + "/api/leads_count";
    fetch(url, {
      headers: { Authentication: 'Bearer ' + this.getWithExpiry() }
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          leads: result.data,
          leads_count: result.total_count,
        });
      });

    let url1 = config().BASE_URL + "/api/sales_count";
    fetch(url1, {
      headers: { Authentication: 'Bearer ' + this.getWithExpiry() }
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          sales: result.data,
          sales_count: result.total_count,
        });
      });

    let url2 = config().BASE_URL + "/api/sales_count/1";
    fetch(url2, {
      headers: { Authentication: 'Bearer ' + this.getWithExpiry() }
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          assign_sales: result.data,
          assign_sales_count: result.total_count,
        });
      });

    let ulak = config().BASE_URL + "/api/ulak_count";
    fetch(ulak, {
      headers: { Authentication: 'Bearer ' + this.getWithExpiry() }
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          ulak: result.data,
          ulak_count: result.total_count,
        });
      });

    let kstatus = config().BASE_URL + "/api/ki_status_count_menu";
    fetch(kstatus, {
      headers: { Authentication: 'Bearer ' + this.getWithExpiry() }
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          ki_status: result.data,
          ki_status_count: result.total_count,
        });
      });
  };

  componentDidMount() {
    this.fetchAllMenus();
  }

  toggleMenu = () => {

    if (reactLocalStorage.get("sidemenu") == "1") {
      reactLocalStorage.set("sidemenu", "0");
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    } else {
      reactLocalStorage.set("sidemenu", "1");
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    }
    if (window.matchMedia("(min-width: 768px)").matches) {
      $("body").toggleClass("main-sidebar-hide");
    } else {
      $("body").toggleClass("main-sidebar-show");
    }
  };

  render() {
    return (
      <div>
        <div className="main-sidebar main-sidebar-sticky side-menu">
          <div className="sidemenu-logo">
            <Link className="main-logo" to="/">
              <img
                src="/assets/img/brand/logo-light.png"
                className="header-brand-img desktop-logo"
                alt="logo"
              />
              <img
                src="/assets/img/brand/icon-light.png"
                className="header-brand-img icon-logo"
                alt="logo"
              />
              <img
                src="/assets/img/brand/logo.png"
                className="header-brand-img desktop-logo theme-logo"
                alt="logo"
              />
              <img
                src="/assets/img/brand/icon.png"
                className="header-brand-img icon-logo theme-logo"
                alt="logo"
              />
            </Link>

            {reactLocalStorage.get("sidemenu") == "0" ? (
              <div className="main-header-left logo-hide-menu">
                <Link
                  className="main-header-menu-icon"
                  to="#"
                  onClick={() => this.toggleMenu()}
                >
                  <span></span>
                </Link>
              </div>
            ) : null}
          </div>
          <div className="main-sidebar-body">
            <ul className="nav">
              <li
                className={`nav-item ${!this.state.current_page ? "active" : ""
                  } ${this.state.current_subpage ? "show" : ""}`}
              >
                <Link className="nav-link" to="/">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-home sidemenu-icon" />
                  <span className="sidemenu-label">Panel</span>
                </Link>
              </li>

              <li className="nav-header">
                <span className="nav-label">Aktivasyon İşlemleri</span>
              </li>

              <li

                className={`nav-item ${this.state.current_page == "leads" ? "active" : ""
                  } ${this.state.current_page == "leads" &&
                    this.state.current_subpage
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-wallet sidemenu-icon" />
                  <span className="sidemenu-label">Potansiyel </span>
                  <span className="badge badge-warning side-badge">
                    {this.state.leads_count}
                  </span>
                </Link>

                <ul className="nav-sub">
                  {this.state.leads.map((n, idx) => (
                    <li
                      className={`nav-sub-item ${this.state.current_subpage == n.url ? "active" : ""
                        } `}
                      key={idx}
                    >
                      <Link
                        className="nav-sub-link"
                        to={"/leads/" + n.url}
                        style={{ display: "block" }}
                      >
                        {n.name}{" "}
                        <span className="badge badge-primary pull-right mr-3">
                          {n.count}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="nav-item anasayfa">
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-wallet sidemenu-icon" />
                  <span className="sidemenu-label">Satışlar</span>
                  <span className="badge badge-warning side-badge">
                    {this.state.sales_count}
                  </span>
                </Link>

                <ul className="nav-sub">
                  {this.state.sales.map((n, idx) => (
                    <li className="nav-sub-item" key={idx}>
                      <Link
                        className="nav-sub-link"
                        to={"/leads/" + n.url}
                        style={{ display: "block" }}
                      >
                        {n.name}{" "}
                        <span className="badge badge-primary pull-right mr-3">
                          {n.count}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="nav-item anasayfa">
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-wallet sidemenu-icon" />
                  <span className="sidemenu-label">Gönderiler</span>
                  <span className="badge badge-warning side-badge">0</span>
                </Link>

                <ul className="nav-sub">
                  {this.state.ulak.map((n, idx) => (
                    <li className="nav-sub-item" key={idx}>
                      <Link
                        className="nav-sub-link"
                        to={"/gonderiler/" + n.id}
                        style={{ display: "block" }}
                      >
                        {n.name}{" "}
                        <span className="badge badge-primary pull-right mr-3">
                          {n.count}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li
                className={`nav-item ${this.state.current_page == "kolayiptal" ? "active" : ""
                  } ${this.state.current_subpage &&
                    this.state.current_page == "kolayiptal"
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-layout-media-right sidemenu-icon" />
                  <span className="sidemenu-label">Kolay İptal İşlemleri</span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
                <ul className="nav-sub">
                  {this.state.ki_status.map((n, idx) => (
                    <li className="nav-sub-item" key={idx}>
                      <Link
                        className="nav-sub-link"
                        to={"/kolayiptal/talep/" + n.id}
                        style={{ display: "block" }}
                      >
                        {n.name}{" "}
                        <span className="badge badge-primary pull-right mr-3">
                          {n.count}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li

                className={`nav-item anasayfa ${this.state.current_page == "accounts" ? "active" : ""
                  } ${this.state.current_page == "accounts" &&
                    this.state.current_subpage
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-user sidemenu-icon" />
                  <span className="sidemenu-label">Müşteriler </span>
                  <i className="angle fe fe-chevron-right" />
                </Link>

                <ul className="nav-sub">
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/accounts/kolayiptal">
                      Kolayiptal Müşterileri
                    </Link>
                  </li>
                </ul>
              </li>

              <li

                className={`nav-item anasayfa${this.state.current_page == "sellers" ? "active" : ""
                  } ${this.state.current_page == "sellers" &&
                    this.state.current_subpage
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-stats-up sidemenu-icon" />
                  <span className="sidemenu-label">Bayi Listesi </span>
                  <i className="angle fe fe-chevron-right" />
                </Link>

                <ul className="nav-sub">
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/sellers/active">
                      Aktif Bayiler
                    </Link>
                  </li>
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/sellers/passive">
                      Pasif Bayiler
                    </Link>
                  </li>
                </ul>
              </li>

              <li

                className={`nav-item ${this.state.current_page == "brands" ? "active" : ""
                  } `}
              >
                <Link className="nav-link" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-camera sidemenu-icon" />
                  <span className="sidemenu-label">Markalar</span>

                </Link>
              </li>

              <li className="nav-header">
                <span className="nav-label">Uygulamalar</span>
              </li>

              <li

                className={`nav-item ${this.state.current_page == "fax" ? "active" : ""
                  } ${this.state.current_subpage && this.state.current_page == "fax"
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-layout-media-right sidemenu-icon" />
                  <span className="sidemenu-label">Faks İşlemleri</span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
                <ul className="nav-sub">
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/fax/IncomingFax">
                      Gelen Fakslar
                    </Link>
                  </li>
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/fax/OutgoingFax">
                      Gönderilen Fakslar
                    </Link>
                  </li>
                  <li className="nav-sub-item">
                    <a className="nav-sub-link" href="#">
                      Faks Gönder
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-email sidemenu-icon" />
                  <span className="sidemenu-label">Mail İşlemleri</span>
                </Link>
                <ul className="nav-sub">
                  <li className="nav-sub-item">
                    <Link
                      className="nav-sub-link"
                      to="/email/ListEmailAccounts"
                    >
                      Mail Hesapları
                    </Link>
                  </li>
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/email/MailBox">
                      Mail Uygulaması
                    </Link>
                  </li>
                </ul>
              </li>

              <li

                className={`nav-item ${this.state.current_page == "meeting" ? "active" : ""
                  } ${this.state.current_subpage &&
                    this.state.current_page == "meeting"
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link " to="/meeting/ZoomMeetingList">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-thought sidemenu-icon" />
                  <span className="sidemenu-label">Toplantı İşlemleri</span>
                </Link>
              </li>

              <li

                className={`nav-item ${this.state.current_page == "report" ? "active" : ""
                  } ${this.state.current_subpage &&
                    this.state.current_page == "report"
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link " to="/meeting/ZoomMeetingList">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-stats-up sidemenu-icon" />
                  <span className="sidemenu-label">Raporlar</span>
                </Link>
              </li>

              <li className="nav-header">
                <span className="nav-label">Genel Ayarlar</span>
              </li>
              <li

                className={`nav-item ${this.state.current_page == "content" ? "active" : ""
                  } ${this.state.current_subpage &&
                    this.state.current_page == "content"
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-layers-alt sidemenu-icon" />
                  <span className="sidemenu-label">İçerik Yönetimi</span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
                <ul className="nav-sub">
                  <li

                    className={`nav-item  deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "products" &&
                        this.state.current_page == "content"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Kampanya Yönetimi</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link
                          className="nav-sub-link"
                          to="/content/products/Categorys"
                        >
                          Kampanya Kategorileri
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link
                          className="nav-sub-link"
                          to="/content/products/all"
                        >
                          Tüm Kampanyalar
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link
                          className="nav-sub-link"
                          to="/content/products/addProduct"
                        >
                          Yeni Kampanya Ekle
                        </Link>
                      </li>


                    </ul>
                  </li>
                  <li
                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "Mainmenu" &&
                        this.state.current_page == "content" || this.state.current_subpage == "Mainmenu"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link" to="/content/Mainmenu">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Website Anamenü</span>

                    </Link>

                  </li>


                  <li
                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "SpecialCampaigns" &&
                        this.state.current_page == "content" || this.state.current_subpage == "SpecialCampaignsNew"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Özel Kampanyalar</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/SpecialCampaigns">
                          Tüm Özel Kampanyalar
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/SpecialCampaignsNew">
                          Yeni Özel Kampanya Ekle
                        </Link>
                      </li>

                    </ul>
                  </li>

                  <li

                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "sliders" &&
                        this.state.current_page == "content"
                        ? "show"
                        : ""
                      }`}
                  >





                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Slider</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/sliders/all">
                          Tüm Slidelar
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/sliders/addSlide">
                          Yeni Slider Ekle
                        </Link>
                      </li>

                    </ul>
                  </li>


                  <li

                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "comments" &&
                        this.state.current_page == "content"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Müşteri Yorumları</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/comments/all">
                          Tüm Yorumlar
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/comments/commentsAdd">
                          Yeni Yorum Ekle
                        </Link>
                      </li>

                    </ul>
                  </li>






                  <li

                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "faqs" &&
                        this.state.current_page == "content"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Sıkça Sorulan sorular</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/faqs/all">
                          Tüm S.S.S
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/faqs/addFaq">
                          Yeni S.S.S Ekle
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/faqs/Categorys">
                          S.S.S. Kategorileri
                        </Link>
                      </li>


                    </ul>
                  </li>




                  <li

                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "jobs" &&
                        this.state.current_page == "content"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">İş İlanları</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/jobs/all">
                          Tüm İş İlanları
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/jobs/addjob">
                          Yeni İş İlanı
                        </Link>
                      </li>


                    </ul>
                  </li>




                  <li

                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "blog" &&
                        this.state.current_page == "content"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Blog</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/blog/all">
                          Blog Yazıları
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/blog/add">
                          Blog Yazısı Ekle
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link
                          className="nav-sub-link"
                          to="/content/blog/Categorys"
                        >
                          Blog Kategorileri
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li

                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "pages" &&
                        this.state.current_page == "content"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Sayfalar</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/pages/all">
                          Tüm sayfalar
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/pages/AddPage">
                          Yeni Sayfa Ekle
                        </Link>
                      </li>

                    </ul>
                  </li>


                  <li

                    className={`nav-item deactive ${this.state.current_page == "content" ? "" : ""
                      } ${this.state.current_subpage == "press" &&
                        this.state.current_page == "content"
                        ? "show"
                        : ""
                      }`}
                  >
                    <Link className="nav-link with-sub" to="/#">
                      <span className="shape1" />
                      <span className="shape2" />
                      <span className="sidemenu-label">Haberler</span>
                      <i className="angle fe fe-chevron-right" />
                    </Link>
                    <ul className="nav-sub">
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/press/all">
                          Tüm Haberler
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link className="nav-sub-link" to="/content/press/add">
                          Haber Ekle
                        </Link>
                      </li>
                      <li className="nav-sub-item">
                        <Link
                          className="nav-sub-link"
                          to="/content/press/Categorys"
                        >
                          Haber Kategorileri
                        </Link>
                      </li>
                    </ul>
                  </li>




                </ul>
              </li>

              <li

                className={`nav-item ${this.state.current_page == "settings" ? "active" : ""
                  } ${this.state.current_subpage &&
                    this.state.current_page == "settings"
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-settings sidemenu-icon" />
                  <span className="sidemenu-label">Sistem Ayarları</span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
                <ul className="nav-sub">

                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/settings/Status">
                      Kolay İptal Durumlar
                    </Link>
                  </li>

                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/settings/allSettings">
                      Genel Ayarlar
                    </Link>
                  </li>



                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/settings/KiIptalNeden">
                      Sonlandırma Nedenleri
                    </Link>
                  </li>
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/settings/Kiinputs">
                      Kolay İptal Özel Alanlar
                    </Link>
                  </li>
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/settings/KiBrands">
                      Kolay İptal Kurumları
                    </Link>
                  </li>
                </ul>
              </li>


              <li

                className={`nav-item ${this.state.current_page == "members" ? "active" : ""
                  } ${this.state.current_subpage &&
                    this.state.current_page == "members"
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-user sidemenu-icon" />
                  <span className="sidemenu-label">Üye İşlemleri</span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
                <ul className="nav-sub">
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/members/allmembers">
                      Tüm Üyeler
                    </Link>
                  </li>

                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/members/subscriptionTypes">
                      Abonelik Tipleri
                    </Link>
                  </li>
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/members/MembershipPackages">
                      Üyelik Paketleri
                    </Link>
                  </li>
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/members/MembershipPackageFeatures">
                      Üyelik Paket Özellikleri
                    </Link>
                  </li>

                </ul>
              </li>


              <li

                className={`nav-item ${this.state.current_page == "users" ? "active" : ""
                  } ${this.state.current_subpage &&
                    this.state.current_page == "users"
                    ? "show"
                    : ""
                  }`}
              >
                <Link className="nav-link with-sub" to="/#">
                  <span className="shape1" />
                  <span className="shape2" />
                  <i className="ti-user sidemenu-icon" />
                  <span className="sidemenu-label">Kullanıcı İşlemleri</span>
                  <i className="angle fe fe-chevron-right" />
                </Link>
                <ul className="nav-sub">
                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/settings/Status">
                      Atlas Kullanıcıları
                    </Link>
                  </li>

                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/settings/Status">
                      Ulak Kullanıcıları
                    </Link>
                  </li>

                  <li className="nav-sub-item">
                    <Link className="nav-sub-link" to="/settings/Status">
                      Çözüm Ortağı Kullanıcıları
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidemenu;
