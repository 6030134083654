import { Route, Redirect } from 'react-router-dom';

const getWithExpiry = (key) => {
    
    const itemStr = localStorage.getItem("AtlasToken");
    console.log("PrimaryToken", itemStr);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
   

    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      //localStorage.removeItem(key)
      return null
    }
    return item.value
  }

const PrivateRoute = ({ component: Component, ...rest }) => {

    const tok = getWithExpiry("AtlasToken");
    return (
        <Route { ...rest } render={ props => (
            localStorage.getItem("AtlasToken") ? 
            <Component {...props} /> : 
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        ) }
        />
    );
};

export default PrivateRoute;