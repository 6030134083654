import cogoToast from "cogo-toast";
import moment from "moment";
import React, { button } from "react";

import { config } from "../../config";

import "moment/locale/tr";
import "react-block-ui/style.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const tableRef = React.createRef();
class BlogCategorys extends React.Component {
  constructor(props, context) {
    super(props);
    const datePickerId = moment(new Date()).format("YYYY-MM-DD[T]HH:mm");

    this.state = {
      categorys: [],
      isDraft: "all",
      allcats : 'true' ,
      create: { status: false },
      mindate: datePickerId,
      load_detail: false,
      selected_categorys: [],
      value: "",
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleCheckChieldElement = this.handleCheckChieldElement.bind(this);
    this.handleAllChecked = this.handleAllChecked.bind(this);
  }

  componentDidMount() {
 
  }

  handleAllChecked = (event) => {

      var secim = "true" ;
      if(this.state.allcats == 'true')
      {
         secim = "false " ;
      }
      console.log("Seçim" , secim , this.state.allcats);
      this.setState(
        {
          allcats: secim,
        });

    let categorys = this.state.categorys;
    categorys.forEach(
      (category) => (category.isChecked = event.target.checked)
    );

    this.setState(
      {
        categorys: categorys,
      },
      () => {
        let selected = [];
        let categorys = this.state.categorys;
        categorys.forEach((category) => {
          if (category.isChecked == true) selected.push(category);
        });
        this.setState({ selected_categorys: selected }, () => {
          this.filtrele();
        });
      }
    );
  };

  filtrele = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  handleCheckChieldElement = (event) => {
    let categorys = this.state.categorys;
    categorys.forEach((category) => {
      if (category.id === event.target.value)
        category.isChecked = event.target.checked;
    });

    this.setState(
      {
        categorys: categorys,
      },
      () => {
        let selected = [];
        let categorys = this.state.categorys;
        categorys.forEach((category) => {
          if (category.isChecked == true) selected.push(category);
        });
        this.setState({ selected_categorys: selected }, () => {
          this.filtrele();
          this.setState(
            {
              allcats: false,
            });
        });
      }
    );
  };

  RemoteData = () => {
    const deleteStatus = (id) => {
      fetch(this.state.BASE_URL + "/api/blog_post_delete/" + id, {
        method: "GET",
      }).then((response) => {
        if (response.status === 200) {
          tableRef.current && tableRef.current.onQueryChange();

          cogoToast.success("Yazı Başarılı Bir Şekilde Silindi", {
            position: "top-right",
          });
        } else {
          cogoToast.warn("Yazı Silinemedi Hata Oluştu", {
            position: "top-right",
          });
        }
      });
    };

    const getStatus = (id) => {
      this.setState({ create: { status: false } });
      this.setState({
        load_detail: true,
      });
      this.setState({
        edit: {
          id: "",
          name: "",
        },
      });
      fetch(this.state.BASE_URL + "/api/get_blog_category_single/" + id)
        .then((response) => {
          return response.json();
        })
        .then((resp) => {
          var sonuc = resp.data;
          this.setState({
            edit: {
              id: sonuc.id,
              name: sonuc.name,
              status: true,
            },
          });

          this.setState({
            load_detail: false,
          });
        });
    };
 
    return (
      <></>
    );
  };

  render() {
    return (
      <></>
    
    );
  }
}

export default BlogCategorys;
