import React, { Component } from 'react';
import { sortableContainer, sortableElement } from "react-sortable-hoc";

class Questions extends Component {
  constructor(props) {
    super(props);

  }


 
  render() {
   
  const SortableItem = sortableElement(
    ({ value, collection, index, sira, ust ,handleClick }) => (
      <li>
        <div className="card custom-card" key={index}>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4">
                <label className>{this.props.name_string} </label>
                <input
                  className="form-control"
                  required
                  type="text"
                  name="name"
                  onBlur={(event) => this.props.guncelle(ust, event, sira + 1)}
                  defaultValue={value.name}
                  placeholder={value.name}
                />
              </div>
              <div className="col-lg-6">
                <label className>{this.props.value_string}</label>
                <textarea
                  className="form-control"
                  rows="3"
                  name="value"
                  onBlur={(event) => this.props.guncelle(ust, event, sira + 1)}
                  defaultValue={value.value}
                  placeholder={value.value}
                />
              </div>
            
              <div className="col-lg-2">
                <button
                  className="btn btn-warning btn-sm"
                  onClick={(event) => this.props.delete(ust, sira)}
                >
                  <i className="fe fe-trash-2"></i> Sil
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  );

  const SortableContainer = sortableContainer(({ children }) => {
    return <div>{children}</div>;
  });
    return (
        <SortableContainer onSortEnd={this.props.onSortEnd}>
        <ul className="SortableList">
          {this.props.data.map((alt_template, is) => (
            <SortableItem
              className="SortableItem"
              key={"item" + is}
              value={alt_template}
              index={is}
              sira={is}
              ust={this.props.ust}
              collection={this.props.ust}
            >
  
            </SortableItem>
          ))}
        </ul>
      </SortableContainer>
     
    );
  }
}

export default Questions;

