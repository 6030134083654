import React, { button } from "react";
import { Link ,Redirect } from "react-router-dom";
import Ustkisim from "../inc/Header";
import { seo } from "../../helpers";
import { config } from "../../config";
import Moment from "moment";
import "moment/locale/tr";
import MaterialTable from "material-table";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import Checkbox from "../../bilesenler/Checkbox";
import Switch from "react-switch";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
const tableRef = React.createRef();
class BlogCategorys extends React.Component {
  constructor(props, context) {
    super(props);
    const datePickerId = moment(new Date()).format("YYYY-MM-DD[T]HH:mm");

    this.state = {
      categorys: [],
      isDraft: "all",
      allcats : 'true' ,
      create: { status: false },
      mindate: datePickerId,
      load_detail: false,
      selected_categorys: [],
      value: "",
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleCheckChieldElement = this.handleCheckChieldElement.bind(this);
    this.handleAllChecked = this.handleAllChecked.bind(this);
  }

  componentDidMount() {
    seo({
      title: "S.S.S. | Atlas",
      metaDescription: "Atlas Ana Ekranı",
    });

    fetch(this.state.BASE_URL + "/api/faq_Categorys_all")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        let categorys = result;
        categorys.forEach((category) => (category.isChecked = true));

        this.setState(
          {
            categorys: categorys,
          },
          () => {
            let selected = [];
            let categorys = this.state.categorys;
            categorys.forEach((category) => {
              if (category.isChecked == true) selected.push(category);
            });
            this.setState({ selected_categorys: selected });
          }
        );
      });
  }

  handleAllChecked = (event) => {

      var secim = "true" ;
      if(this.state.allcats == 'true')
      {
         secim = "false " ;
      }
      console.log("Seçim" , secim , this.state.allcats);
      this.setState(
        {
          allcats: secim,
        });

    let categorys = this.state.categorys;
    categorys.forEach(
      (category) => (category.isChecked = event.target.checked)
    );

    this.setState(
      {
        categorys: categorys,
      },
      () => {
        let selected = [];
        let categorys = this.state.categorys;
        categorys.forEach((category) => {
          if (category.isChecked == true) selected.push(category);
        });
        this.setState({ selected_categorys: selected }, () => {
          this.filtrele();
        });
      }
    );
  };

  filtrele = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  handleCheckChieldElement = (event) => {
    let categorys = this.state.categorys;
    categorys.forEach((category) => {
      if (category.id === event.target.value)
        category.isChecked = event.target.checked;
    });

    this.setState(
      {
        categorys: categorys,
      },
      () => {
        let selected = [];
        let categorys = this.state.categorys;
        categorys.forEach((category) => {
          if (category.isChecked == true) selected.push(category);
        });
        this.setState({ selected_categorys: selected }, () => {
          this.filtrele();
          this.setState(
            {
              allcats: false,
            });
        });
      }
    );
  };

  RemoteData = () => {
    const deleteStatus = (id) => {
      fetch(this.state.BASE_URL + "/api/faq_delete/" + id, { 
        method: "GET",
      }).then((response) => {
        if (response.status === 200) {
          tableRef.current && tableRef.current.onQueryChange();

          cogoToast.success("S.S.S. Başarılı Bir Şekilde Silindi", {
            position: "top-right",
          });
        } else {
          cogoToast.warn("S.S.S. Silinemedi Hata Oluştu", {
            position: "top-right",
          });
        }
      });
    };

    const getStatus = (id) => {
      this.setState({ create: { status: false } });
      this.setState({
        load_detail: true,
      });
      this.setState({
        edit: {
          id: "",
          name: "",
        },
      });
      fetch(this.state.BASE_URL + "/api/get_blog_category_single/" + id)
        .then((response) => {
          return response.json();
        })
        .then((resp) => {
          var sonuc = resp.data;
          this.setState({
            edit: {
              id: sonuc.id,
              name: sonuc.name,
              status: true,
            },
          });

          this.setState({
            load_detail: false,
          });
        });
    };
 
    return (
      <MaterialTable
        key="Tablom"
        title=" S.S.S."
        tableRef={tableRef}
        actions={[
          (rowData) => ({
            icon: "edit",
            tooltip: "Düzenle",
            onClick: (event, rowData) => this.props.history.push("/content/faqs/faq/"+rowData.id)  ,
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "S.S.S.  Sil",
            onClick: (event, rowData) =>
              confirmAlert({
                title: "S.S.S. Silinecek",
                message: "S.S.S. Silmek İstediğinize Emin misiniz?",
                buttons: [
                  {
                    label: "Hayır",
                    onClick: () => {
                      return true;
                    },
                  },
                  {
                    label: "Evet",
                    onClick: () => {
                      deleteStatus(rowData.id);
                      console.log(rowData.id);
                    }, //tableRef.current && tableRef.current.onQueryChange() ;
                  },
                ],
              }),
            disabled: rowData.count > 0,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          filtering: false,
          pageSizeOptions: [1, 2, 3, 4, 5, 50, 100, 200],
          pageSize: 50,
          headerStyle: {
            height: "25px !important",
            padding: "5px 20px",
          },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{count} sonuçtan {from}-{to} arası sonuçlar",
            labelRowsSelect: "data göster ",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: "İşlem",
          },
          body: {
            emptyDataSourceMessage: "Gösterilecek Data Bulunamadı",
            filterRow: {
              filterTooltip: "Filtre",
            },
          },
        }}
        columns={[

          {
            title: "Tarih",
            field: "ques_date",
            render: (rowData) => (
              <p className="text-nowrap">
                <strong>
                  {Moment(rowData.ques_date).format("DD MMM YYYY")}
                </strong>
              </p>
            ),
          },
          
          {
            title: "Soru",
            field: "ques_title",
            render: (rowData) => (
              <p>
                <strong>{rowData.ques_title}</strong>
              </p>
            ),
          },
          
          {
            title: "Cevap",
            field: "ques_description",
            render: (rowData) => (
              <p>
                <strong>{rowData.ques_description}</strong>
              </p>
            ),
          }
          ,
          {
            title: "Resim",
            field: "image",
            render: (rowData) => (
              <div className="main-img-user avatar-md">
                <img alt="Resim" className="square" src={rowData.image} />
              </div>
            ),
          },
          {
            title: "Kategori",
            field: "category_name",
            render: (rowData) => (
              <p>
                <strong>{rowData.category_name}</strong>
              </p>
            ),
          }
          
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            if (!query.orderDirection) {
              query.orderBy = { field: "id" };
              query.orderDirection = "desc";
            }

            let selected = [];
            this.state.selected_categorys.forEach((category) => {
              selected.push(category.id);
            });

            let url = config().BASE_URL + "/api/all_faqs?";
            url += "limit=" + query.pageSize;
            url +=
              "&order[" + query.orderBy.field + "]=" + query.orderDirection;
            url += "&page=" + (query.page + 1);
            url += "&categorys=" + selected.join(",");

            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                if (result.error) {
                  cogoToast.warn(
                    "Webservis Kaynaklı Sorun Oluşmuştur, Lütfen Sistem Yöneticisine Bilgi Veriniz...",
                    { position: "bottom-right" }
                  );
                }

                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    );
  };

  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img alt="Resim" src="../assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    S.S.S.
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Atlas</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    S.S.S.
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <Link
                      to={"/content/faqs/addFaq"}
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-plus mr-2" /> Yeni  S.S.S. Ekle
                    </Link>
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                    <div className="card-body pt-0">{this.RemoteData()}</div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                   <div className="card custom-card">
                    <div className="card-body">
                      <div className="card-header pb-0 border-bottom-0 pb-1">
                        <label className="main-content-label pb-2  ">
                          S.S.S. Kategorileri
                        </label>
                      </div>
                      <div className="card-body pb-0 pt-0">
                        <div className="d-flex flex-column">
                       
                          <div className="form-group">
                            {this.state.categorys.map((category) => {
                              return (
                                <Checkbox
                                  key={"kategori_" + category.id}
                                  value={category.id}
                                  handleCheckChieldElement={
                                    this.handleCheckChieldElement
                                  }
                                  {...category}
                                />
                              );
                            })}
                          </div>

                          <div className="form-group">
                            <label className="selectgroup-item">
                              <input
                                type="checkbox"
                                onChange={this.handleAllChecked}
                                value="checkedall"
                                checked={this.state.allcats === "true"}
                                className="selectgroup-input"
                              />
                              <span className="selectgroup-button">
                                <i className="fe fe-check inpus"></i> Hepsini
                                Seç 
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogCategorys;
