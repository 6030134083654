import React from 'react';
import { Link } from 'react-router-dom';
import Ustkisim from './inc/Header';
import Footer from './inc/Footer';
import { config } from "../config";
import Moment from "moment";
import "moment/locale/tr"; 
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import PageHeader from "../bilesenler/PageHeader"

class MailBox extends React.Component {
	constructor(props) {
		super(props);
        this.state = {total : 0 , page : 0 , limit : 10 , selectfolder : 'INBOX' ,load_folder : false , load_mailbox : false ,  mailbox : [] , folders: [{folder : 'text' , count : '0' }]};
        this.state.BASE_URL = config().BASE_URL;
        this.state.loop = [0,1,2,3,4,5,6,7,9]
	}
	
	componentDidMount() {
        this.setState({
            load_folder : true 
        });
		fetch(this.state.BASE_URL+'/api/getEmailFoldersLocal')
			.then(response => {
				return response.json();
			}).then(result => {
			
				this.setState({
                    folders:result,
                    load_folder : false  
				});
            });
            this.getmailBox();
	}
	
	    changeFolder(option) {
   
            this.setState({
                selectfolder: option,
                page : 0 ,
            }, () => {
                this.getmailBox();
            });

       
      }
    
	
      nexPage() {
        
        this.setState({
            page:  this.state.page + 1
        }, () => {
            this.getmailBox();
        });
       
    }

    prevPage() {
        
        this.setState({
            page:  this.state.page - 1
        }, () => {
            this.getmailBox();
        });
       
    }

    
      
      getmailBox = () => {
        this.setState({
            load_mailbox : true 
        });
 
        fetch(this.state.BASE_URL+'/api/getEmailList?page='+this.state.page+'&limit='+this.state.limit+'&folder='+this.state.selectfolder)
        .then(response => {
            return response.json();
        }).then(result => {
        
            this.setState({
                mailbox:result.data,
                total:result.total,
                load_mailbox : false 
            });
        });

      }


	render() {

       
		return (

			<div>
		
				<Ustkisim />

				<div className="main-content side-content pt-0">
    <div className="container-fluid">

    <div id="global-loader-page">
        <div className="loader-img-page">
        <img src="/assets/img/loader.svg" />
        <div className="LoaderText">Lütfen Bekleyiniz...</div>
        </div>
			
		</div>
  <div className="inner-body">

  <PageHeader title="Mail Uygulaması" html={   <div className="justify-content-center">
  
          <button type="button" className="btn btn-primary my-2 btn-icon-text">
            <i className="fe fe-send mr-2" /> Yeni Mail Gönder
          </button>
        </div>} />

    <div className="row row-sm">
      <div className="col-xl-3 col-lg-4">
      <BlockUi
                      loader={
                        <div className="loader-img-page-div">
                          <img src="/assets/img/loader.svg" />
                          <div className="LoaderText-div">
                            Lütfen Bekleyiniz...
                          </div>
                        </div>
                      }
                      tag="div"
                      className="card custom-card mail-container task-container overflow-hidden"
                      blocking={this.state.load_folder}
                    >
       
          <div className>
         
            <div className="card-body tab-list-items">
              <div className="main-content-left main-content-left-mail">
                <div className="main-mail-menu">
                  <nav className="nav main-nav-column mg-b-20">
                  {this.state.folders.map((mail) =>
                     <Link className="nav-link" onClick={() => this.changeFolder(mail.folder_name)}
                     
                     className={mail.folder_name  == this.state.selectfolder ? "nav-link active" : "nav-link"}
                     
                     >
                     <i className={"fe "+mail.icon} /> {mail.folder} <span>{mail.count}</span>
                   </Link>
                    )}


                 
                  </nav>
                
              
                </div>
              </div>
            
          </div>
        </div>
        </BlockUi>
      </div>
      <div className="col-xl-9 col-lg-8  main-content-body-mail1">
        <div className="card custom-card mail-container task-container overflow-hidden">
          <div className="inbox-body p-4">
            <div className="mail-option">
              <div className="chk-all">
                <div className="btn-group">
                  <a data-toggle="dropdown" href="#" className="btn mini all" aria-expanded="false">
                    Hepsini Göster
                    <i className="fa fa-angle-down ml-2" />
                  </a>
                  <ul className="dropdown-menu">
                    <li><a href="#"> Hepsini Göster</a></li>
                    <li><a href="#"> Okunanlar</a></li>
                    <li><a href="#"> Okunmayanlar</a></li>
                  </ul>
                </div>
              </div>
              <div className="btn-group">
                <Link onClick={() => this.getmailBox()} className="btn mini tooltips  ml-2">
                  <i className=" fa fa-refresh" />
                </Link>
              </div>
              <div className="btn-group hidden-phone">
                <a data-toggle="dropdown" href="#" className="btn mini blue ml-2" aria-expanded="false">
                  İşlem
                  <i className="fa fa-angle-down ml-2" />
                </a>
                <ul className="dropdown-menu">
                  <li><a href="#"><i className="fa fa-edit mr-2" /> Hepsini Oku</a></li>
                  <li><a href="#"><i className="fa fa-ban mr-2" /> Spam</a></li>
                  <li className="divider" />
                  <li><a href="#"><i className="fa fa-trash mr-2" /> Sil</a></li>
                </ul>
              </div>
              <ul className="unstyled inbox-pagination">
              <li>
                  <Link onClick={() => this.prevPage()}  className="np-btn npx-btn" disabled = {(this.state.page == 0 )? "disabled" : ""} ><i className="fa fa-angle-left pagination-left" /></Link>
                </li>
                
                <li><span>{this.state.total} Mail'den {this.state.page * this.state.limit} ile  {(this.state.page + 1 ) * this.state.limit} Arası Sonuçlar</span></li>
                <li>
                  <Link  onClick={() => this.nexPage()}  className="np-btn" disabled = {(this.state.total < ((this.state.page + 1 ) * this.state.limit) )? "disabled " : ""} ><i className="fa fa-angle-right pagination-right" /></Link>
                </li>
              </ul>
            </div>
           
            <BlockUi
                      loader={
                        <div className="loader-img-page-div">
                          <img src="/assets/img/loader.svg" />
                          <div className="LoaderText-div">
                            Lütfen Bekleyiniz...
                          </div>
                        </div>
                      }
                      tag="div"
                      className="table-responsive"
                      blocking={this.state.load_mailbox}
                    >
              <table className="table table-inbox text-md-nowrap table-hover text-nowrap">
                <tbody>
               {this.state.mailbox.length > 0  ?          
                    this.state.mailbox.map((mail) =>
                 <tr className>
                    <td className="inbox-small-cells">
                      <label className="custom-control custom-checkbox mb-0">
                        <input type="checkbox" className="custom-control-input" name="example-checkbox2" defaultValue="option2" />
                        <span className="custom-control-label" />
                      </label>
                    </td>
                    <td className="inbox-small-cells">
                        {mail.attachment  ? <i className="fe fe-paperclip" /> : '' }
                        

                        </td>
                    <td className="view-message dont-show font-weight-semibold">{mail.from}</td>
                    <td className="view-message">{mail.subject}</td>
                    <td className="view-message text-right font-weight-semibold">{   Moment(mail.date).format("D MMM YYYY hh:mm")}</td>
                  </tr>
                   )
                    : 
                this.state.loop.map((mail) =>
                    <tr className>
                    <td className="inbox-small-cells">
                    &nbsp; 
                    </td>
                    <td className="inbox-small-cells"> &nbsp; </td>
                    <td className="inbox-small-cells"> &nbsp; </td>
                    <td className="view-message dont-show font-weight-semibold"> &nbsp; </td>
                    <td className="view-message"> &nbsp; </td>
                    <td className="view-message text-right font-weight-semibold"> &nbsp; </td>
                  </tr>
                )
               }
                
                </tbody>
              </table>
              </BlockUi>
          
          </div>
          {/* mail-content */}
        </div>
      </div>
    </div>
    {/* /row */}
  </div>
</div>

    
  </div>
			
			</div>
		)
	}
}

export default MailBox;