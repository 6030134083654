import React, { button } from "react";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "moment/locale/tr";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader";
import GlobalLoader from "../../bilesenler/GlobalLoader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
} from "react-bootstrap";
import InputMask from "react-input-mask";

import { Link, Switch, Route, useRouteMatch } from "react-router-dom";
class AllSettings extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      BASE_URL: "",
      settings: { company: {}, login: {} },
    };
    this.state.BASE_URL = config().BASE_URL;
  }

  handleOnChange(type, event) {
    const slidem = this.state.settings[type];
    slidem[event.target.name] = event.target.value;
    this.setState({
      slidem,
    });
  }



  updateSettings = () => {
    this.setState(
      {
        addBlockLoad: true,
        form: this.state.slide,
      },
      () => {
        fetch(this.state.BASE_URL + "/api/updateSettings", {
          method: "POST",
          body: JSON.stringify(this.state.settings),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              addBlockLoad: false,
            });
            if (result.success) {
              cogoToast.success("Ayarlar Başarılı Bir Şekilde Güncellendi", {
                position: "top-right",
              });
            } else {
              cogoToast.warn("Ayarlar Güncellenirken Hata Oluştu", {
                position: "top-right",
              });
            }
          })
          .catch((error) => {
            this.setState({
              addBlockLoad: false,
            });
            cogoToast.warn("Ayarlar Güncellenirken Hata Oluştu", {
              position: "top-right",
            });
          });
      }
    );
  };

  componentDidMount() {
    fetch(this.state.BASE_URL + "/api/get_settings")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        //result.login = {};
        this.setState({

          settings: result,
        });
      });
  }

  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <GlobalLoader />
            <div className="inner-body">
              <PageHeader title="Genel Sistem Ayarları" html="" />

              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                    <BlockUi
                      loader={
                        <div className="loader-img-page-div">
                          <img alt="Resim" src="/assets/img/loader.svg" />
                          <div className="LoaderText-div">
                            Ayarlar Güncellenirken Lütfen Bekleyiniz...
                          </div>
                        </div>
                      }
                      tag="div"
                      className="card-body"
                      blocking={this.state.addBlockLoad}
                    >
                      <Tabs
                        defaultActiveKey="home"
                        id="controlled-tab-example"
                        className="nav nav-pills "
                      >
                        <Tab eventKey="home" title="Şirket Bilgileri">
                          <div className="row row-sm">
                            <div className="col-lg-12 col-md-12">
                              <div className="card custom-card">
                                <div className="card-body">
                                  <div>
                                    <h6 className="main-content-label mb-1">
                                      Şirket İle İlgili Sistem Ayarları
                                    </h6>
                                    <p className="text-muted card-sub-title"></p>
                                  </div>
                                  <div className="row row-sm">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                      <div className>
                                        <div className="form-group">
                                          <label className>Şirket ünvanı</label>
                                          <input
                                            className="form-control"
                                            required
                                            type="text"
                                            value={
                                              this.state.settings.company.name
                                            }
                                            name="name"
                                            onChange={(event) =>
                                              this.handleOnChange(
                                                "company",
                                                event
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="form-group">
                                          <div className="row row-sm">
                                            <div className="col-sm-4">
                                              <label className>
                                                Mail Adresi
                                              </label>
                                              <div className="pos-relative">
                                                <input
                                                  className="form-control pd-r-80"
                                                  required
                                                  type="email"
                                                  value={
                                                    this.state.settings.company
                                                      .email
                                                  }
                                                  name="email"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "company",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <label className>
                                                Telefon Numarası
                                              </label>
                                              <div className="pos-relative">
                                                <InputMask
                                                  mask="+\90 999 999 99 99"
                                                  className="form-control pd-r-80"
                                                  value={
                                                    this.state.settings.company
                                                      .phone
                                                  }
                                                  name="phone"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "company",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <label className>
                                                Faks Numarası{" "}
                                              </label>
                                              <div className="pos-relative">
                                                <InputMask
                                                  mask="+\90 999 999 99 99"
                                                  className="form-control pd-r-80"
                                                  value={
                                                    this.state.settings.company
                                                      .fax
                                                  }
                                                  name="fax"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "company",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="form-group">
                                          <label className>Şirket Adresi</label>
                                          <input
                                            className="form-control"
                                            required
                                            type="text"
                                            value={
                                              this.state.settings.company
                                                .address
                                            }
                                            name="address"
                                            onChange={(event) =>
                                              this.handleOnChange(
                                                "company",
                                                event
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="form-group">
                                          <label className>
                                            Google Harita Kodu
                                          </label>
                                          <input
                                            className="form-control"
                                            required
                                            type="text"
                                            value={
                                              this.state.settings.company.map
                                            }
                                            name="map"
                                            onChange={(event) =>
                                              this.handleOnChange(
                                                "company",
                                                event
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="form-group">
                                          <div className="row row-sm">
                                            <div className="col-sm-4">
                                              <label className>
                                                Facebook
                                              </label>
                                              <div className="pos-relative">
                                                <input
                                                  className="form-control pd-r-80"
                                                  required
                                                  type="text"
                                                  value={
                                                    this.state.settings.company
                                                      .facebook
                                                  }
                                                  name="facebook"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "company",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <label className>
                                                Twitter
                                              </label>
                                              <div className="pos-relative">
                                                <input
                                                  className="form-control pd-r-80"
                                                  required
                                                  type="text"
                                                  value={
                                                    this.state.settings.company
                                                      .twitter
                                                  }
                                                  name="twitter"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "company",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <label className>
                                                Instagram
                                              </label>
                                              <div className="pos-relative">
                                                <input
                                                  className="form-control pd-r-80"
                                                  required
                                                  type="text"
                                                  value={
                                                    this.state.settings.company
                                                      .instagram
                                                  }
                                                  name="instagram"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "company",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <label className>
                                                Linkedin
                                              </label>
                                              <div className="pos-relative">
                                                <input
                                                  className="form-control pd-r-80"
                                                  required
                                                  type="text"
                                                  value={
                                                    this.state.settings.company
                                                      .linkedin
                                                  }
                                                  name="linkedin"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "company",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <label className>
                                                Youtube
                                              </label>
                                              <div className="pos-relative">
                                                <input
                                                  className="form-control pd-r-80"
                                                  required
                                                  type="text"
                                                  value={
                                                    this.state.settings.company
                                                      .youtube
                                                  }
                                                  name="youtube"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "company",
                                                      event
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>






                                          </div>
                                        </div>



                                        <button
                                          className="btn ripple btn-main-primary pull-right"
                                          onClick={this.updateSettings}
                                        >
                                          Ayarları Kaydet
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab>
                        <Tab eventKey="profile" title="Giriş İşlemleri">
                          <div className="row row-sm">
                            <div className="col-lg-12 col-md-12">
                              <div className="card custom-card">
                                <div className="card-body">
                                  <div>
                                    <h6 className="main-content-label mb-1">
                                      Giriş İşlemleri
                                    </h6>
                                    <p className="text-muted card-sub-title"></p>
                                  </div>
                                  <div className="row row-sm">
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                      <div className>

                                        <div className="form-group">
                                          <div className="row row-sm">
                                            <div className="col-sm-4">
                                              <label className>
                                                Mercan
                                              </label>
                                              <div className="pos-relative">
                                                <select className="form-control pd-r-80"
                                                  required
                                                  value={
                                                    this.state.settings.login.mercan
                                                  }
                                                  name="mercan"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "login",
                                                      event
                                                    )
                                                  }>
                                                  <option value="0">Pasif</option>
                                                  <option value="1">Aktif</option>
                                                </select>

                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <label className>
                                                Apple İle Giriş
                                              </label>
                                              <div className="pos-relative">
                                                <select className="form-control pd-r-80"
                                                  required
                                                  value={
                                                    this.state.settings.login.apple
                                                  }
                                                  name="apple"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "login",
                                                      event
                                                    )
                                                  }>
                                                  <option value="0">Pasif</option>
                                                  <option value="1">Aktif</option>
                                                </select>

                                              </div>
                                            </div>

                                            <div className="col-sm-4">
                                              <label className>
                                                Google İle Giriş
                                              </label>
                                              <div className="pos-relative">
                                                <select className="form-control pd-r-80"
                                                  required
                                                  value={
                                                    this.state.settings.login.google
                                                  }
                                                  name="google"
                                                  onChange={(event) =>
                                                    this.handleOnChange(
                                                      "login",
                                                      event
                                                    )
                                                  }>
                                                  <option value="0">Pasif</option>
                                                  <option value="1">Aktif</option>
                                                </select>

                                              </div>
                                            </div>


                                          </div>
                                        </div>


                                        <button
                                          className="btn ripple btn-main-primary pull-right"
                                          onClick={this.updateSettings}
                                        >
                                          Ayarları Kaydet
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </Tab>
                      </Tabs>
                    </BlockUi>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card custom-card">
                    <BlockUi
                      loader={
                        <div className="loader-img-page-div">
                          <img src="../assets/img/loader.svg" />
                          <div className="LoaderText-div">
                            Lütfen Bekleyiniz...
                          </div>
                        </div>
                      }
                      tag="div"
                      className="card-body"
                      blocking={this.state.load_detail}
                    ></BlockUi>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AllSettings;
