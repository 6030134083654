import React, { button } from "react";
import { Link } from "react-router-dom";
import Ustkisim from "./inc/Header";
import Footer from "./inc/Footer";
import { seo } from "../helpers";
import { config } from "../config";
import Moment from "moment";
import "moment/locale/tr";
import MaterialTable from "material-table";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Loader from 'react-loaders'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class ListEmailAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { faks: {}, load_detail: false };
    this.state.BASE_URL = config().BASE_URL;
  }

  componentDidMount() {
    seo({
      title: "E-Posta Hesapları | Maya",
      metaDescription: "Maya Ana Ekranı",
    });
  }

  getfaks = (id) => {
    this.setState({
      load_detail: true,
    });
    fetch(this.state.BASE_URL + "/api/sender_get_single_faks/" + id)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          faks: result,
          load_detail: false,
        });
      });
  };

  statusKontrol = (id) => {
    var durum = {};
    if (id == "sent") {
      durum = {
        title: "Başarıyla Gönderildi",
        color: "badge badge-pill badge-success-light",
      };
    } else if (id == "wait") {
      durum = {
        title: "Gönderilmeye Hazırlanıyor",
        color: "badge badge-pill badge-primary-light",
      };
    } else if (id == "error") {
      durum = {
        title: "Gönderim Başarısız",
        color: "badge badge-pill badge-danger-light",
      };
    } else {
      durum = {
        title: "Bilinmiyor",
        color: "badge badge-pill badge-warning-light",
      };
    }
    return durum;
  };




  RemoteData = () => {
    const tableRef = React.createRef();
    let  promisem;
    return (
      <MaterialTable
        title="Tüm E-Posta Hesapları"
        tableRef={tableRef}
        actions={[
      
          rowData => ({
            icon: 'edit',
            tooltip: 'Düzenle',
            onClick: (event, rowData) =>   confirmAlert({
              title: 'Hesap Silinecek',
              message: 'Mail Hesabını Silmek İstediğinize Emin misiniz?',
              buttons: [
                {
                  label: 'Evet',
                  onClick: () => tableRef.current && tableRef.current.onQueryChange() 
                },
                {
                  label: 'Hayır',
                  onClick: () => true
                }
              ]
            }),
            disabled: rowData.is_admin == true
          }),
          rowData => ({
            icon: 'delete',
            tooltip: 'Delete User',
            onClick: (event, rowData) => alert("You want to delete " + rowData.name),
            disabled: rowData.is_admin == true
          })
        ]}
        options={{
          actionsColumnIndex: -1,
          search: true,
          filtering: false,
          pageSizeOptions: [50, 100, 200],
          pageSize : 50 ,
          headerStyle: {
       
            height: "25px !important",
            padding: "5px 20px"
          }
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{count} sonuçtan {from}-{to} arası sonuçlar",
            labelRowsSelect: "data göster ",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: "İşlem",
          },
          body: {
            emptyDataSourceMessage: "Gösterilecek Data Bulunamadı",
            filterRow: {
              filterTooltip: "Filtre",
            },
          },
        }}

      //  $id = $data->id;
      //  $name = $data->name;
      //  $email = $data->email;
      //  $created = $data->created;
       // $is_robot = $data->is_robot;
      //  $is_dismissed = $data->is_dismissed;
       // $is_admin = $data->is_admin;

        columns={[
          { title: "Mail Adresi", field: "email" },
          {
            title: "İsim Soyisim",
            field: "name",
            render: (rowData) =>
            rowData.name.first+" "+rowData.name.last,
          },
          {
            title: "Durumu",
            field: "is_dismissed",
            render: (rowData) =>
            rowData.is_dismissed == false ? <span class="badge badge-pill badge-success-light">Aktif</span> : <span class="badge badge-pill badge-danger-light">Pasif</span>
          },
          {
            title: "Kullanıcı Tipi",
            field: "is_admin",
            render: (rowData) =>
            rowData.is_admin == true ? <span class="badge badge-pill badge-success-light">Yönetici</span> : <span class="badge badge-pill badge-primary-light">Kullanıcı</span>
          },
          {
            title: "Oluşturma Tarihi",
            field: "created",
            render: (rowData) =>
              Moment(rowData.created).format("d MMM YYYY"),
          },
        
         
        ]}
        data={(query) =>
          
          new Promise((resolve, reject) => {
           
           

            if (promisem)
            {
            
              clearTimeout(promisem)
            }
           

             promisem =   setTimeout(function(){ 
              
              
                                 

            if (!query.orderDirection) {
              query.orderBy = { field: "id" };
              query.orderDirection = "asc";
            }
          
            let url = config().BASE_URL + "/api/list_accounts?";
            url += "per_page=" + query.pageSize;
            url += "&nickname=" + query.search ;
            url += "&page=" + (query.page + 1);

            fetch(url)
              .then((response) => response.json())
              .then((result) => {
               
                if(result.success)
                {

                  var datam = [] ;
                  result.result.result.forEach(function(item, index, object) {
            
                    if (item.is_robot == false) {
                      datam.push(item);
                    
                    }
                    
                  });

                  resolve({
                    data: datam,
                    page: result.result.page - 1,
                    totalCount: result.result.total,
                  });
                }
              
              });
            }, 500);
          })
        }
      />
    );
  };

  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="../assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                  E-Posta Hesapları
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Maya</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    E-Posta Hesapları
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-download mr-2" /> Faks Gönder
                    </button>
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                    <div className="card-body pt-0">{this.RemoteData()}</div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card custom-card">
                    <BlockUi
                    loader={
                    <div className="loader-img-page-div">
                      <img src="../assets/img/loader.svg" />
                      <div className="LoaderText-div">Lütfen Bekleyiniz...</div>
                    </div>}
                      tag="div"
                      className="card-body"
                      blocking={this.state.load_detail}
                    >
                      {!this.state.faks.id ? (
                        <div class="bg-white box-shadow custom-card card">
                          <div class="alert text-center fade show p-3">
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                            <i class="fe fe-download-cloud fs-50 text-primary"></i>
                            <h5 class="mt-2 mb-1">Henüz Seçim Yapmadınız!</h5>
                            <p class="mb-3 mb-3 tx-inverse">
                              Lütfen Önce Tablodan Bir Kayıt Seçiniz
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div class="card-header pb-0 border-bottom-0">
                            <label class="main-content-label mb-2 pt-1">
                              Faks Detayları
                            </label>
                            <p class="tx-12 mb-0 text-muted">
                              Faks Detaylarına Aşağıdan Ulaşabilirsiniz.
                            </p>
                          </div>
                          <div class="card-body">
                            <ul class="visitor mb-0 d-block users-images list-unstyled list-unstyled-border">
                              <li class="media d-flex mb-3 mt-0 pt-0">
                                <div class="media-body mb-1">
                                  <div class="float-right">
                                    {this.state.faks.created_at
                                      ? Moment(
                                          this.state.faks.created_at
                                        ).format("d MMM YYYY hh:mm")
                                      : ""}
                                  </div>
                                  <h5 class="media-title tx-15 mb-0">
                                    Gönderme Tarihi :{" "}
                                  </h5>
                                </div>
                              </li>
                              <li class="media d-flex mb-3">
                                <div class="media-body mb-1">
                                  <div class="float-right">
                                    {this.state.faks.did.number}
                                  </div>
                                  <h5 class="media-title tx-15 mb-0">
                                    {" "}
                                    Gönderen :{" "}
                                  </h5>
                                </div>
                              </li>

                              {this.state.faks.recipients.map((n) => (
                                <li class="media d-flex mb-3">
                                  <div class="media-body mb-1">
                                    <div class="float-right">{n.recipient}</div>
                                    <h5 class="media-title tx-15 mb-0">
                                      {" "}
                                      Gönderilen :{" "}
                                    </h5>

                                    <span class="text-muted">
                                      <span
                                        className={
                                          this.statusKontrol(n.state).color
                                        }
                                      >
                                        {this.statusKontrol(n.state).title}
                                      </span>
                                    </span>
                                  </div>
                                </li>
                              ))}

                              <li class="media d-flex mb-3">
                                <div class="media-body mb-1">
                                  <div class="float-right">
                                    <a
                                      href={this.state.faks.attachment}
                                      target="_blank"
                                      className="btn btn-sm btn-success"
                                    >
                                      Belgeyi İndir
                                    </a>
                                  </div>
                                  <h5 class="media-title tx-15 mb-0">Belge</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </BlockUi>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ListEmailAccounts;
