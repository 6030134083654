import React, { button } from "react";
import { Link, withRouter } from "react-router-dom";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader";
import "react-confirm-alert/src/react-confirm-alert.css";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class UpdateJob extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      jobid: this.props.jobid,
      categorys: {},
      cat_id: [],
      form: {},
      tanim: [{ name: "" }],
      nitelik: [{ name: "" }],
      addBlockLoad: false,
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleMultiChange = this.handleMultiChange.bind(this);
  }

  componentDidMount() {
    fetch(this.state.BASE_URL + "/api/get_job_single/" + this.state.jobid)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          name: result.data.name,
          description: result.data.description,
          nitelik: result.data.nitelik,
          tanim: result.data.tanim,
        });
      });
  }

  handleOnChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleMultiChange(option) {
    this.setState((state) => {
      return {
        cat_id: option.id,
      };
    });
  }

  tanimeval(event, sira) {
    const stat = this.state.tanim;
    const name = "name";
    const value = event.target.value;
    stat[sira][name] = value;
    this.setState({ stat });
  }

  addItem() {
    const stat = this.state.tanim;
    const veri = { name: "" };

    stat.push(veri);

    this.setState({ stat });
  }

  removeItems = (i) => {
    const stat = this.state.tanim;
    stat.splice(i, 1);
    this.setState({ stat });
  };

  nitelikval(event, sira) {
    const stat = this.state.nitelik;
    const name = "name";
    const value = event.target.value;
    stat[sira][name] = value;
    this.setState({ stat });
  }

  addItemnitelik() {
    const stat = this.state.nitelik;
    const veri = { name: "" };

    stat.push(veri);

    this.setState({ stat });
  }

  removeItemsnitelik = (i) => {
    const stat = this.state.nitelik;
    stat.splice(i, 1);
    this.setState({ stat });
  };

  addJob = () => {
    this.setState(
      {
        addBlockLoad: true,
        form: {
          id: this.state.jobid,
          name: this.state.name,
          description: this.state.description,
          tanim: this.state.tanim,
          nitelik: this.state.nitelik,
        },
      },
      () => {
        fetch(this.state.BASE_URL + "/api/addJob", {
          method: "POST",
          body: JSON.stringify(this.state.form),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              addBlockLoad: false,
            });
            if (result.success) {
              cogoToast.success("İş İlanı Başarılı Bir Şekilde Güncellendi", {
                position: "top-right",
              });

              this.props.history.push("/content/jobs/all");
              this.setState({
                categorys: {},
                loadImage: false,
                form: {},
                addBlockLoad: false,
              });
            } else {
              cogoToast.warn("İş İlanı Güncellenirken Hata Oluştu", {
                position: "top-right",
              });
            }
          });
      }
    );
  };

  icerikver = (e) => {
    this.setState({
      body: e,
    });
  };

  render() {
    return (
      <div>
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" alt="loading" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Yeni İş İlanını Güncelle"
                html={
                  <Link
                    to="/content/jobs/all"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> İş İlanı Geri Dön
                  </Link>
                }
              />
              <BlockUi
                loader={
                  <div className="loader-img-page-div">
                    <img alt="Resim" src="/assets/img/loader.svg" />
                    <div className="LoaderText-div">
                      İş İlanı Güncellenirken Lütfen Bekleyiniz...
                    </div>
                  </div>
                }
                tag="div"
                className="row row-sm"
                blocking={this.state.addBlockLoad}
              >
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label className="row">
                                  <div className="col-lg-12">İlan Başlığı</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="İlan Başlığı"
                                  id="name"
                                  name="name"
                                  value={this.state.name}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>

                              <div className="form-group">
                                <label className>İlan Açıklaması</label>
                                <div>
                                  <textarea
                                    rows="10"
                                    id="description"
                                    name="description"
                                    value={this.state.description}
                                    class="form-control"
                                    onChange={(event) =>
                                      this.handleOnChange(event)
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label className="row">
                                  <div className="col-lg-12">İlan Tanımı</div>
                                </label>

                                {this.state.tanim.map((template, i) => (
                                  <div
                                    className="d-flex mb-3"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <input
                                      className="form-control"
                                      required
                                      type="text"
                                      placeholder=""
                                      id="name"
                                      name="name"
                                      value={this.state.tanim[i].name}
                                      onChange={(event) =>
                                        this.tanimeval(event, i)
                                      }
                                    />
                                    <button
                                      className="btn btn-danger ml-3 btn-sm pull-right"
                                      onClick={(event) => this.removeItems(i)}
                                    >
                                      <i className="fe fe-trash"></i>{" "}
                                    </button>
                                  </div>
                                ))}

                                <div className="d-flex">
                                  <div className="ml-auto mt-3 d-flex">
                                    <div className="d-flex text-muted tx-13">
                                      <button
                                        className="btn btn-success  btn-sm pull-right"
                                        onClick={(event) => this.addItem()}
                                      >
                                        <i className="fe fe-plus"></i> Yeni Ekle
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label className="row">
                                  <div className="col-lg-12">
                                    Aranan Nitelikler
                                  </div>
                                </label>

                                {this.state.nitelik.map((template, i) => (
                                  <div
                                    className="d-flex mb-3"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <input
                                      className="form-control"
                                      required
                                      type="text"
                                      placeholder=""
                                      id="name"
                                      name="name"
                                      value={this.state.nitelik[i].name}
                                      onChange={(event) =>
                                        this.nitelikval(event, i)
                                      }
                                    />
                                    <button
                                      className="btn btn-danger ml-3 btn-sm pull-right"
                                      onClick={(event) =>
                                        this.removeItemsnitelik(i)
                                      }
                                    >
                                      <i className="fe fe-trash"></i>{" "}
                                    </button>
                                  </div>
                                ))}

                                <div className="d-flex">
                                  <div className="ml-auto mt-3 d-flex">
                                    <div className="d-flex text-muted tx-13">
                                      <button
                                        className="btn btn-success  btn-sm pull-right"
                                        onClick={(event) =>
                                          this.addItemnitelik()
                                        }
                                      >
                                        <i className="fe fe-plus"></i> Yeni Ekle
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="col-lg-12">
                        <button
                          className="btn ripple btn-main-primary pull-right"
                          onClick={this.addJob}
                        >
                          İş İlanı Güncelle
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </BlockUi>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UpdateJob);
