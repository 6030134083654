import cogoToast from "cogo-toast";
import React, { button } from "react";
import BlockUi from "react-block-ui";
import { InputTags } from "react-bootstrap-tagsinput";
import { confirmAlert } from "react-confirm-alert";
import Dropzone from "react-dropzone";
import EasyEdit, { Types } from "react-easy-edit";
import Loader from "react-loaders";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Switch from "react-switch";
import SunEditor from "suneditor-react";
// Import Sun Editor's CSS File
import lang from "suneditor/src/lang";

import GlobalLoader from "../../bilesenler/GlobalLoader";
import PageHeader from "../../bilesenler/PageHeader";
import { config } from "../../config";
import Ustkisim from "../inc/Header";

import "react-block-ui/style.css";
import "react-block-ui/style.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "suneditor/dist/css/suneditor.min.css";

const tableRef = React.createRef();

class addBlog extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      categorys: {},
      cat_id: [],
      form : {},
      addBlockLoad : false 
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleMultiChange = this.handleMultiChange.bind(this);    

  }

 
  componentDidMount() {
    fetch(this.state.BASE_URL + "/api/faq_Categorys_all")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          categorys: result,
        });
      });
  }
  handleOnChange(event) {
    this.setState({
      [event.target.name] : event.target.value
    })
  }

  handleMultiChange(option) {
  
    this.setState((state) => {
      return {
        cat_id: option.id,
      };
    });
  }



  ekleblog = () => {

    this.setState({
      addBlockLoad : true ,
      form : {

        ques_title : this.state.ques_title , 
        ques_description : this.state.ques_description , 
        cat_id : this.state.cat_id , 
      },
    }, () => {
   
    fetch(this.state.BASE_URL + "/api/addFaq", {
      method: "POST",
      body: JSON.stringify(this.state.form),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          addBlockLoad: false,
        });
        if (result.success) {
     
          cogoToast.success("S.S.S. Başarılı Bir Şekilde Oluşturuldu", {
            position: "top-right",
          });

          this.props.history.push("/content/faqs/all");
          this.setState({
            categorys: {},
            loadImage : false ,
            form : {},
            addBlockLoad : false 
           });


        } else {
          cogoToast.warn("S.S.S. Oluşturulurken Hata Oluştu", {
            position: "top-right",
          });
        }
      });



  });
    

  }
  



  resyukle = (files , info , core ,uploadHandler) => {
   
    console.log(files , info , core ,uploadHandler);

    return false ;
    
    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/", {
       method: 'POST',
       body: data
         }).then((response) => {
          return response.json();
        })
        .then((result) => {

          console.log(result);

        });


  }

  
  
  addImage = () => {

    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/", {
       method: 'POST',
       body: data
         }).then((response) => {
          return response.json();
        })
        .then((result) => {



          if (!result.success) {
            cogoToast.warn(
              "Resim Yüklenirken Sorun Oluştu.",
              { position: "top-right" }
            );
          }
          else 
          {
            cogoToast.success(
              "Resim Başarılı Bir Şekilde Yüklendi.",
              { position: "top-right" }
            );
            this.setState({
              blog_image: result.url,
              
            });
            
          }
          this.setState({
            loadImage: false,
          });
            console.log(result);
        });
    }


    

    icerikver = (e) => {

      this.setState({
        body: e,
      });

    };


  linkVer = (e) => {

    this.setState({
      title: e.target.value,
    });


    var str = e.target.value;
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    this.setState((state) => {
      return {
        link: str,
      };
    });
  };

  linkDegis = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    this.setState((state) => {
      return {
        link: str,
      };
    });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", this.state.BASE_URL + "/api/upPro/");
      const data = new FormData();
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText).url;

        resolve({ data: { link: response } });
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  };

  handleChange = (e) => {
    this.setState({ tags: e });
  };

  render() {
    const previewStyle = {
      display: "inline",
      width: 100,
      height: 100,
    };

    return (
      <div>
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
          <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Yeni S.S.S. Ekle"
                html={
                  <Link
                    to="/content/faqs/all"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> S.S.S.  Geri
                    Dön
                  </Link>
                }
              />
               <BlockUi
                            loader={
                              <div className="loader-img-page-div">
                                <img alt="Resim" src="/assets/img/loader.svg" />
                                <div className="LoaderText-div">
                                S.S.S. Eklenirken Lütfen Bekleyiniz...
                                </div>
                              </div>
                            }
                            tag="div"
                            className="row row-sm"
                            blocking={this.state.addBlockLoad} >
             
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label className="row">
                                  <div className="col-lg-12" >Soru</div>
  
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Soru"
                                  id="ques_title"
                                  name="ques_title"
                                  onChange={(event) => this.handleOnChange(event)}
                                />

                                                     
                              </div>

                              <div className="form-group">
                                <label className>S.S.S. Kategorisi</label>
                                <div className="pos-relative">
                                  <Select
                                    
                                    name="categorys"
                                    options={this.state.categorys}
                                    getOptionLabel={(option) =>
                                      `${option.name}`
                                    }
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={this.handleMultiChange}
                                    className="basic-single-select"
                                    classNamePrefix="select"
                                    placeholder="Lütfen Kategori Seçiniz"
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label className>Soru Cevabı</label>
                                <div>
                                  <textarea
                                   rows="10"
                                    id="ques_description"
                                    name="ques_description"
                                    class="form-control"
                                    onChange={(event) => this.handleOnChange(event)}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                           
                   
                            <div className="col-lg-12">
                            
                              <button className="btn ripple btn-main-primary pull-right"  onClick={this.ekleblog}>
                              S.S.S. Ekle
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
           
              </BlockUi>
           
             
            </div>
          </div>
        </div>
      
      </div>
      
    );
  }
}

export default withRouter(addBlog);
