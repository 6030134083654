import React from 'react';
import { Link } from 'react-router-dom';
import Ustkisim from './inc/Header';
import Footer from './inc/Footer';
import {seo} from '../helpers';
import Meeting from './Meeting';
import { ZoomMtg } from "@zoomus/websdk";




class Zoom extends React.Component {
	constructor(props) {
		super(props);
		this.state = {websites: []};
		this.headers = [
			{ key: 'id', label: 'Id'},
			{ key: 'title', label: 'Title' },
			{ key: 'url', label: 'URL' }
		];
		this.deleteWebsite = this.deleteWebsite.bind(this);
	}
	
	componentDidMount() {


		ZoomMtg.setZoomJSLib("https://source.zoom.us/1.8.3/lib", "/av");
		ZoomMtg.preLoadWasm();
		ZoomMtg.prepareJssdk();
		

			seo({
			  title: 'Maya Dashboard',
			  metaDescription: 'Maya Ana Ekranı'
			});


		fetch('http://localhost/maya3/index.php/websiterestcontroller/websites')
			.then(response => {
				return response.json();
			}).then(result => {
				console.log(result);
				this.setState({
					websites:result
				});
			});
	}
	
	deleteWebsite(id) {
		if(window.confirm("Are you sure want to delete?")) {
			fetch('http://localhost/maya3/index.php/websiterestcontroller/delete_website/' + id, {
                                method : 'DELETE'
                                   }).then(response => { 
					if(response.status === 200) {
						alert("Website deleted successfully");
                                                fetch('http://localhost/maya3/index.php/websiterestcontroller/websites')
						.then(response => {
							return response.json();
						}).then(result => {
							console.log(result);
							this.setState({
								websites:result
							});
						});
					} 
			 });
		}
	}
	
	render() {
        const toplanti_id = this.props.match.params.id;
		return (

			<div>
		
				<Ustkisim />

				<div className="main-content side-content pt-0">
    <div className="container-fluid">

    <div id="global-loader-page">
        <div className="loader-img-page">
        <img src="/assets/img/loader.svg" />
        <div className="LoaderText">Lütfen Bekleyiniz...</div>
        </div>
			
		</div>

      <div className="inner-body">
          {
              toplanti_id ? <Meeting meetid={toplanti_id} /> : '' 
          }
            
       </div>
    </div>
  </div>
 				
			</div>
		)
	}
}

export default Zoom;