import React, { button } from "react";
import { Link , withRouter } from "react-router-dom";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "react-block-ui/style.css";
import Loader from "react-loaders";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader";
import GlobalLoader from "../../bilesenler/GlobalLoader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import lang from "suneditor/src/lang";
import EasyEdit, { Types } from "react-easy-edit";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InputTags } from "react-bootstrap-tagsinput";
import Dropzone from "react-dropzone";
import Switch from "react-switch";

const tableRef = React.createRef();

class addBlog extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      body: "",
      tags: [],
      categorys: {},
      blog_categorys: [],
      link: "",
      title : "",
      loadImage : false ,
      file: {},
      form : {},
      addBlockLoad : false ,
      blog_image : "",
      isDraft: true 
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.linkVer = this.linkVer.bind(this);
    this.icerikver = this.icerikver.bind(this);
    this.resyukle = this.resyukle.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    
    this.onDrop = (file) => {
      this.setState({
        file: file,
      }, () => {
        this.addImage();
    });
     
    };
  }

 
  componentDidMount() {
    fetch(this.state.BASE_URL + "/api/blog_Categorys_all")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          categorys: result,
        });
      });
  }
  handleOnChange(event) {
    this.setState({
      short_description: event.target.value
    })
  }
  handleChangeCheck(isDraft) {
    this.setState({ isDraft });
  }

  handleMultiChange(option) {
    this.setState((state) => {
      return {
        blog_categorys: option,
      };
    });
  }

  kaldir = () => {
    this.setState({
      blog_image: "",
    });
  }

  ekleblog = () => {

    this.setState({
      addBlockLoad : true ,
      form : {
        body : this.state.body , 
        tags : this.state.tags , 
        slug : this.state.link , 
        image : this.state.blog_image , 
        title : this.state.title ,
        isDraft : this.state.isDraft ,
        description : this.state.short_description, 
        categorys : this.state.blog_categorys , 
      },
    }, () => {
   
    fetch(this.state.BASE_URL + "/api/addBlogPOst", {
      method: "POST",
      body: JSON.stringify(this.state.form),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          addBlockLoad: false,
        });
        if (result.success) {
     
          cogoToast.success("Yazı Başarılı Bir Şekilde Oluşturuldu", {
            position: "top-right",
          });

          this.props.history.push("/content/blog/all");
          this.setState({
            body: "",
            tags: [],
            categorys: {},
            blog_categorys: [],
            link: "",
            title : "",
            loadImage : false ,
            file: {},
            form : {},
            addBlockLoad : false ,
            blog_image : "",
            isDraft: true });


        } else {
          cogoToast.warn("Yazı Oluşturulurken Hata Oluştu", {
            position: "top-right",
          });
        }
      });



  });
    

  }
  



  resyukle = (files , info , core ,uploadHandler) => {
   
    console.log(files , info , core ,uploadHandler);

    return false ;
    
    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/", {
       method: 'POST',
       body: data
         }).then((response) => {
          return response.json();
        })
        .then((result) => {

          console.log(result);

        });


  }

  
  
  addImage = () => {

    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/", {
       method: 'POST',
       body: data
         }).then((response) => {
          return response.json();
        })
        .then((result) => {



          if (!result.success) {
            cogoToast.warn(
              "Resim Yüklenirken Sorun Oluştu.",
              { position: "top-right" }
            );
          }
          else 
          {
            cogoToast.success(
              "Resim Başarılı Bir Şekilde Yüklendi.",
              { position: "top-right" }
            );
            this.setState({
              blog_image: result.url,
              
            });
            
          }
          this.setState({
            loadImage: false,
          });
            console.log(result);
        });
    }


    

    icerikver = (e) => {

      this.setState({
        body: e,
      });

    };


  linkVer = (e) => {

    this.setState({
      title: e.target.value,
    });


    var str = e.target.value;
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    this.setState((state) => {
      return {
        link: str,
      };
    });
  };

  linkDegis = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    this.setState((state) => {
      return {
        link: str,
      };
    });
  };

  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", this.state.BASE_URL + "/api/upPro/");
      const data = new FormData();
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText).url;

        resolve({ data: { link: response } });
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  };

  handleChange = (e) => {
    this.setState({ tags: e });
  };

  render() {
    const previewStyle = {
      display: "inline",
      width: 100,
      height: 100,
    };

    return (
      <div>
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
          <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Yeni Blog Yazısı Ekle"
                html={
                  <Link
                    to="/content/blog/all"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> Blog Yazılarına Geri
                    Dön
                  </Link>
                }
              />
               <BlockUi
                            loader={
                              <div className="loader-img-page-div">
                                <img alt="Resim" src="/assets/img/loader.svg" />
                                <div className="LoaderText-div">
                                  Yazı Eklenirken Lütfen Bekleyiniz...
                                </div>
                              </div>
                            }
                            tag="div"
                            className="row row-sm"
                            blocking={this.state.addBlockLoad} >
             
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-8">
                              <div className="form-group">
                                <label className="row">
                                  <div className="col-lg-6" >Blog Başlığı</div>
                                  <div className="col-lg-6" >
                                <Switch


onColor="#e0f7ea"
onHandleColor="#00d057"
handleDiameter={30}
boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
height={20}
width={48}
checked={this.state.isDraft}
onChange={this.handleChangeCheck}
uncheckedIcon={
<div
  style={{
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    fontSize: 15,
    paddingRight: 2,
    width: "180px"
  }}
>
  Taslak Olarak Kaydet
</div>
}
checkedIcon={
<div
style={{
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  height: "100%",
  fontSize: 15,
  paddingRight: 2,
  width: "200px",
  color : '#00d057'
}}
>
Hemen Yayınla
</div>
}
className="react-switch"
id="icon-switch"
/>
</div>     
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Blog Başlığı"
                                  onChange={this.linkVer}
                                />

                            {
                              this.state.link ? (
                                <div className="row">
                                <div className="col-lg-12 mt-3">
                                  <span className="abonesepeticom">
                                    <i class="fe fe-chrome"></i>{" "}
                                    https://www.abonesepeti.com/blog/kategori/{" "}
                                  </span>
                                  <EasyEdit
                                    type={Types.TEXT}
                                    value={this.state.link}
                                    placeholder="Düzenlemek İçin Tıklayın"
                                    saveButtonLabel="Kaydet"
                                    cancelButtonLabel="Vazgeç"
                                    onSave={(val) => this.linkDegis(val)}
                                  />
                                </div>
                              </div>
                              ) : (<></>)
                            }
                               
                              </div>

                              <div className="form-group">
                                <label className>Yazı Kategorisi</label>
                                <div className="pos-relative">
                                  <Select
                                    isMulti
                                    name="categorys"
                                    options={this.state.categorys}
                                    getOptionLabel={(option) =>
                                      `${option.name}`
                                    }
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={this.handleMultiChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Lütfen Kategori Seçiniz"
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label className>Anahtar Kelimeler</label>
                                <div className="pos-relative bootstrap-tagsinput">
                                  <InputTags
                                    placeholder="Etiketi Yazıp Enter Tuşuna Başınız"
                                    values={this.state.tags}
                                    onTags={(value) =>
                                      this.handleChange(value.values)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className>Kısa Açıklama</label>
                                <div>
                                  <textarea
                                    id="short_desc"
                                    name="short_desc"
                                    class="form-control"
                                    onChange={(event) => this.handleOnChange(event)}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                           
                            <BlockUi
                            loader={
                              <div className="loader-img-page-div">
                                <img alt="Resim" src="/assets/img/loader.svg" />
                                <div className="LoaderText-div">
                                  Resim Yüklenirken Lütfen Bekleyiniz...
                                </div>
                              </div>
                            }
                            tag="div"
                            className="col-lg-4"
                            blocking={this.state.loadImage} >
                              <div className="form-group">
                                <label className>Öne Çıkan Resim </label>
                                <div className="pos-relative">
                                  <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="container">
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p>
                                            Öne Çıkan Resmi Yüklemek İçin Tıklayın <br /> veya <br /> Resmi Buraya Sürükleyin
                                          </p>
                                        </div>
                                        {this.state.blog_image ? (
                                        <div class="media d-block mt-3 text-center">
                                          <img
                                            src={this.state.blog_image}
                                            className="wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0"
                                          />
                                          <button className="btn btn-warning wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 " onClick={this.kaldir}>Resmi Kaldır</button>
                                        </div>) : (<></>
                                        )}
                                      </section>
                                    )}
                                  </Dropzone>
                                </div>
                              </div>
                            </BlockUi>

                            <div className="col-lg-12">
                              <div className="form-group">
                                <label className>Blog İçeriği</label>
                                <div className="pos-relative">
                                  <SunEditor
                                    height="500"
                                    placeholder="Blog içeriğini buraya yazınız..."
                                    setOptions={{
                                      buttonList: [
                                        [
                                          "undo",
                                          "redo",
                                          "font",
                                          "fontSize",
                                          "formatBlock",
                                          "paragraphStyle",
                                          "blockquote",
                                          "bold",
                                          "underline",
                                          "italic",
                                          "strike",
                                          "subscript",
                                          "superscript",
                                          "fontColor",
                                          "hiliteColor",
                                          "textStyle",
                                          "removeFormat",
                                          "outdent",
                                          "indent",
                                          "align",
                                          "horizontalRule",
                                          "list",
                                          "lineHeight",
                                          "table",
                                          "link",
                                          "image",
                                          "video",
                                          "audio",
                                          "fullScreen",
                                          "showBlocks",
                                          "codeView",
                                          "preview",
                                        ],
                                      ],
                                      lang: lang.tr,
                               
                                    }}
                                      setContents={this.state.body}
                                      onChange={this.icerikver}
                                  />
                                </div>
                              </div>
                             
                              <button className="btn ripple btn-main-primary pull-right"  onClick={this.ekleblog}>
                                Yazıyı Ekle
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
           
              </BlockUi>
           
             
            </div>
          </div>
        </div>
      
      </div>
      
    );
  }
}

export default withRouter(addBlog);
