export function config() {

  if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")
  {
    const data = {BASE_URL : 'https://webservice.abonesepeti.net' }
    return data ;
   
  }
  else 
  {
    const data = {BASE_URL : 'https://webservice.abonesepeti.net' }
    return data ;
  }
  

   
    

  }

