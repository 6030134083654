import React from 'react';

class GlobalLoader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
<div id="global-loader-page">
<div className="loader-img-page">
  <img src="/assets/img/loader.svg" />
  <div className="LoaderText">Lütfen Bekleyiniz...</div>
</div>
</div>
    );
  }
}


export default GlobalLoader;
