import React from 'react';
import { Link } from 'react-router-dom';
import Ustkisim from './inc/Header';
import Footer from './inc/Footer';
import {seo} from '../helpers';
import {config} from '../config'
class Leads extends React.Component {
	constructor(props) {
		super(props);
        this.state = {websites: []};
        this.state.BASE_URL = config().BASE_URL ;
		this.headers = [
			{ key: 'id', label: 'Id'},
			{ key: 'title', label: 'Title' },
			{ key: 'url', label: 'URL' }
		];
		this.deleteWebsite = this.deleteWebsite.bind(this);
	}
	
	componentDidMount() {
		fetch(this.state.BASE_URL+'index.php/websiterestcontroller/websites')
			.then(response => {
				return response.json();
			}).then(result => {
				console.log(result);
				this.setState({
					websites:result
				});
            });
            
            seo({
                title: 'Potansiyel Müşteriler | Maya',
                metaDescription: 'Maya Ana Ekranı'
              });

              

            
	}
	
	deleteWebsite(id) {
		if(window.confirm("Are you sure want to delete?")) {
			fetch('http://localhost/maya3/index.php/websiterestcontroller/delete_website/' + id, {
                                method : 'DELETE'
                                   }).then(response => { 
					if(response.status === 200) {
						alert("Website deleted successfully");
                                                fetch('http://localhost/maya3/index.php/websiterestcontroller/websites')
						.then(response => {
							return response.json();
						}).then(result => {
							console.log(result);
							this.setState({
								websites:result
							});
						});
					} 
			 });
		}
	}
	
	render() {
		return (

			<div>
		
				<Ustkisim />
            
	<div className="main-content side-content pt-0">
    <div className="container-fluid">
    <div id="global-loader-page">
        <div className="loader-img-page">
        <img src="../assets/img/loader.svg" />
        <div className="LoaderText">Lütfen Bekleyiniz...</div>
        </div>
			
		</div>
      <div className="inner-body">
  
        {/* Page Header */}
        <div className="page-header">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">Potansiyel Müşteriler</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="#">Ecommerce</a></li>
              <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
            </ol>
          </div>
          <div className="d-flex">
            <div className="justify-content-center">
              <button type="button" className="btn btn-white btn-icon-text my-2 mr-2">
                <i className="fe fe-download mr-2" /> Import
              </button>
              <button type="button" className="btn btn-white btn-icon-text my-2 mr-2">
                <i className="fe fe-filter mr-2" /> Filter
              </button>
              <button type="button" className="btn btn-primary my-2 btn-icon-text">
                <i className="fe fe-download-cloud mr-2" /> Download Report
              </button>
            </div>
          </div>
        </div>
        {/* End Page Header */}
        {/* Row */}
        <div className="row row-sm">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card custom-card">
              <div className="card-body">
                <div className="card-order ">
                  <label className="main-content-label mb-3 pt-1">New Users</label>
                  <h2 className="text-right card-item-icon card-icon">
                    <i className="mdi mdi-account-multiple icon-size float-left text-primary" /><span className="font-weight-bold">3,672</span></h2>
                  <p className="mb-0 mt-4 text-muted">Monthly users<span className="float-right">50%</span></p>
                </div>
              </div>
            </div>
          </div>
          {/* COL END */}
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card custom-card">
              <div className="card-body">
                <div className="card-order">
                  <label className="main-content-label mb-3 pt-1">Total tax</label>
                  <h2 className="text-right"><i className="mdi mdi-cube icon-size float-left text-primary" /><span className="font-weight-bold">$89,265</span></h2>
                  <p className="mb-0 mt-4 text-muted">Monthly Income<span className="float-right">$7,893</span></p>
                </div>
              </div>
            </div>
          </div>
          {/* COL END */}
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card custom-card">
              <div className="card-body">
                <div className="card-order">
                  <label className="main-content-label mb-3 pt-1">Total Profit</label>
                  <h2 className="text-right"><i className="icon-size mdi mdi-poll-box   float-left text-primary" /><span className="font-weight-bold">$23,987</span></h2>
                  <p className="mb-0 mt-4 text-muted">Monthly Profit<span className="float-right">$4,678</span></p>
                </div>
              </div>
            </div>
          </div>
          {/* COL END */}
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
            <div className="card custom-card">
              <div className="card-body">
                <div className="card-order">
                  <label className="main-content-label mb-3 pt-1">Total Sales</label>
                  <h2 className="text-right"><i className="mdi mdi-cart icon-size float-left text-primary" /><span className="font-weight-bold">46,486</span></h2>
                  <p className="mb-0 mt-4 text-muted">Monthly Sales<span className="float-right">3,756</span></p>
                </div>
              </div>
            </div>
          </div>
          {/* COL END */}
        </div>
        {/* End Row */}
        {/* row opened */}
        <div className="row row-sm">
          <div className="col-xxl-6 col-xl-12 col-lg-12 col-md-12">
            <div className="card custom-card">
              <div className="card-header border-bottom-0">
                <label className="main-content-label my-auto pt-2">Revenue Overview</label>
                <span className="d-block tx-12 mb-0 mt-1 text-muted">An Overview. Revenue is the total amount of income generated by the sale of goods or services related to the company's primary operations.</span>
              </div>
              <div className="card-body">
                <div className="chart-wrapper">
                  <canvas id="revenuechart"  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-6 col-md-12 col-lg-12">
            <div className="card custom-card">
              <div className="card-header border-bottom-0 pb-0">
                <label className="main-content-label mb-2 pt-1">Recent Orders</label>
                <p className="tx-12 mb-0 text-muted">An order is an investor's instructions to a broker or brokerage firm to purchase or sell</p>
              </div>
              <div className="card-body sales-product-info ot-0 pt-0 pb-0">
                <div id="recentorders" className="ht-150" />
                <div className="row sales-product-infomation pb-0 mb-0 mx-auto wd-100p">
                  <div className="col-md-6 col justify-content-center text-center">
                    <p className="mb-0 d-flex justify-content-center "><span className="legend bg-primary brround" />Delivered</p>
                    <h3 className="mb-1 font-weight-bold">5238</h3>
                    <div className="d-flex justify-content-center ">
                      <p className="text-muted ">Last 6 months</p>
                    </div>
                  </div>
                  <div className="col-md-6 col text-center float-right">
                    <p className="mb-0 d-flex justify-content-center "><span className="legend bg-light brround" />Cancelled</p>
                    <h3 className="mb-1 font-weight-bold">3467</h3>
                    <div className="d-flex justify-content-center ">
                      <p className="text-muted">Last 6 months</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-6 col-md-12 col-sm-12">
            <div className="card custom-card">
              <div className="card-header pb-0 border-bottom-0">
                <label className="main-content-label mb-2 pt-1">Tickets</label>
                <p className="tx-12 mb-0 text-muted">Sales activities are the tactics that salespeople use to achieve</p>
              </div>
              <div className="card-body">
                <ul className="visitor mb-0 d-block users-images list-unstyled list-unstyled-border">
                  <li className="media d-flex mb-3 mt-0 pt-0">
                    <img className="mr-3 rounded-circle avatar avatar-md" src="../assets/img/users/3.jpg"  />
                    <div className="media-body mb-1">
                      <div className="float-right"><small>10-9-2018</small></div>
                      <h5 className="media-title tx-15 mb-0">Vanessa</h5>
                      <span className="text-muted">sed do eiusmod </span>
                    </div>
                  </li>
                  <li className="media d-flex mb-3">
                    <img className="mr-3 rounded-circle avatar avatar-md" src="../assets/img/users/5.jpg"  />
                    <div className="media-body mb-1">
                      <div className="float-right"><small>15-9-2018</small></div>
                      <h5 className="media-title tx-15 mb-0"> Rutherford</h5>
                      <small className="text-muted">sed do eiusmod </small>
                    </div>
                  </li>
                  <li className="media d-flex mb-3">
                    <img className="mr-3 rounded-circle avatar avatar-md" src="../assets/img/users/7.jpg"  />
                    <div className="media-body mb-1">
                      <div className="float-right"><small>17-9-2018</small></div>
                      <h5 className="media-title tx-15 mb-0">Elizabeth </h5>
                      <small className="text-muted">sed do eiusmod </small>
                    </div>
                  </li>
                  <li className="media d-flex mb-3">
                    <img className="mr-3 rounded-circle avatar avatar-md" src="../assets/img/users/4.jpg" title="avatar" />
                    <div className="media-body mb-1">
                      <div className="float-right"><small>19-9-2018</small></div>
                      <h5 className="media-title tx-15 mb-0">Anthony</h5>
                      <small className="text-muted">sed do eiusmod </small>
                    </div>
                  </li>
                  <li className="media d-flex mb-0">
                    <img className="mr-3 rounded-circle avatar avatar-md" src="../assets/img/users/9.jpg" title="avatar" />
                    <div className="media-body mb-1">
                      <div className="float-right"><small>19-9-2018</small></div>
                      <h5 className="media-title tx-15 mb-0">Anthony</h5>
                      <small className="text-muted">sed do eiusmod </small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* row closed */}
        {/* row opened */}
        <div className="row row-sm">
          <div className="col-xxl-3 col-xl-6 col-md-12 col-lg-6">
            <div className="card custom-card">
              <div className="card-header border-bottom-0 pb-1">
                <label className="main-content-label mb-2 pt-1">Sales Activity</label>
                <p className="tx-12 mb-0 text-muted">Sales activities are the tactics that salespeople use to achieve their goals and objective</p>
              </div>
              <div className="product-timeline card-body pt-3 mt-1">
                <ul className="timeline-1 mb-0">
                  <li className="mt-0"> <i className="ti-pie-chart product-icon" /> <span className="font-weight-semibold mb-4 tx-14 ">Total Products</span> <a href="#" className="float-right tx-11 text-muted">3 days ago</a>
                    <p className="mb-0 text-muted tx-12">1.3k New Products</p>
                  </li>
                  <li className="mt-0"> <i className="mdi mdi-cart-outline product-icon" /> <span className="font-weight-semibold mb-4 tx-14 ">Total Sales</span> <a href="#" className="float-right tx-11 text-muted">35 mins ago</a>
                    <p className="mb-0 text-muted tx-12">1k New Sales</p>
                  </li>
                  <li className="mt-0"> <i className="ti-bar-chart-alt product-icon" /> <span className="font-weight-semibold mb-4 tx-14 ">Toatal Revenue</span> <a href="#" className="float-right tx-11 text-muted">50 mins ago</a>
                    <p className="mb-0 text-muted tx-12">23.5K New Revenue</p>
                  </li>
                  <li className="mt-0"> <i className="ti-wallet product-icon" /> <span className="font-weight-semibold mb-4 tx-14 ">Toatal Profit</span> <a href="#" className="float-right tx-11 text-muted">1 hour ago</a>
                    <p className="mb-0 text-muted tx-12">3k New profit</p>
                  </li>
                  <li className="mt-0 mb-0"><i className="si si-eye product-icon" /> <span className="font-weight-semibold mb-4 tx-14 ">Customer Visits</span> <a href="#" className="float-right tx-11 text-muted">1 day ago</a>
                    <p className="mb-0 text-muted tx-12">15% increased</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-6 col-md-12 col-lg-6">
            <div className="card custom-card">
              <div className="card-header border-bottom-0 pb-1">
                <label className="main-content-label mb-2 pt-1">Top products</label>
                <p className="tx-12 mb-0 text-muted">Top Trending Products to Sell Online At Your Ecommerce &amp; Dropshipping Store.</p>
              </div>
              <div className="card-body pt-0">
                <ul className="top-selling-products pb-0 mb-0 pl-0">
                  <li className="product-item">
                    <div className="product-img"><img src="../assets/img/pngs/14.png"  /></div>
                    <div className="product-info">
                      <div className="product-name">College Bag</div>
                      <div className="price">Fashion</div>
                    </div>
                    <div className="product-amount">
                      <div className="product-price">$990.00</div>
                      <div className="items-sold">10 Sold</div>
                    </div>
                  </li>
                  <li className="product-item">
                    <div className="product-img"><img src="../assets/img/pngs/18.png"  /></div>
                    <div className="product-info">
                      <div className="product-name">Smartwatch</div>
                      <div className="price">Electronics</div>
                    </div>
                    <div className="product-amount">
                      <div className="product-price">$990.00</div>
                      <div className="items-sold">10 Sold</div>
                    </div>
                  </li>
                  <li className="product-item">
                    <div className="product-img"><img src="../assets/img/pngs/17.png"  /></div>
                    <div className="product-info">
                      <div className="product-name">Chair</div>
                      <div className="price">Furniture</div>
                    </div>
                    <div className="product-amount">
                      <div className="product-price">$990.00</div>
                      <div className="items-sold">10 Sold</div>
                    </div>
                  </li>
                  <li className="product-item">
                    <div className="product-img"><img src="../assets/img/pngs/16.png"  /></div>
                    <div className="product-info">
                      <div className="product-name">Flowers Pot</div>
                      <div className="price">Gardening</div>
                    </div>
                    <div className="product-amount">
                      <div className="product-price">$990.00</div>
                      <div className="items-sold">10 Sold</div>
                    </div>
                  </li>
                  <li className="product-item pb-0">
                    <div className="product-img"><img src="../assets/img/pngs/19.png"  /></div>
                    <div className="product-info">
                      <div className="product-name">iPhone Mobile</div>
                      <div className="price">Electronics</div>
                    </div>
                    <div className="product-amount">
                      <div className="product-price">$990.00</div>
                      <div className="items-sold">10 Sold</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12 col-md-12 col-lg-12">
            <div className="card custom-card">
              <div className="card-header border-bottom-0">
                <label className="main-content-label mb-2 pt-1">Country Wise Sales</label>
                <p className="tx-12 mb-0 text-muted">​The global ecommerce sales in 2020 is expected to reach $4.453 trillion this marks an increase of <b>22.5 %</b> percent from the previous year as the global ecommerce market.</p>
              </div>
              <div className="card-body pt-0 pb-3 row">
                <div className="col-xl-8">
                  <div id="world-map-markers" className="ht-300" />
                </div>
                <div className="col-xl-4 col-md-12 mt-xl-4">
                  <div className="mb-4 pt-2">
                    <h5 className="mb-2 d-block">
                      <span className="fs-14">Brazil</span>
                      <span className="float-right fs-14">80%</span>
                    </h5>
                    <div className="progress ht-4 progress-md h-2">
                      <div className="progress-bar progress-bar-animated progress-bar-striped bg-primary ht-4 wd-80p" />
                    </div>
                  </div>
                  <div className="mb-4">
                    <h5 className="mb-2 d-block">
                      <span className="fs-14">Russia</span>
                      <span className="float-right fs-14">72%</span>
                    </h5>
                    <div className="progress ht-4 progress-md">
                      <div className="progress-bar progress-bar-animated progress-bar-striped bg-primary ht-4 wd-70p" />
                    </div>
                  </div>
                  <div className="mb-4">
                    <h5 className="mb-2 d-block">
                      <span className="fs-14">Poland</span>
                      <span className="float-right fs-14">67%</span>
                    </h5>
                    <div className="progress progress-md  ht-4">
                      <div className="progress-bar progress-bar-animated progress-bar-striped bg-primary ht-4 wd-60p" />
                    </div>
                  </div>
                  <div className="mb-4">
                    <h5 className="mb-2 d-block">
                      <span className="fs-14">Canada</span>
                      <span className="float-right fs-14">53%</span>
                    </h5>
                    <div className="progress progress-md  ht-4">
                      <div className="progress-bar progress-bar-animated progress-bar-striped bg-primary ht-4 wd-50p" />
                    </div>
                  </div>
                  <div className="mb-4">
                    <h5 className="mb-2 d-block">
                      <span className="fs-14">India</span>
                      <span className="float-right fs-14">75%</span>
                    </h5>
                    <div className="progress progress-md  ht-4">
                      <div className="progress-bar progress-bar-animated  progress-bar-striped bg-primary ht-4 wd-40p" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-xl-8">
            <div className="card custom-card overflow-hidden">
              <div className="card-header border-bottom-0 d-flex">
                <div>
                  <label className="main-content-label mb-2 pt-1">Products Details</label>
                  <p className="tx-12 mb-3 text-muted">The details displayed often include size, color, price, shipping information, reviews, and other relevant information customers may want to know before making a purchase</p>
                </div>
                <div className="card-options float-right">
                  <a  className="mr-0 text-default" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="true"> <span className="fe fe-more-vertical tx-17 float-right" /> </a>
                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    <li><a href="#"><i className="fe fe-eye mr-2" />View</a></li>
                    <li><a href="#"><i className="fe fe-plus-circle mr-2" />Add</a></li>
                    <li><a href="#"><i className="fe fe-trash-2 mr-2" />Remove</a></li>
                    <li><a href="#"><i className="fe fe-download-cloud mr-2" />Download</a></li>
                    <li><a href="#"><i className="fe fe-settings mr-2" />More</a></li>
                  </ul>
                </div>
              </div>
              <div className="card-body pt-0">
                <div className="table-responsive">
                  <table className="table table-vcenter border mb-0 text-nowrap">
                    <thead>
                      <tr>
                        <th>Product ID</th>
                        <th>Product</th>
                        <th>Product Cost</th>
                        <th>Total</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#C234</td>
                        <td className="d-flex my-auto"><div className="product-img"><img src="../assets/img/pngs/14.png"  className="ht-40 wd-40 mr-3" /></div><span className="my-auto">Regular waterproof (24 L) Backpack</span></td>
                        <td><b>$14,500</b></td>
                        <td>2,977</td>
                        <td><span className="badge badge-primary">Available</span></td>
                      </tr>
                      <tr>
                        <td>#C389</td>
                        <td className="d-flex my-auto"><div className="product-img"><img src="../assets/img/pngs/15.png"  className="ht-40 wd-40 mr-3" /></div><span className="my-auto">Women Pink Heels Sandal</span></td>
                        <td><b>$30,000</b></td>
                        <td>678</td>
                        <td><span className="badge badge-primary">Limited</span></td>
                      </tr>
                      <tr>
                        <td>#C936</td>
                        <td className="d-flex my-auto"><div className="product-img"><img src="../assets/img/pngs/16.png"  className="ht-40 wd-40 mr-3" /></div><span className="my-auto">Designer Hand Decorative flower Pot</span></td>
                        <td><b>$13,200</b></td>
                        <td>4,922</td>
                        <td><span className="badge badge-primary">Avilable</span></td>
                      </tr>
                      <tr>
                        <td>#C493</td>
                        <td className="d-flex my-auto"><div className="product-img"><img src="../assets/img/pngs/17.png"  className="ht-40 wd-40 mr-3" /></div><span className="my-auto">Plastic Outdoor Chair</span></td>
                        <td><b>$15,100</b></td>
                        <td>1,234</td>
                        <td><span className="badge badge-primary">Limited</span></td>
                      </tr>
                      <tr>
                        <td>#C729</td>
                        <td className="d-flex my-auto"><div className="product-img"><img src="../assets/img/pngs/18.png"  className="ht-40 wd-40 mr-3" /></div><span className="my-auto">Blck Digital smart watch</span></td>
                        <td><b>$5,987</b></td>
                        <td>4,789</td>
                        <td><span className="badge badge-primary op-5">No Stock</span></td>
                      </tr>
                      <tr>
                        <td>#C529</td>
                        <td className="d-flex my-auto"><div className="product-img"><img src="../assets/img/pngs/19.png"  className="ht-40 wd-40 mr-3" /></div><span className="my-auto">Apple iPhone(Black, 128 GB)</span></td>
                        <td><b>$11,987</b></td>
                        <td>938</td>
                        <td><span className="badge badge-primary">Limited</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-12 col-sm-12">
            <div className="card custom-card">
              <div className="card-header border-bottom-0">
                <div>
                  <label className="main-content-label mb-2 pt-1">Order Activity</label>
                  <p className="tx-12 mb-3 text-muted">Ordering Activity. means an activity that is authorized to place orders, or establish blanket purchase agreements.</p>
                </div>
              </div>
              <div className="card-body pt-1">
                <div className="border">
                  <div className="list-group projects-list pt-0 pb-0 pl-0 pr-0">
                    <a href="#" className="list-group-item list-group-item-action flex-column align-items-start border-0">
                      <div className="d-flex w-100 justify-content-between">
                        <h6 className="mb-1 font-weight-semibold">Order Picking</h6>
                        <h6 className="mb-0 font-weight-bold tx-15">3,876</h6> </div>
                      <div className="d-flex w-100 justify-content-between"> <span className="text-muted"><i className="fe fe-arrow-down text-success " /> 03% last month</span> <span className="text-muted tx-11">5 days ago</span> </div>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action flex-column align-items-start border-bottom-0  border-left-0 border-right-0 border-top">
                      <div className="d-flex w-100 justify-content-between">
                        <h6 className="mb-1 font-weight-semibold">Storage</h6>
                        <h6 className="mb-0 font-weight-bold tx-15">2,178</h6> </div>
                      <div className="d-flex w-100 justify-content-between"> <span className="text-muted"><i className="fe fe-arrow-down text-danger " /> 16% last month</span> <span className="text-muted tx-11">2 days ago</span> </div>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action flex-column align-items-start border-bottom-0  border-left-0 border-right-0 border-top">
                      <div className="d-flex w-100 justify-content-between">
                        <h6 className="mb-1 font-weight-semibold ">Shipping</h6>
                        <h6 className="mb-0 font-weight-bold tx-15">1,367</h6> </div>
                      <div className="d-flex w-100 justify-content-between"> <span className="text-muted"><i className="fe fe-arrow-up text-success" /> 06% last month</span> <span className="text-muted tx-11">1 days ago</span> </div>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action flex-column align-items-start border-bottom-0  border-left-0 border-right-0 border-top">
                      <div className="d-flex w-100 justify-content-between">
                        <h6 className="mb-1 font-weight-semibold ">Receiving</h6>
                        <h6 className="mb-0 font-weight-bold tx-15">678</h6> </div>
                      <div className="d-flex w-100 justify-content-between"> <span className="text-muted"><i className="fe fe-arrow-down text-danger " /> 25% last month</span> <span className="text-muted tx-11">10 days ago</span> </div>
                    </a>
                    <a href="#" className="list-group-item list-group-item-action flex-column align-items-start border-left-0 border-right-0 border-top">
                      <div className="d-flex w-100 justify-content-between">
                        <h6 className="mb-1 font-weight-semibold">Other</h6>
                        <h6 className="mb-0 font-weight-bold tx-15">5,678</h6> </div>
                      <div className="d-flex w-100 justify-content-between"> <span className="text-muted"><i className="fe fe-arrow-up text-success " /> 16% last month</span> <span className="text-muted tx-11">5 days ago</span> </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
				
				<Link to="/create">Add Website</Link>
				<p/>
				<table>
					<thead>
						<tr>
						{
							this.headers.map(function(h) {
								return (
									<th key = {h.key}>{h.label}</th>
								)
							})
						}
						  <th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{
							this.state.websites.map(function(item, key) {
							return (
								<tr key = {key}>
								  <td>{item.id}</td>
								  <td>{item.title}</td>
								  <td>{item.url}</td>
								  <td>
										<Link to={`/update/${item.id}`}>Edit</Link>
										 
										<a href="true" onClick={this.deleteWebsite.bind(this, item.id)}>Delete</a>
								  </td>
								</tr>
											)
							}.bind(this))
						}
					</tbody>
				</table>
			</div>
		)
	}
}

export default Leads;