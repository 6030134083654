import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, HashRouter as Router, Switch } from "react-router-dom";

import { setAuthorizationToken } from "./auth/helpers/setAuthorizationToken";
import store from './auth/helpers/store';
import Giris from './auth/Login';
import PrivateRoute from './auth/PrivateRoute';
import addBlog from "./components/blog/addBlog";
import Blog from "./components/blog/Blog";
import BlogCategorys from "./components/blog/Categorys";
import UpdateBlog from "./components/blog/UpdateBlog";
import Create from "./components/create";
import addFaq from "./components/faqs/addFaq";
import FaqsCategorys from "./components/faqs/Categorys";
import Faqs from "./components/faqs/Faqs";
import UpdateFaq from "./components/faqs/UpdateFaq";
import comments from "./components/happy_customers/Customers";
import commentsAdd from "./components/happy_customers/NewCustomer";
import commentsUpdate from "./components/happy_customers/UpdateCustomer";
import IncomingFax from "./components/IncomingFax";
import NotFound from "./components/inc/NotFound";
import addjob from "./components/jobs/addjob";
import Jobs from "./components/jobs/Jobs";
import UpdateJob from "./components/jobs/UpdateJob";
import Kitalepler from "./components/Kitalepler";
import KiUsers from "./components/KiUsers";
import Leads from "./components/Leads";
import ListEmailAccounts from "./components/ListEmailAccounts";
import MailBox from "./components/MailBox";
import addModule from "./components/modules/addModule";
import OutgoingFax from "./components/OutgoingFax";
import AddPage from "./components/pages/addPage";
import Pages from "./components/pages/Pages";
import UpdatePage from "./components/pages/UpdatePage";
import addPress from "./components/press/addPress";
import PressCategorys from "./components/press/Categorys";
import Press from "./components/press/Press";
import UpdatePress from "./components/press/UpdatePress";
import addProduct from "./components/products/addProduct";
import allProducts from "./components/products/allProducts";
import ProductsCategorys from "./components/products/ProductsCategorys";
import UpdateProduct from "./components/products/updateProduct";
import CopyProduct from "./components/products/copyProduct";
import ActiveSellers from "./components/sellers/Active";
import PassiveSellers from "./components/sellers/Passive";
import AllSettings from "./components/settigns/AllSettings";
import KiBrands from "./components/settigns/KiBrands";
import KiInputs from "./components/settigns/KiInputs";
import KiNeden from "./components/settigns/KiNeden";
import KiNewBrand from "./components/settigns/KiNewBrand";
import Status from "./components/settigns/Status";
import addSlide from "./components/sliders/addSlide";
import Sliders from "./components/sliders/Sliders";
import UpdateSlide from "./components/sliders/UpdateSlide";
import NewSpecialCampaigns from "./components/special_campaigns/newcampaign";
import SpecialCampaigns from "./components/special_campaigns/Scampaigns";
import UpdatepecialCampaigns from "./components/special_campaigns/updatecampaign";
import Talep from "./components/Talep";
import Update from "./components/update";
import Websites from "./components/websites";
import Zoom from "./components/Zoom";
import ZoomMeetingList from "./components/ZoomMeetingList";
//import registerServiceWorker from './registerServiceWorker';
import { registerServiceWorker } from "./register-sw";

const renderNotification = (notification, i) => <li key={i}>{notification}</li>;

const registerPushListener = (pushNotification) =>
  navigator.serviceWorker.addEventListener("message", ({ data }) => {
    pushNotification(
      data.data
        ? data.data.message
        : data["firebase-messaging-msg-data"].data.message
    );
  });

  const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const now = new Date()
    const item = JSON.parse(itemStr)
    
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

registerServiceWorker();



const jwtToken = localStorage.getItem("AtlasToken");

if (jwtToken) {
  const tok = getWithExpiry("AtlasToken");

  if(tok)
  {
    setAuthorizationToken(tok);
  }
  
}
 

ReactDOM.render(
  
  <Provider store={store}>
  <Router>
    <Switch>
      <Route path="/login"><Giris /></Route>
      <PrivateRoute  exact path="/" component={Websites} />
      <PrivateRoute  path="/leads/:id" component={Leads} />
      <PrivateRoute  path="/talep/:id" component={Kitalepler} />
      <PrivateRoute  exact path="/fax/IncomingFax" component={IncomingFax} />
      <PrivateRoute  exact path="/fax/OutgoingFax" component={OutgoingFax} />
      <PrivateRoute 
        exact
        path="/meeting/ZoomMeetingList"
        component={ZoomMeetingList}
      />
      <PrivateRoute 
        exact
        path="/email/ListEmailAccounts"
        component={ListEmailAccounts}
      />
      <PrivateRoute  exact path="/email/MailBox" component={MailBox} />
      <PrivateRoute  path="/meeting/view/:id" component={Zoom} />
      <PrivateRoute  exact path="/zoom/users" component={KiUsers} />
      <PrivateRoute  path="/create" component={Create} />
      <PrivateRoute  exact path="/accounts/kolayiptal" component={KiUsers} />
      <PrivateRoute  exact path="/sellers/active" component={ActiveSellers} />
      <PrivateRoute  exact path="/sellers/passive" component={PassiveSellers} />
      <Route 
        path="/kolayiptal/talep/:talep"
        render={({ match }) => (
          <Kitalepler key={match.params.talep} id={match.params.talep} />
        )}
      />

      <Route 
        path="/kolayiptal/goster/:talepid"
        render={({ match }) => (
          <Talep key={match.params.talepid} talepid={match.params.talepid} />
        )}
      />
      <PrivateRoute  exact path="/settings/Status" component={Status} />
      <PrivateRoute  exact path="/settings/Kiinputs" component={KiInputs} />
      <PrivateRoute  exact path="/settings/KiIptalNeden" component={KiNeden} />
      <PrivateRoute  exact path="/settings/KiBrands" component={KiBrands} />
      <PrivateRoute  exact path="/settings/KiNewBrand" component={KiNewBrand} />
      <PrivateRoute  exact path="/settings/allSettings" component={AllSettings} />
      
      <PrivateRoute 
        exact
        path="/content/products/Categorys"
        component={ProductsCategorys}
      />
      <PrivateRoute  exact path="/content/products/all" component={allProducts} />
      <PrivateRoute  exact path="/content/products/addProduct" component={addProduct} />
      <Route 
        path="/content/products/product/:product_id"
        render={({ match }) => (
          <UpdateProduct
          key={match.params.product_id} product_id={match.params.product_id} />
        )}
      />
        <Route 
        path="/content/products/clone/:product_id"
        render={({ match }) => (
          <CopyProduct
          key={match.params.product_id} product_id={match.params.product_id} />
        )}
      />

      

      <PrivateRoute  exact path="/content/blog/Categorys" component={BlogCategorys} />
      <PrivateRoute  exact path="/content/blog/add" component={addBlog} />
      <PrivateRoute  exact path="/content/blog/all" component={Blog} />



      <PrivateRoute  exact path="/content/press/Categorys" component={PressCategorys} />
      <PrivateRoute  exact path="/content/press/add" component={addPress} />
      <PrivateRoute  exact path="/content/press/all" component={Press} />


      <PrivateRoute  exact path="/content/faqs/Categorys" component={FaqsCategorys} />
      <PrivateRoute  exact path="/content/faqs/addFaq" component={addFaq} />
      <PrivateRoute  exact path="/content/faqs/all" component={Faqs} />
      <Route 
        path="/content/faqs/faq/:faqid"
        render={({ match }) => (
          <UpdateFaq key={match.params.faqid} faqid={match.params.faqid} />
        )}
      />

      <PrivateRoute  exact path="/content/jobs/addjob" component={addjob} />
      <PrivateRoute  exact path="/content/jobs/all" component={Jobs} />
      <Route 
        path="/content/jobs/job/:jobid"
        render={({ match }) => (
          <UpdateJob key={match.params.jobid} jobid={match.params.jobid} />
        )}
      />
      
      
      <Route 
        path="/content/blog/posts/:postid"
        render={({ match }) => (
          <UpdateBlog key={match.params.postid} postid={match.params.postid} />
        )}
      />

    <Route 
        path="/content/press/posts/:postid"
        render={({ match }) => (
          <UpdatePress key={match.params.postid} postid={match.params.postid} />
        )}
      />




      <PrivateRoute  exact path="/content/pages/all" component={Pages} />
      <PrivateRoute  exact path="/content/pages/AddPage" component={AddPage} />
      <Route 
        path="/content/pages/page/:pageid"
        render={({ match }) => (
          <UpdatePage key={match.params.pageid} pageid={match.params.pageid} />
        )}
      />

      <PrivateRoute  exact path="/content/modules/addModule" component={addModule} />

      <PrivateRoute  exact path="/content/sliders/all" component={Sliders} />
      <PrivateRoute  exact path="/content/sliders/addSlide" component={addSlide} />
      <Route 
        path="/content/sliders/slide/:slide_id"
        render={({ match }) => (
          <UpdateSlide
          key={match.params.postid} slide_id={match.params.slide_id} />
        )}
      />

 


      <PrivateRoute  exact path="/content/comments/all" component={comments} />
      <PrivateRoute  exact path="/content/comments/commentsAdd" component={commentsAdd} />
      <Route 
        path="/content/comments/update/:comment_id"
        render={({ match }) => (
          <commentsUpdate
          key={match.params.postid} comment_id={match.params.comment_id} />
        )}
      />

<PrivateRoute  exact path="/content/SpecialCampaigns" component={SpecialCampaigns} />
      <PrivateRoute  exact path="/content/SpecialCampaignsNew" component={NewSpecialCampaigns} />
      <Route 
        path="/content/SpecialCampaigns/:campaign_id"
        render={({ match }) => (
          <UpdatepecialCampaigns
          key={match.params.postid} campaign_id={match.params.campaign_id} />
        )}
      />





      <Route component={NotFound} />
    </Switch>
  </Router>
  </Provider>,
  document.getElementById("root")
);
