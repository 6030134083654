import React, { button } from "react";
import { Link, withRouter } from "react-router-dom";
import { render } from "react-dom";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import lang from "suneditor/src/lang";
import EasyEdit, { Types } from "react-easy-edit";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InputTags } from "react-bootstrap-tagsinput";
import Dropzone from "react-dropzone";
import Switch from "react-switch";
import ArrayItem from "../../bilesenler/ArrayItems";
import TeamItems from "../../bilesenler/TeamItems";
import MaddeItem from "../../bilesenler/Madde";
import Questions from "../../bilesenler/Questions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import arrayMove from "array-move";
import LzEditor from "react-lz-editor";
import "antd/lib/modal/style/index.css";
import { ReactSortable } from "react-sortablejs";
import SerpPreview from "react-serp-preview";
import Hotkeys from "react-hot-keys";

const tableRef = React.createRef();
const toInputUppercase = (e) => {
  e.target.value = e.target.value
    .replace(/([\wöçşğüıİ])/gi, function (a, b) {
      return b.replace("I", "ı").toLowerCase();
    })
    .replace(/(^[a-zöçşğüı]|[\s|\.][a-zöçşğüı])/g, function (c, d) {
      return d.replace("i", "İ").toUpperCase();
    });
};
class AddPage extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      id: "",
      class: "",
      function: "",
      body: "",
      tags: [],
      short_description: "",
      chars_left: 160,
      max_char: 160,
      categorys: [],
      template: [],
      template_gecici: { data: [] },
      template_name: "",
      link: "",
      title: "",
      loadImage: false,
      file: {},
      form: {},
      addBlockLoad: false,
      header_image: "",
      isDraft: true,
      items: ["Apple", "Banana", "Cherry", "Guava", "Peach", "Strawberry"],
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.linkVer = this.linkVer.bind(this);
    this.icerikver = this.icerikver.bind(this);

    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.addModulePage = this.addModulePage.bind(this);

    //this.receiveHtml = this.receiveHtml.bind(this);
    this.onDrop = (file) => {
      this.setState(
        {
          file: file,
        },
        () => {
          this.addImage();
        }
      );
    };

    this.EklemeResim = (files, e, a) => {
      const file = files[0];
      const file_name = file.name;
      let data = new FormData();
      data.append("image", file);
      data.append("image_name", file_name);

      fetch(this.state.BASE_URL + "/api/upPro/", {
        method: "POST",
        body: data,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (!result.success) {
            cogoToast.warn("Resim Yüklenirken Sorun Oluştu.", {
              position: "top-right",
            });
          } else {
            cogoToast.success("Resim Başarılı Bir Şekilde Yüklendi.", {
              position: "top-right",
            });
            const stat = this.state.template;
            const name = "value";
            stat[e].data[a][name] = result.url;

            this.setState({ stat });
          }
          this.setState({
            loadImage: false,
          });
          console.log(result);
        });
    };

    this.ArrayResim = (files, ust, alt, sira) => {
      const file = files[0];
      const file_name = file.name;
      let data = new FormData();
      data.append("image", file);
      data.append("image_name", file_name);

      fetch(this.state.BASE_URL + "/api/upPro/", {
        method: "POST",
        body: data,
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (!result.success) {
            cogoToast.warn("Resim Yüklenirken Sorun Oluştu.", {
              position: "top-right",
            });
          } else {
            cogoToast.success("Resim Başarılı Bir Şekilde Yüklendi.", {
              position: "top-right",
            });
            const stat = this.state.template;
            stat[ust].data[alt].data[sira].icon = result.url;
            this.setState({ stat });
          }
          this.setState({
            loadImage: false,
          });
          console.log(result);
        });
    };
  }

  componentDidMount() {
    fetch(this.state.BASE_URL + "/api/modules_page")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          categorys: result,
        });
      });
  }

  handleOnChangeBody(event) {
    const stat = this.state;
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  }

  handleWordCount = (event) => {
    const charCount = event.target.value.length;
    const maxChar = this.state.max_char;
    const charLength = maxChar - charCount;
    this.setState({ chars_left: charLength });
  };

  handleChangeCheck(isDraft) {
    this.setState({ isDraft });
  }
  addModulePage(data) {
    var data = JSON.parse(JSON.stringify(data));
    this.setState(({ template }) => {
      const mQuestions = template;
      const yenidata = data;
      mQuestions.push(yenidata);
      return { template: mQuestions };
    });
  }

  handleMultiChange(option) {
    this.setState((state) => {
      return {
        template_name: option.name,
        template: option,
        template_gecici: option,
      };
    });
  }

  receiveHtml = (value, ust, alt, name) => {
    const stat = this.state.template;
    stat[ust].data[alt][name] = value;

    this.setState({ stat });
  };

  addItem(ust) {
    this.setState(({ template }) => {
      const mQuestions = template;
      const yenidata = { name: "", icon: "", description: "" };
      mQuestions[ust].data[0].data.push(yenidata);
      return { template: mQuestions };
    });
  }

  addItemTeam(ust ,alt) {
    this.setState(({ template }) => {
      const mQuestions = template;
      const yenidata = { name: "", icon: "", description: "" ,linkedin : "" };
      mQuestions[ust].data[alt].data.push(yenidata);
      return { template: mQuestions };
    });
  }

  addItemMadde(ust ,alt) {
    this.setState(({ template }) => {
      const mQuestions = template;
      const yenidata = { name: "",description: ""};
      mQuestions[ust].data[alt].data.push(yenidata);
      return { template: mQuestions };
    });
  }

  removeItems = (e, is) => {
    const stats = this.state.template;
    stats.data[e].data[is].splice(is, 1);
    this.setState({ template: stats });
  };

  kaldir = () => {
    this.setState({
      header_image: "",
    });
  };

  kaldirResim = (e, a) => {
    const stat = this.state.template;
    const name = "value";
    stat[e].data[a][name] = "";

    this.setState({ stat });
  };

  inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === "Enter" && val) {
      console.log(val);
    }
  };

  addPage = () => {
    this.setState(
      {
        addBlockLoad: true,
        form: {
          body: this.state.template,
          tags: this.state.tags,
          id: this.state.id,
          function: this.state.function,
          class: this.state.class,
          slug: this.state.link,
          title: this.state.title,
          isDraft: this.state.isDraft,
          description: this.state.short_description,
        },
      },
      () => {
        fetch(this.state.BASE_URL + "/api/add_page", {
          method: "POST",
          body: JSON.stringify(this.state.form),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              addBlockLoad: false,
            });
            if (result.success) {
              cogoToast.success("Sayfa Başarılı Bir Şekilde Eklendi", {
                position: "top-right",
              });

              this.props.history.push("/content/pages/all");
            } else {
              cogoToast.warn("Sayfa Eklenirken Hata Oluştu", {
                position: "top-right",
              });
            }
          });
      }
    );
  };

  addImage = () => {
    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append("image", file);
    data.append("image_name", file_name);

    fetch(this.state.BASE_URL + "/api/upPro/", {
      method: "POST",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (!result.success) {
          cogoToast.warn("Resim Yüklenirken Sorun Oluştu.", {
            position: "top-right",
          });
        } else {
          cogoToast.success("Resim Başarılı Bir Şekilde Yüklendi.", {
            position: "top-right",
          });
          this.setState({
            header_image: result.url,
          });
        }
        this.setState({
          loadImage: false,
        });
        console.log(result);
      });
  };

  icerikver = (e) => {
    this.setState({
      body: e,
    });
  };

  linkVer = (e) => {
    this.setState({
      title: e.target.value,
    });

    var str = e.target.value;
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    this.setState((state) => {
      return {
        link: str,
      };
    });
  };

  linkDegis = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    this.setState((state) => {
      return {
        link: str,
      };
    });
  };

  handleChange = (e) => {
    this.setState({ tags: e });
  };

  sirala = (e) => {
    // this.setState({ template: e });
  };

  deleteModul = (e, name) => {
    confirmAlert({
      title: name,
      message: "Modülü Silmek İstediğinize Emin misiniz?",
      buttons: [
        {
          label: "Hayır",
          onClick: () => {
            return true;
          },
        },
        {
          label: "Evet",
          onClick: () => {
            this.setState(({ template }) => {
              const mQuestions = template;
              mQuestions.splice(e, 1);
              return { template: mQuestions };
            });
          },
        },
      ],
    });
  };

  removeItem = (ust, is, alt) => {
    console.log("Data", this.state.template[ust].data[alt].data);
    this.setState(({ template }) => {
      const mQuestions = template;
      mQuestions[ust].data[alt].data.splice(is, 1);
      return { template: mQuestions };
    });
  };

  ustchange = (ust, alt, event) => {
    const stat = this.state.template;
    const value = event.target.value;
    const name = event.target.name;
    stat[ust].data[alt][name] = value;
    console.log(stat[ust].data[alt][name]);
    this.setState({ stat });
  };

  kaldirIcon = (ust, alt, sira) => {
    const stat = this.state.template;
    stat[ust].data[alt].data[sira].icon = "";
    this.setState({ stat });
  };

  handleClick = (e, event, is = "", alt = "yok") => {
    console.log("Bura", e, is, alt);

    if (is == "") {
      const stat = this.state.template;
      const value = event.target.value;
      const name = event.target.name;
      stat[is].data[e][name] = value;
      this.setState({ stat });
      console.log("burası");
    } else {
      is = is - 1;
      const stat = this.state.template;
      const value = event.target.value;
      const name = event.target.name;
      if (alt == "yok") {
        const stat = this.state.template;
        console.log("Data", this.state.template[is].data[e].value);
        stat[is].data[e].value = value;
        this.setState({ stat });
      } else {
        const stat = this.state.template;
        stat[e].data[alt].data[is][name] = value;
        this.setState({ stat });
      }
    }
  };

  onSortEnd = ({ oldIndex, newIndex, collection }) => {
    const newCollections = this.state.template;
    newCollections[collection.ust].data[collection.alt].data = arrayMove(
      this.state.template[collection.ust].data[collection.alt].data,
      oldIndex,
      newIndex
    );

    this.setState(
      {
        newCollections,
      },
      () => {
        console.log(this.state.template);
      }
    );
  };

  onKeyUp(keyNm, e, handle) {
    console.log(keyNm);
    e.preventDefault();
    if (keyNm == "ctrl+s") {
      //  this.addPage();
    }
    if (keyNm == "ctrl+c") {
      cogoToast.success("İçerik Kopyalandı", {
        position: "top-center",
      });
    }
  }
  onKeyDown(keyName, e, handle) {
    if (keyName == "ctrl+s") {
      e.preventDefault();
    }
  }

  render() {
    const previewStyle = {
      display: "inline",
      width: 100,
      height: 100,
    };

    return (
      <Hotkeys
        keyName="ctrl+s,ctrl+c"
        onKeyUp={this.onKeyUp.bind(this)}
        onKeyDown={this.onKeyDown.bind(this)}
      >
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Yeni Sayfa Oluştur"
                html={
                  <Link
                    to="/content/pages/all"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> Sayfalara Geri Dön
                    Dön
                  </Link>
                }
              />
              <BlockUi
                loader={
                  <div className="loader-img-page-div">
                    <img alt="Resim" src="/assets/img/loader.svg" />
                    <div className="LoaderText-div">Lütfen Bekleyiniz...</div>
                  </div>
                }
                tag={"div"}
                className="row row-sm"
                blocking={this.state.addBlockLoad}
              >
                <div className="col-lg-12 col-md-12">
                  <div className="row row-sm">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="form-group">
                            <div className="card custom-card">
                              <div className="card-body">
                                <label className="row">
                                  <div className="col-lg-6">Sayfa Başlığı</div>
                                  <div className="col-lg-6">
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.isDraft}
                                      onChange={this.handleChangeCheck}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                          Taslak
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                          Hemen Yayınla
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>
                                </label>

                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  onInput={toInputUppercase}
                                  value={this.state.title}
                                  placeholder="Sayfa Başlığı"
                                  onChange={this.linkVer}
                                />

                                {this.state.link ? (
                                  <div className="row">
                                    <div className="col-lg-12 mt-3">
                                      <span className="abonesepeticom">
                                        <i className="fe fe-chrome"></i>{" "}
                                        https://www.abonesepeti.com/{" "}
                                      </span>
                                      <EasyEdit
                                        type={Types.TEXT}
                                        value={this.state.link}
                                        placeholder="Düzenlemek İçin Tıklayın"
                                        saveButtonLabel="Kaydet"
                                        cancelButtonLabel="Vazgeç"
                                        onSave={(val) => this.linkDegis(val)}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="card custom-card">
                            <div className="card-body">
                              <div className="form-group">
                                <label className>Anahtar Kelimeler</label>
                                <div className="pos-relative bootstrap-tagsinput">
                                  <InputTags
                                    autoSave={false}
                                    placeholder="Etiketi Yazıp Enter Tuşuna Başınız"
                                    values={this.state.tags}
                                    onTags={(value) =>
                                      this.handleChange(value.values)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card custom-card">
                            <div className="card-body">
                              <div className="form-group ">
                                <label className>Kısa Açıklama</label>
                                <div>
                                  <textarea
                                    id="short_desc"
                                    name="short_description"
                                    className="form-control"
                                    rows={3}
                                    type="text"
                                    maxLength="160"
                                    defaultValue={this.state.short_description}
                                    onChange={(event) =>
                                      this.handleWordCount(event)
                                    }
                                    onBlur={(event) =>
                                      this.handleOnChangeBody(event)
                                    }
                                  ></textarea>
                                  <p className="text-right mt-2">
                                    {this.state.chars_left} /{" "}
                                    {this.state.max_char}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="card custom-card">
                                <div className="card-body">
                                  <div className="form-group">
                                    <label className>Sayfa id</label>
                                    <div>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">#</div>
                                        </div>
                                        <input
                                          className="form-control"
                                          name="id"
                                          id="id"
                                          value={this.state.id}
                                          placeholder={"Body Sayfa ID"}
                                          onChange={(event) =>
                                            this.handleOnChangeBody(event)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="card custom-card">
                                <div className="card-body">
                                  <div className="form-group">
                                    <label className>Sayfa Css Class</label>
                                    <div>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">
                                            {"{}"}
                                          </div>
                                        </div>
                                        <input
                                          className="form-control"
                                          name="class"
                                          id="class"
                                          value={this.state.class}
                                          placeholder={"Body Sayfa class"}
                                          onChange={(event) =>
                                            this.handleOnChangeBody(event)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4">
                              <div className="card custom-card">
                                <div className="card-body">
                                  <div className="form-group">
                                    <label className>Sayfa JS Fonksiyonu</label>
                                    <div>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text">$</div>
                                        </div>
                                        <input
                                          className="form-control"
                                          name="function"
                                          id="function"
                                          value={this.state.function}
                                          placeholder={"Sayfa JS Fonksiyonu"}
                                          onChange={(event) =>
                                            this.handleOnChangeBody(event)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <ReactSortable
                            list={this.state.template}
                            setList={(newState) => this.sirala(newState)}
                          >
                            {this.state.template.map((templates, is) => (
                              <div className="form-group" key={"map" + is}>
                                <div className="card custom-card col-lg-12">
                                  <div className="card-header custom-card-header border-bottom-0 ">
                                    <h5 className="main-content-label tx-dark my-auto tx-medium mb-0">
                                      {templates.title}
                                    </h5>
                                    <div className="card-options">
                                      {templates.type == "array" ? (
                                        <button
                                          className="card-options-fullscreen"
                                          onClick={(event) => this.addItem(is)}
                                        >
                                          <i className="fe fe-plus"></i> Yeni
                                          Ekle
                                        </button>
                                      ) : (
                                        <></>
                                      )}
                                      <a
                                        href="#"
                                        className="card-options-collapse"
                                        data-toggle="card-collapse"
                                      >
                                        <i className="fe fe-chevron-up"></i>
                                      </a>

                                      <button
                                        className="card-options-removes"
                                        onClick={(e) =>
                                          this.deleteModul(is, templates.title)
                                        }
                                      >
                                        <i className="fe fe-x"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="form-group row">
                                      {templates.data.map((template, i) =>
                                        template.type == "text" ? (
                                          <div
                                            className={template.class}
                                            key={"arr" + i}
                                          >
                                            <div
                                              className="card custom-card"
                                              key={"altmap" + is}
                                            >
                                              <div className="card-body">
                                                <label className>
                                                  {template.title}
                                                </label>

                                                <input
                                                  className="form-control"
                                                  type="text"
                                                  name="value"
                                                  onChange={(event) =>
                                                    this.ustchange(is, i, event)
                                                  }
                                                  value={template.value}
                                                  placeholder={template.title}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : template.type == "textinput" ? (
                                          <div
                                            className={template.class}
                                            key={"arr" + i}
                                          >
                                            <div className="card custom-card">
                                              <div className="card-body">
                                                <label className>
                                                  {template.title}
                                                </label>
                                                <textarea
                                                  className="form-control"
                                                  type="text"
                                                  name="value"
                                                  onChange={(event) =>
                                                    this.ustchange(is, i, event)
                                                  }
                                                  rows="3"
                                                  defaultValue={template.value}
                                                  placeholder={template.title}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : template.type == "button" ? (
                                          <div
                                            className="col-lg-12"
                                            key={"arr" + i}
                                          >
                                            <div className="card custom-card m-0 p-0">
                                              <label className="col-lg-12 text-center">
                                                {template.title}
                                              </label>
                                            </div>

                                            <div className="row">
                                              <div className=" col-lg-6">
                                                <label className>
                                                  Buton Yazısı
                                                </label>
                                                <input
                                                  className="form-control"
                                                  value={template.value}
                                                />
                                              </div>
                                              <div className="col-lg-6">
                                                <label className>
                                                  Buton Bağlantısı
                                                </label>
                                                <input
                                                  className="form-control"
                                                  value={template.href}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : template.type == "textarea" ? (
                                          <div className={template.class}>
                                            <div
                                              className="card custom-card"
                                              key={"arr" + i}
                                            >
                                              <div className="card-body">
                                                <label className>
                                                  {template.title}
                                                </label>

                                                <LzEditor
                                                  active={true}
                                                  name="value"
                                                  importContent={template.value}
                                                  cbReceiver={(value) =>
                                                    this.receiveHtml(
                                                      value,
                                                      is,
                                                      i,
                                                      "value"
                                                    )
                                                  }
                                                  image={false}
                                                  video={false}
                                                  lang="tr"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : template.type == "image" ? (
                                          <div className={template.class}>
                                            <div
                                              className="card custom-card"
                                              key={"arr" + i}
                                            >
                                              <div className="card-body">
                                                <label className>
                                                  {template.title}
                                                </label>

                                                <Dropzone
                                                  onDrop={(files) =>
                                                    this.EklemeResim(
                                                      files,
                                                      is,
                                                      i
                                                    )
                                                  }
                                                >
                                                  {({
                                                    getRootProps,
                                                    getInputProps,
                                                  }) =>
                                                    template.value ? (
                                                      <div className="media d-block mt-3 text-center">
                                                        <img
                                                          src={template.value}
                                                          className="wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0"
                                                        />
                                                        <button
                                                          className="btn btn-warning wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 mt-3 "
                                                          onClick={(files) =>
                                                            this.kaldirResim(
                                                              is,
                                                              i
                                                            )
                                                          }
                                                        >
                                                          Resmi Kaldır
                                                        </button>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        {...getRootProps({
                                                          className: "dropzone",
                                                        })}
                                                      >
                                                        <input
                                                          {...getInputProps()}
                                                        />
                                                        <p>
                                                          Resmi Yüklemek İçin
                                                          Tıklayın <br /> veya{" "}
                                                          <br /> Resmi Buraya
                                                          Sürükleyin
                                                        </p>
                                                      </div>
                                                    )
                                                  }
                                                </Dropzone>
                                              </div>
                                            </div>
                                          </div>
                                        ): template.type == "madde" ? (
                                          
                                          <div
                                            className={template.class}
                                            key={"arr" + i}
                                          >
                                            {" "}
                                            <div className="card-header custom-card-header border-bottom-0 ">
                                    <div className="card-options">
                                   
                                        <button
                                          className="card-options-fullscreen"
                                          onClick={(event) =>  this.addItemMadde(is, i)}
                                        >
                                          <i className="fe fe-plus"></i> Yeni
                                          Ekle
                                        </button>
                                     

                                    </div>
                                  </div>
                                            <div className=" pt-2 mt-0 no-list">
                                              <MaddeItem
                                                data={
                                                  this.state.template[is].data[
                                                    i
                                                  ].data
                                                }
                                                ust={is}
                                                alt={i}
                                                onSortEnd={this.onSortEnd}
                                                delete={this.removeItem}
                                                guncelle={this.handleClick}
                                                upload={this.ArrayResim}
                                                deleteIcon={this.kaldirIcon}
                                              />
                                            </div>
                                          </div>
                                        )   : template.type == "team" ? (
                                          <div
                                            className={template.class}
                                            key={"arr" + i}
                                          >
                                            {" "}
                                            <div className="card-header custom-card-header border-bottom-0 ">
                                    <div className="card-options">
                                   
                                        <button
                                          className="card-options-fullscreen"
                                          onClick={(event) =>  this.addItemTeam(is, i)}
                                        >
                                          <i className="fe fe-plus"></i> Yeni Kişi Ekle</button>
                                     

                                    </div>
                                  </div>
                                            <div className=" pt-2 mt-0 no-list">
                                            <label className>
                                                  Ekibimiz
                                                </label>
                                              <TeamItems
                                                data={
                                                  this.state.template[is].data[
                                                    i
                                                  ].data
                                                }
                                                ust={is}
                                                alt={i}
                                                onSortEnd={this.onSortEnd}
                                                delete={this.removeItem}
                                                guncelle={this.handleClick}
                                                upload={this.ArrayResim}
                                                deleteIcon={this.kaldirIcon}
                                              />
                                            </div>
                                          </div>
                                        ) : template.type == "array" ? (
                                          <div
                                            className={template.class}
                                            key={"arr" + i}
                                          >
                                            {" "}
                                            <div className=" pt-2 mt-0 no-list">
                                              <ArrayItem
                                                data={
                                                  this.state.template[is].data[
                                                    i
                                                  ].data
                                                }
                                                ust={is}
                                                alt={i}
                                                onSortEnd={this.onSortEnd}
                                                delete={this.removeItem}
                                                guncelle={this.handleClick}
                                                upload={this.ArrayResim}
                                                deleteIcon={this.kaldirIcon}
                                              />
                                            </div>
                                          </div>
                                        ) 
                                        : template.type == "team" ? (
                                          <div
                                            className={template.class}
                                            key={"arr" + i}
                                          >
                                            {" "}
                                            <div className=" pt-2 mt-0 no-list">
                                            <label className>
                                                  Ekibimiz
                                                </label>
                                              <ArrayItem
                                                data={
                                                  this.state.template[is].data[
                                                    i
                                                  ].data
                                                }
                                                ust={is}
                                                alt={i}
                                                onSortEnd={this.onSortEnd}
                                                delete={this.removeItem}
                                                guncelle={this.handleClick}
                                                upload={this.ArrayResim}
                                                deleteIcon={this.kaldirIcon}
                                              />
                                            </div>
                                          </div>
                                        )
                                        
                                        : template.type == "object" ? (
                                          <div
                                            className={template.class}
                                            key={"arr" + i}
                                          >
                                            {" "}
                                            <div className="card custom-card">
                                              <div className="card-header  border-bottom-0 pb-0">
                                                <div>
                                                  <div className="d-flex">
                                                    <label className="main-content-label my-auto pt-2">
                                                      {template.title}
                                                    </label>
                                                    <div className="ml-auto mt-3 d-flex">
                                                      <div className="mr-3 d-flex text-muted tx-13">
                                                        <button
                                                          className="btn btn-success  btn-sm pull-right"
                                                          onClick={(event) =>
                                                            this.addItem(is, i)
                                                          }
                                                        >
                                                          <i className="fe fe-plus"></i>{" "}
                                                          Yeni Ekle
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="card-header border-bottom-0 pb-0 d-flex pl-3 ml-1"></div>

                                              <div className="card-body pt-2 mt-0 no-list">
                                                <Questions
                                                  data={
                                                    this.state.template[i].data
                                                  }
                                                  name_string={
                                                    this.state.template[i]
                                                      .name_string
                                                  }
                                                  value_string={
                                                    this.state.template[i]
                                                      .value_string
                                                  }
                                                  ust={i}
                                                  onSortEnd={this.onSortEnd}
                                                  delete={this.removeItem}
                                                  guncelle={this.handleClick}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </ReactSortable>
                          {this.state.template.length > 0 ? (
                            <div div className="card custom-card ">
                              <div className="card-body">
                                <button
                                  className="btn ripple btn-main-primary pull-right"
                                  onClick={this.addPage}
                                >
                                  <i className="fe fe-plus"></i> Sayfayı Ekle
                                </button>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>

                        <BlockUi
                          loader={
                            <div className="loader-img-page-div">
                              <img alt="Resim" src="/assets/img/loader.svg" />
                              <div className="LoaderText-div">
                                Resim Yüklenirken Lütfen Bekleyiniz...
                              </div>
                            </div>
                          }
                          tag={"div"}
                          className="col-lg-4 custom-leftnav"
                          blocking={this.state.loadImage}
                        >
                          <div className="main-content-left-components">
                            {this.state.short_description ? (
                              <div className="card custom-card">
                                <div className="card-body component-item">
                                  <SerpPreview
                                    className="w-100"
                                    title={this.state.title}
                                    metaDescription={
                                      this.state.short_description
                                    }
                                    url={
                                      "https://abonesepeti.com/" +
                                      this.state.link
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="card custom-card">
                              <div className="card-body">
                                <div className="table-responsive border-left border-top">
                                  <table className="table table-bordered mg-b-0">
                                    <tbody>
                                      {
                                      
                                      this.state.categorys.sort(function(a, b) {
                                        if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                        if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                        return 0;
                                       }).map(
                                        (template, i) => (
                                          <tr key={i}>
                                            <td className="bd-t-0 bd-l-0">
                                              <h6 className="mg-b-0">
                                                {template.title}
                                              </h6>
                                            </td>
                                            <td className="bd-t-0 bd-l-0">
                                              <button
                                                className="btn ripple btn-primary btn-sm"
                                                onClick={(e) =>
                                                  this.addModulePage(template)
                                                }
                                              >
                                                Sayfaya Ekle
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </BlockUi>
                      </div>
                    </div>
                  </div>
                </div>
              </BlockUi>
            </div>
          </div>
        </div>
      </Hotkeys>
    );
  }
}

export default withRouter(AddPage);
