import React from 'react';
import { seo } from "../helpers";
class PageHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    seo({
      title: this.props.title+" | Maya",
      metaDescription: "Maya Ana Ekranı",
    });
  }

  render() {
    return (
        <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
           {this.props.title}
          </h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Maya</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            {this.props.title}
            </li>
          </ol>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
          {this.props.html}
          </div>
        </div>
      </div>
    );
  }
}



  React.defaultProps = {
    name: 'Home',
    position: 1
  };

export default PageHeader;