import cogoToast from "cogo-toast";
import MaterialTable from "material-table";
import Moment from "moment";
import moment from "moment";
import React, { button } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link, Redirect } from "react-router-dom";

import { config } from "../../config";
import { seo } from "../../helpers";
import Ustkisim from "../inc/Header";

import "moment/locale/tr";

const tableRef = React.createRef();
class Jobs extends React.Component {
  constructor(props, context) {
    super(props);
    const datePickerId = moment(new Date()).format("YYYY-MM-DD[T]HH:mm");

    this.state = {
      load_detail: false,
    };
    this.state.BASE_URL = config().BASE_URL;

  }

  componentDidMount() {
    seo({
      title: "İş İlanları | Atlas",
      metaDescription: "Atlas Ana Ekranı",
    });

  }

  filtrele = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  RemoteData = () => {
    const deleteStatus = (id , status) => {
      if(status == 0)
      {
        status = 1 ;
      }
      else 
      {
        status  = 0 ;
      }
      fetch(this.state.BASE_URL + "/api/SpecialCampaign_delete/" + id + '/'+status, {
        method: "GET",
      }).then((response) => {
        if (response.status === 200) {
          tableRef.current && tableRef.current.onQueryChange();

          cogoToast.success("İş İlanı Başarılı Bir Şekilde Silindi", {
            position: "top-right",
          });
        } else {
          cogoToast.warn("İş İlanı Silinemedi Hata Oluştu", {
            position: "top-right",
          });
        }
      });
    };


    return (
      <MaterialTable
        key="Tablom"
        title=" Özel Kampanyalar"
        tableRef={tableRef}
        actions={[
          (rowData) => ({
            icon: "edit",
            tooltip: "Düzenle",
            onClick: (event, rowData) =>
              this.props.history.push("/content/SpecialCampaigns/" + rowData.id),
          }),
          (rowData) => ({
            icon: rowData.status  == true ? "toggle_on" :  "toggle_off",
            tooltip: "Durumu Değiştir",
            onClick: (event, rowData) =>
              confirmAlert({
                title: "Kampanya Durumu",
                message: "Kampanya Durumunu Değiştirmek İstediğnize Emin misiniz?",
                buttons: [
                  {
                    label: "Hayır",
                    onClick: () => {
                      return true;
                    },
                  },
                  {
                    label: "Evet",
                    onClick: () => {
                      deleteStatus(rowData.id,rowData.status);
 
                    }, //tableRef.current && tableRef.current.onQueryChange() ;
                  },
                ],
              }),
            disabled: rowData.count > 0,
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
          filtering: false,
          pageSizeOptions: [1, 2, 3, 4, 5, 50, 100, 200],
          pageSize: 50,
          headerStyle: {
            height: "25px !important",
            padding: "5px 20px",
          },
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{count} sonuçtan {from}-{to} arası sonuçlar",
            labelRowsSelect: "data göster ",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: "İşlem",
          },
          body: {
            emptyDataSourceMessage: "Gösterilecek Data Bulunamadı",
            filterRow: {
              filterTooltip: "Filtre",
            },
          },
        }}
        columns={[
          {
            title: "Tarih",
            field: "created_date",
            render: (rowData) => (
              <p className="text-nowrap">
                <strong>
                  {Moment(rowData.created_date).format("DD MMM YYYY")}
                </strong>
              </p>
            ),
          },

          {
            title: "Başlık",
            field: "title",
            render: (rowData) => (
              <p>
                <strong>{rowData.title}</strong>
              </p>
            ),
          },

          {
            title: "Açıklama",
            field: "description",
            render: (rowData) => (
              <p>
                <strong>{rowData.description}</strong>
              </p>
            ),
          },
          {
            title: "Durumu",
            field: "status",
            render: (rowData) =>
            rowData.status == true ? <span class="badge badge-pill badge-success-light">Aktif</span> : <span class="badge badge-pill badge-danger-light">Pasif</span>
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            if (!query.orderDirection) {
              query.orderBy = { field: "id" };
              query.orderDirection = "desc";
            }


            let url = config().BASE_URL + "/api/all_SpecialCampaign?";
            url += "limit=" + query.pageSize;
            url +=
              "&order[" + query.orderBy.field + "]=" + query.orderDirection;
            url += "&page=" + (query.page + 1);
            fetch(url)
              .then((response) => response.json())
              .then((result) => {
                if (result.error) {
                  cogoToast.warn(
                    "Webservis Kaynaklı Sorun Oluşmuştur, Lütfen Sistem Yöneticisine Bilgi Veriniz...",
                    { position: "bottom-right" }
                  );
                }

                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    );
  };

  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img alt="Resim" src="../assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">Özel Kampanyalar</h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Atlas</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Özel Kampanyalar
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <Link
                      to={"/content/SpecialCampaignsNew"}
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-plus mr-2" /> Yeni Özel Kampanya Ekle
                    </Link>
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                    <div className="card-body pt-0">{this.RemoteData()}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Jobs;
