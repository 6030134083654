import cogoToast from "cogo-toast";
import React, { button } from "react";
import BlockUi from "react-block-ui";
import Dropzone from "react-dropzone";
import EasyEdit, { Types } from "react-easy-edit";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import Switch from "react-switch";

// Import Sun Editor's CSS File

import PageHeader from "../../bilesenler/PageHeader";
import { config } from "../../config";
import Ustkisim from "../inc/Header";

import "react-block-ui/style.css";
import "react-block-ui/style.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "suneditor/dist/css/suneditor.min.css";

class NewCampaign extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      file: {},
      categorys : [],
      platforms : [],
      products : [],
      SpecialCampaigns: {
        description: "",
        title: "",
        image: "",
        IsExternal: false,
        slug: '',
        buttontext: 'Kampanyaları Göster',
        buttonurl: '',
        category : '',
        platform : {},
        product : {},
        body: [{ name: "" }],
      },
    };
    this.state.BASE_URL = config().BASE_URL;
    this.onDrop = (file) => {
      this.setState({
        file: file,
      }, () => {
        this.addImage();
      });

    };
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleMultiChange = this.handleMultiChange.bind(this);    
    this.handlePlatformChange= this.handlePlatformChange.bind(this);  
    this.handleProductChange= this.handleProductChange.bind(this);  
  }


  componentDidMount() {

    fetch(
      this.state.BASE_URL + "/api/get_special_campaigns_single/" + this.props.campaign_id
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {

        this.setState({
          SpecialCampaigns : result.data
        });
            if(result.data?.platform?.id)
            {
              fetch(this.state.BASE_URL + "/api/remote_Product/"+result.data.platform.id)
              .then((response) => {
                return response.json();
              })
              .then((result) => {
                this.setState({
                  products: result,
                });
              });
            }
      
      
      });

    fetch(this.state.BASE_URL + "/api/special_campaigns_category")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          categorys: result,
        });


     


      });

      fetch(this.state.BASE_URL + "/api/remote_Cats")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          platforms: result,
        });



      });

  }

  handleChangeCheck(IsExternal) {
    const SpecialCampaigns = this.state.SpecialCampaigns;
    SpecialCampaigns.IsExternal = IsExternal;
    this.setState({
      SpecialCampaigns
    });
  }

  linkVer = (e) => {
    const SpecialCampaigns = this.state.SpecialCampaigns;
    SpecialCampaigns.title = e.target.value;

    var str = e.target.value;
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes


    SpecialCampaigns.slug = str;
    this.setState({
      SpecialCampaigns
    });
  };

  linkDegis = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes


    const SpecialCampaigns = this.state.SpecialCampaigns;
    SpecialCampaigns.slug = str;
    this.setState({
      SpecialCampaigns
    });

  };




  handleOnChange(event) {
    const SpecialCampaigns = this.state.SpecialCampaigns;
    SpecialCampaigns[event.target.name] = event.target.value;
    this.setState({
      SpecialCampaigns
    });
  }


  handleMultiChange(option) {
  
    const SpecialCampaigns = this.state.SpecialCampaigns;
    SpecialCampaigns.category = option.id;
    this.setState({
      SpecialCampaigns
    });

  
  }

  handlePlatformChange(option) {
    const SpecialCampaigns = this.state.SpecialCampaigns;
    SpecialCampaigns.platform = option;
    SpecialCampaigns.product = {};
    this.setState({
      SpecialCampaigns
    });

    fetch(this.state.BASE_URL + "/api/remote_Product/"+option.id)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      this.setState({
        products: result,
      });
    });
  }

  handleProductChange(option) {
    const SpecialCampaigns = this.state.SpecialCampaigns;
    SpecialCampaigns.product = option;
    this.setState({
      SpecialCampaigns
    });
  }
  kaldir = () => {
    const SpecialCampaigns = this.state.SpecialCampaigns;
    SpecialCampaigns.image = "";
    this.setState({
      SpecialCampaigns
    });


  }
  bodyeval(event, sira) {
    const stat = this.state.SpecialCampaigns.body;
    const name = "name";
    const value = event.target.value;
    stat[sira][name] = value;
    this.setState({ stat });
  }

  addItem() {
    const stat = this.state.SpecialCampaigns.body;
    const veri = { name: "" };

    stat.push(veri);

    this.setState({ stat });
  }

  removeItems = (i) => {
    const stat = this.state.SpecialCampaigns.body;
    stat.splice(i, 1);
    this.setState({ stat });
  };
  addImage = () => {

    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/SpecialCampaigns", {
      method: 'POST',
      body: data
    }).then((response) => {
      return response.json();
    })
      .then((result) => {



        if (!result.success) {
          cogoToast.warn(
            "Resim Yüklenirken Sorun Oluştu.",
            { position: "top-right" }
          );
        }
        else {
          cogoToast.success(
            "Resim Başarılı Bir Şekilde Yüklendi.",
            { position: "top-right" }
          );
          const SpecialCampaigns = this.state.SpecialCampaigns;
          SpecialCampaigns.image = result.url;
          this.setState({
            SpecialCampaigns
          });

        }
        this.setState({
          loadImage: false,
        });
        console.log(result);
      });
  }

  ekleComment = () => {

    this.setState({
      addBlockLoad: true,
      form: this.state.SpecialCampaigns,
    }, () => {

      fetch(this.state.BASE_URL + "/api/addSpecialCampaign", {
        method: "POST",
        body: JSON.stringify(this.state.form),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          this.setState({
            addBlockLoad: false,
          });
          if (result.success) {

            cogoToast.success("Kampanya Başarılı Bir Şekilde Oluşturuldu", {
              position: "top-right",
            });

            this.props.history.push("/content/SpecialCampaigns");

          } else {
            cogoToast.warn("Kampanya Oluşturulurken Hata Oluştu", {
              position: "top-right",
            });
          }
        }).catch((error) => {
          this.setState({
            addBlockLoad: false,
          });
          cogoToast.warn("Kampanya Oluşturulurken Hata Oluştu", {
            position: "top-right",
          });

        })



    });


  }

  render() {
    return (
      <div>
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Kampanyayı Düzenle"
                html={
                  <Link
                    to="/content/SpecialCampaigns"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> Özel Kampanyalara Geri Dön
                    
                  </Link>
                }
              />
              <BlockUi
                loader={
                  <div className="loader-img-page-div">
                    <img alt="Resim" src="/assets/img/loader.svg" />
                    <div className="LoaderText-div">
                      Müşteri Yorumu Eklenirken Lütfen Bekleyiniz...
                    </div>
                  </div>
                }
                tag="div"
                className="row row-sm"
                blocking={this.state.addBlockLoad}
              >
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card ">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-8">
                              <div className="row">
                              <div className="form-group col-lg-12">
                                <label className>Kampanya Tipi</label>
                                <div className="pos-relative">
                                <div className="row">
                                <div className="col-lg-6">
                                  <Select
                                    
                                    name="categorys"
                                    options={this.state.categorys}
                                    getOptionLabel={(option) =>
                                      `${option.name}`
                                    }
                                    getOptionValue={(option) => `${option.id}`}
                                    onChange={this.handleMultiChange}
                                    className="basic-single-select"
                                    classNamePrefix="select"
                                    placeholder="Lütfen Kategori Seçiniz"
                                  //  value={this.state?.categorys?.filter(({value}) => value === this.state.SpecialCampaigns.category)[0]}
                                    value={this.state.categorys.filter(
                                      (option) =>
                                        option.id === this.state.SpecialCampaigns.category
                                    )}
                
                                  />
                                  </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group col-lg-12">
                                <label className>Kampanya Kategorisi</label>
                                <div className="pos-relative">
                                <div className="row">
                                <div className="col-lg-6">
                       
                                  <Select
                                    
                                    name="categorys"
                                    options={this.state.platforms}
                                    getOptionLabel={(option) =>
                                      `${option.name}`
                                    }
                                    getOptionValue={(option) => `${option.slug}`}
                                    onChange={this.handlePlatformChange}
                                    className="basic-single-select"
                                    classNamePrefix="select"
                                    placeholder="Lütfen Kategori Seçiniz"
                                    value={this.state.platforms.filter(
                                      (option) =>
                                        option.id === this.state.SpecialCampaigns.platform?.id
                                    )}
                                  />
                                  </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group col-lg-12">
                                <label className>Ürün Adı</label>
                                <div className="pos-relative">
                                <div className="row">
                                <div className="col-lg-6">
                        
                                  <Select
                                    
                                    name="categorys"
                                    options={this.state.products}
                                    getOptionLabel={(option) =>
                                      `${option.subset.name} -  ${option.name} `
                                    }
                                    getOptionValue={(option) => `${option.slug}`}
                                    onChange={this.handleProductChange}
                                    className="basic-single-select"
                                    classNamePrefix="select"
                                    placeholder="Lütfen Kategori Seçiniz"
                                    value={this.state.products.filter(
                                      (option) =>
                                        option.id === this.state.SpecialCampaigns.product?.id
                                    )}
                                  />
                                  </div>
                                  </div>
                                </div>
                              </div>
                              

                                <div className="form-group col-lg-12">
                                  <label className="row">
                                    <div className="col-lg-6">Kampanya Başlığı</div>
                                  </label>
                                  <input
                                    className="form-control"
                                    required
                                    type="text"

                                    name="title"
                                    value={this.state.SpecialCampaigns.title}
                                    onChange={this.linkVer}

                                  />


                                  {
                                    this.state.SpecialCampaigns?.slug ? (
                                      <div className="row">
                                        <div className="col-lg-12 mt-3">
                                          <span className="abonesepeticom">
                                            <i class="fe fe-chrome"></i>{" "}
                                            https://www.abonesepeti.com/kampanyalar/{" "}
                                          </span>
                                          <EasyEdit
                                            type={Types.TEXT}
                                            value={this.state.SpecialCampaigns?.slug}
                                            placeholder="Düzenlemek İçin Tıklayın"
                                            saveButtonLabel="Kaydet"
                                            cancelButtonLabel="Vazgeç"
                                            onSave={(val) => this.linkDegis(val)}
                                          />
                                        </div>
                                      </div>
                                    ) : (<></>)
                                  }

                                </div>
                                <div className="form-group col-lg-12">
                                  <label className="row">
                                    <div className="col-lg-6">Kısa Açıklama</div>
                                  </label>
                                  <textarea
                                    rows="4"
                                    className="form-control"
                                    required

                                    name="description"
                                    value={this.state.SpecialCampaigns.description}
                                    onChange={(event) =>
                                      this.handleOnChange(event)
                                    }
                                  />
                                </div>


                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <label className="row">
                                      <div className="col-lg-12">Kampanya Koşulları</div>
                                    </label>

                                    {this.state?.SpecialCampaigns?.body?.map((template, i) => (
                                      <div
                                        className="d-flex mb-3"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        <input
                                          className="form-control"
                                          required
                                          type="text"
                                          placeholder="Koşul"
                                          id="name"
                                          name="name"
                                          value={this.state?.SpecialCampaigns?.body[i]?.name}
                                          onChange={(event) =>
                                            this.bodyeval(event, i)
                                          }
                                        />
                                        <button
                                          className="btn btn-danger ml-3 btn-sm pull-right"
                                          onClick={(event) => this.removeItems(i)}
                                        >
                                          <i className="fe fe-trash"></i>{" "}
                                        </button>
                                      </div>
                                    ))}

                                    <div className="d-flex">
                                      <div className="ml-auto mt-3 d-flex">
                                        <div className="d-flex text-muted tx-13">
                                          <button
                                            className="btn btn-success  btn-sm pull-right"
                                            onClick={(event) => this.addItem()}
                                          >
                                            <i className="fe fe-plus"></i> Yeni Ekle
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>



                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <fieldset className="scheduler-border">
                                      <legend className="scheduler-border">
                                        Buton Ayarları
                                      </legend>
                                      <div className="row">
                                        <div className="form-group col-lg-4">
                                          <label className="row">
                                            <div className="col-lg-6">Buton Yazısı</div>
                                          </label>
                                          <input
                                            className="form-control"
                                            required
                                            type="text"
                                            placeholder="Kampanyaları Göster"
                                            name="buttontext"
                                            value={this.state.SpecialCampaigns.buttontext}
                                            onChange={(event) =>
                                              this.handleOnChange(event)
                                            }
                                          />
                                        </div>

                                        <div className="form-group col-lg-4">
                                          <label className="row">
                                            <div className="col-lg-6">Buton Bağlantısı</div>
                                          </label>
                                          <input
                                            className="form-control"
                                            required
                                            type="text"
                                            placeholder="Buton Bağlantısı"
                                            name="buttonurl"
                                            value={this.state.SpecialCampaigns.buttonurl}
                                            onChange={(event) =>
                                              this.handleOnChange(event)
                                            }
                                          />
                                        </div>
                                        
                                        <div className="col-lg-4">
                                          <div className="form-group">
                                            <div className="pos-relative" style={{paddingTop : '35px'}}>
                                              
                                              <Switch


                                                onColor="#e0f7ea"
                                                onHandleColor="#00d057"
                                                handleDiameter={30}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={20}
                                                width={48}
                                                checked={this.state.SpecialCampaigns.IsExternal}
                                                onChange={this.handleChangeCheck}
                                                uncheckedIcon={
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "flex-end",
                                                      alignItems: "center",
                                                      height: "100%",
                                                      fontSize: 15,
                                                      paddingRight: 2,
                                                      width: "180px"
                                                    }}
                                                  >
                                                    Aynı Sayfada Aç
                                                  </div>
                                                }
                                                checkedIcon={
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "flex-end",
                                                      alignItems: "center",
                                                      height: "100%",
                                                      fontSize: 15,
                                                      paddingRight: 2,
                                                      width: "200px",
                                                      color: '#00d057'
                                                    }}
                                                  >
                                                    Yeni Sayfada Aç
                                                  </div>
                                                }
                                                className="react-switch"
                                                id="icon-switch"
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-lg-6">
                                          <div className="form-group">
                                            <div className="pos-relative">

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </fieldset>
                                  </div></div>


                              </div>

                            </div>

                            <BlockUi
                              loader={
                                <div className="loader-img-page-div">
                                  <img
                                    alt="Resim"
                                    src="/assets/img/loader.svg"
                                  />
                                  <div className="LoaderText-div">
                                    Resim Yüklenirken Lütfen Bekleyiniz...
                                  </div>
                                </div>
                              }
                              tag="div"
                              className="col-lg-4"
                              blocking={this.state.loadImage}
                            >
                              <div className="form-group">
                                <label className>Müşteri Resmi </label>
                                <div className="pos-relative">
                                  <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="container">
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p>
                                            Müşteri Resmini Yüklemek İçin Tıklayın <br /> veya <br /> Resmi Buraya Sürükleyin
                                          </p>
                                        </div>
                                        {this.state.SpecialCampaigns.image ? (
                                          <div class="media d-block mt-3 text-center">
                                            <img
                                              src={this.state.SpecialCampaigns.image}
                                              className="wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0"
                                            />
                                            <button className="btn btn-warning wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 " onClick={this.kaldir}>Resmi Kaldır</button>
                                          </div>) : (<></>
                                        )}
                                      </section>
                                    )}
                                  </Dropzone>


                                </div>
                              </div>
                            </BlockUi>

                            <div className="col-lg-12">
                              <button className="btn ripple btn-main-primary pull-right" onClick={this.ekleComment}>
                                Kampanyayı Ekle
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </BlockUi>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewCampaign);

