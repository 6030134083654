import React, { button } from "react";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "moment/locale/tr";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader"
import GlobalLoader from "../../bilesenler/GlobalLoader"
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import {Select as RSelect} from 'react-select';
import { Link , Switch, Route, useRouteMatch } from "react-router-dom";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const animatedComponents = makeAnimated();
class KiNewBrand extends React.Component {
  constructor(props, context) {
    super(props);
    const datePickerId = moment(new Date()).format("YYYY-MM-DD[T]HH:mm");
    this.state = {
      iptalneden : {},
      categorys : {},
      brand : {},
      brands : {},
      form_data : {},
      brandcategorys: [],
      brand_ozel_alanlar : [],
      iptalnedenleri  : [] ,
      load_detail: false,
      src: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: 1 / 1
      }
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.handleMultiChange_Ozel = this.handleMultiChange_Ozel.bind(this);
    this.addKurum = this.addKurum.bind(this);
    this.handleiptalnedensec = this.handleiptalnedensec.bind(this);
    this.kurumSec = this.kurumSec.bind(this);
    this.UpdateChange = this.UpdateChange.bind(this);
 
  }

componentDidMount() {

    fetch(this.state.BASE_URL+"/api/getBransProps")
        .then(response => {
            return response.json();
        }).then(result => {
         
            this.setState({
                iptalneden  : result.iptal_neden,
                categorys   : result.categorys,
                ozel_alanlar   : result.ozel_alanlar,
                brands   : result.brands,
            });
        });
}

handleMultiChange(option) {
   
    this.setState(state => {
      return {
        brandcategorys: option
      };
    });
  }

    
  handleMultiChange_Ozel(event , index , name) {
   
  
    var someProperty = this.state.brandcategorys[index];
    someProperty[name] = event;
    this.setState({ someProperty });

    console.log(this.state.brandcategorys);

  }
  

  handleiptalnedensec(option) {
   
    this.setState(state => {
      return {
        iptalnedenleri: option
      };
    });
  }

  
  
  kurumSec(option) {
    this.setState(state => {
        return {
          brand: option
        };
      });
        
   
  }

  


  handleChange = (field, value) =>
  {
   
      console.log(value);
  }

  UpdateChange = (e) => {
      console.log(e.target.name , e.target.value);

    var someProperty = this.state.brandcategorys;
    someProperty[e.target.name] = e.target.value;
    this.setState({ someProperty });
    
  };


  onChangeTerm = (event , index) => {
    console.log(event.target.name, event.target.value, this.state.brandcategorys[index]);
    var someProperty = this.state.brandcategorys[index];
    someProperty[event.target.name] = event.target.value;
    this.setState({ someProperty });
   // console.log(this.state.brandcategorys.splice(0,index));
}

addKurum = () => {



      this.setState({
        form_data: {brand : this.state.brand  , categorys : this.state.brandcategorys }
    }, () => {
       
        this.setState({
            load_detail: true,
          });
      
          fetch(this.state.BASE_URL + "/api/ki_add_brand", {
            method: "POST",
            body: JSON.stringify(this.state.form_data),
          })
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              this.setState({
                load_detail: false,
              });
              if (result.success) {
              
                cogoToast.success("Kurum Başarılı Bir Şekilde Eklendi", {
                  position: "top-right",
                });
              } else {
                cogoToast.warn("Kurum Oluşturulurken Hata Oluştu", {
                  position: "top-right",
                });
              }
            });

    });


      



      console.log(this.state.form_data);

};

onSelectFile = (e) => {
  if (e.target.files && e.target.files.length > 0) {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      this.setState({ src: reader.result })
    );
    reader.readAsDataURL(e.target.files[0]);
  }
};

// If you setState the crop in here you should return false.
onImageLoaded = (image) => {
  this.imageRef = image;
};

onCropComplete = (crop) => {
  this.makeClientCrop(crop);
};

onCropChange = (crop, percentCrop) => {
  // You could also use percentCrop:
  // this.setState({ crop: percentCrop });
  this.setState({ crop });
};

async makeClientCrop(crop) {
  if (this.imageRef && crop.width && crop.height) {
    const croppedImageUrl = await this.getCroppedImg(
      this.imageRef,
      crop,
      "newFile.jpeg"
    );

        console.log(croppedImageUrl);

    this.setState({ croppedImageUrl });
  }
}

getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        //reject(new Error('Canvas is empty'));
        console.error("Canvas is empty");
        return;
      }
      blob.name = fileName;
      window.URL.revokeObjectURL(this.fileUrl);
      this.fileUrl = window.URL.createObjectURL(blob);
      resolve(this.fileUrl);
    }, "image/jpeg");
  });
}

  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <GlobalLoader />
            <div className="inner-body">
            
              <PageHeader title="Yeni Kurum Ekle" html={  <Link
                      to="/settings/KiBrands"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-arrow-left mr-2" /> Kurum Listesine Geri Dön
                    </Link>} />

              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                <div className="card custom-card">
  <div className="card-body">
    <div>
      <h6 className="main-content-label mb-1">Kurum Ekleme Formu</h6>
      <p className="text-muted card-sub-title">Kurumu Eklemek İçin  Formu Eksiksil Doldurunuz...</p>
    </div>
    <BlockUi
                      loader={
                        <div className="loader-img-page-div">
                          <img src="/assets/img/loader.svg" />
                          <div className="LoaderText-div">
                            Lütfen Bekleyiniz...
                          </div>
                        </div>
                      }
                      tag="div"
                      className="card-body"
                      blocking={this.state.load_detail}
                    >
    <div className>

    <div className="row row-xs align-items-center mg-b-20">
        <div className="col-md-4">
          <label className="mg-b-0">Kurum Seçimi</label>
        </div>
        <div className="col-md-8 mg-t-5 mg-md-t-0">



        <Select
            name="brands"
            getOptionLabel={option =>
                `${option.makename}`
              }
              getOptionValue={option => `${option}`}
            options={this.state.brands}
            onChange={this.kurumSec}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Lütfen Kurum Seçiniz"
        />
        </div>
      </div>



      <div className="row row-xs align-items-center mg-b-20">
        <div className="col-md-4">
          <label className="mg-b-0">Kurum Kategorileri</label>
        </div>
        <div className="col-md-8 mg-t-5 mg-md-t-0">



        <Select
            isMulti
            name="categorys"
            components={animatedComponents}
            options={this.state.categorys}
            onChange={this.handleMultiChange}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Lütfen Kategori Seçiniz"
        />
        </div>
      </div>

 

    
 


  


      {this.state.brandcategorys.map((answer, i) => {     
                   
                   return (
                    <li className="form-group" key={answer.value} className="list-none">
                    <div className="row row-xs align-items-center mg-b-20">
                    <fieldset className="col-lg-12">
                    <legend>{answer.label}</legend>
             
             
                    <div className="row row-xs align-items-center mg-b-20">
        <div className="col-md-4">
          <label className="mg-b-0">İptal Nedenleri</label>
        </div>
        <div className="col-md-8 mg-t-5 mg-md-t-0">



        <Select
            isMulti
            name="iptalneden"
            components={animatedComponents}
            options={this.state.iptalneden}
            onChange={(e) =>this.handleMultiChange_Ozel(e,i , "iptalneden")}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Lütfen İptal Nedenlerini Seçiniz"
        />
        </div>
      </div>


      <div className="row row-xs align-items-center mg-b-20">
        <div className="col-md-4">
          <label className="mg-b-0">İstenecek Alanlar</label>
        </div>
        <div className="col-md-8 mg-t-5 mg-md-t-0">



        <Select
            isMulti
            name="ozel_alanlar"
            components={animatedComponents}
            options={this.state.ozel_alanlar}
            onChange={(e) =>this.handleMultiChange_Ozel(e,i , "ozel_alanlar")}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Lütfen Alan Seçiniz"
        />
        </div>
      </div>

      <div className="row row-xs align-items-center mg-b-20">
        <div className="col-md-4">
          <label className="mg-b-0">{answer.label} İçin Klasör</label>
        </div>
        <div className="col-md-8 mg-t-5 mg-md-t-0">


        <input type="text" name="klasor"  className="form-control"
        placeholder={answer.label + " İçin Pdf Klasör Yolu"}
        value={this.state.brandcategorys[i].klasor} 
        onChange={(e) =>this.onChangeTerm(e,i)}>
        </input>
        </div>
      </div>

                  </fieldset>
                  </div>
                   
                
      </li>)
     
    })}
   
     
   
      <div className="form-group row justify-content-end mb-0">
        <div className="col-md-8 pl-md-2">
          <button className="btn ripple btn-primary pd-x-30 mg-r-5"     onClick={this.addKurum}  >Kurumu Ekle</button>
      
        </div>
      </div>
    </div>
 </BlockUi>
  </div>
</div>

                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3"  >
                  <div className="card custom-card">
             

<div className="row row-sm">
  <div className="col-md-12 col-lg-12 col-xl-12">
    <div  className="card-body card-body user-card text-center">


											<div class="main-img-user  picture avatar-lg online text-center">
												<img alt="avatar" class="rounded-circle" src={this.state.brand.makeImage} />
											</div>
											<div class="mt-2">
												<h5 class="mb-1">{this.state.brand.makename}</h5>
											
										
											</div>
															

    <div className="row row-xs align-items-center mg-b-20">
        <div className="col-md-4">
          <label className="mg-b-0">Kurum Adı</label>
        </div>
        <div className="col-md-8 mg-t-5 mg-md-t-0">
          <input className="form-control" value={this.state.brand.makename}  disabled = {(!this.state.brand.makename)? "disabled" : ""}  placeholder="Önce Kurum Seçiniz" type="text" />
        </div>
      </div>

      <div className="row row-xs align-items-center mg-b-20">
        <div className="col-md-4">
          <label className="mg-b-0">Kurum Logosu</label>
        </div>
        <div className="col-md-8 mg-t-5 mg-md-t-0">
          <input className="form-control" value={this.state.brand.makeImage}  disabled = {(!this.state.brand.makeImage)? "disabled" : ""}  placeholder="Önce Kurum Seçiniz" type="text" />
        </div>
      </div>
        
        
      <div>
          <input type="file" accept="image/*" onChange={this.onSelectFile} />
        </div>
        {this.state.src && (
          <ReactCrop
            src={this.state.src}
            crop={this.state.crop}
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
        {this.state.croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: "100%" }} src={this.state.croppedImageUrl} />
        )}
        
        </div>
      </div>

    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KiNewBrand;
