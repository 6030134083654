import React, { button } from "react";
import { Link } from "react-router-dom";
import Ustkisim from "../inc/Header";
import { seo } from "../../helpers";
import { config } from "../../config";
import Moment from "moment";
import "moment/locale/tr";
import MaterialTable from "material-table";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Loader from 'react-loaders'
import cogoToast from 'cogo-toast';
import PageHeader from "../../bilesenler/PageHeader"

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import moment from "moment";
 const tableRef = React.createRef();
class ProductsCategorys extends React.Component {
  constructor(props,context) {
    super(props);
    const datePickerId = moment(new Date()).format("YYYY-MM-DD[T]HH:mm") ;
 
    this.state = { edit: {status : false , id : '' , topic : '' , start_time: '' , duration : '40' , password : ''}, create : {status : false } , formdata : {topic : '' , start_time: '' , duration : '40' , password : ''} , mindate : datePickerId ,  load_detail: false };
    this.state.BASE_URL = config().BASE_URL;

    this.handleChange = this.handleChange.bind(this);
    this.UpdateChange = this.UpdateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.UpdateSubmit = this.UpdateSubmit.bind(this);
    
  }



  componentDidMount() {
    seo({
      title: "Ürün Kategorileri | Atlas",
      metaDescription: "Atlas Ana Ekranı",
    });
  }



  create_meet = () => 
  {
    this.setState({create: {status : true}}) ;
    this.setState({edit: {id : "" , duration : "" , password : "" , topic : "" , start_time : "" , status : false}}) ;
  }


  RemoteData = () => {
   
    let  promisem;

    const deleteZoomMeeting = (id) => { 

			fetch(this.state.BASE_URL + '/api/delete_meeting/' + id , {
                                method : 'DELETE'
                                   }).then(response => { 
					if(response.status === 200) {
            
            

              tableRef.current && tableRef.current.onQueryChange() ;
                               
                cogoToast.success('Toplantı Başarılı Bir Şekilde Silindi',{position : 'top-right'});             	
					
          } 
          else 
          {
            cogoToast.warn('Toplantı Silinemedi Hata Oluştu',{position : 'top-right'});    
          }
			 });
		
  }
  

  const getZoomMeeting = (id) => { 


    this.setState({create: {status : false}}) ;
    this.setState({
      load_detail: true,
    });
                
    fetch(this.state.BASE_URL + '/api/getSingleMeet/' + id )
    .then(response => {
        return response.json();
    }).then(sonuc => {
      console.log(sonuc);
           if(sonuc.success)
           {
            const datePickerId = moment(sonuc.start_time).format("YYYY-MM-DD[T]HH:mm") ;

            this.setState({edit: {id : sonuc.id , duration : sonuc.duration , password : sonuc.password , topic : sonuc.topic , start_time : datePickerId , status : true}}) ;
           }
           
           console.log(this.state.edit);
           
          this.setState({
            load_detail: false,
          });
     
    });

  
}


    return (
      <MaterialTable
        title="Tüm Ürün Kategorileri"

        tableRef={tableRef}
        actions={[
          rowData => ({
            icon: 'edit',
            tooltip: 'Düzenle',
            onClick: (event, rowData) =>     getZoomMeeting(rowData.id) ,
            disabled: rowData.is_admin == true
          }),
          rowData => ({
            icon: 'delete',
            tooltip: 'Toplantıyı Sil',
            onClick: (event, rowData) =>   confirmAlert({
              title: 'Toplantıyı Silinecek',
              message: 'Toplantıyı Hesabını Silmek İstediğinize Emin misiniz?',
              buttons: [
                {
                  label: 'Hayır',
                  onClick: () => {
                  return true ;
                
                   } 
                }
                ,
                {
                  label: 'Evet',
                  onClick: () => {
                    deleteZoomMeeting(rowData.id);
                    console.log(rowData.id);
                   } //tableRef.current && tableRef.current.onQueryChange() ; 
                }
                
              
              ]
            }),
            disabled: rowData.is_admin == true
          })
         
        ]}

        options={{
            grouping: true,
            actionsColumnIndex: -1,
            search: false,
            filtering: false,
            pageSizeOptions: [1,2,3,4,5,50, 100, 200],
            pageSize : 50 ,
            
          headerStyle: {
       
            height: "25px !important",
            padding: "5px 20px"
          }
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{count} sonuçtan {from}-{to} arası sonuçlar",
            labelRowsSelect: "data göster ",
          },
          toolbar: {
            nRowsSelected: "{0} row(s) selected",
          },
          header: {
            actions: "İşlem",
          },
          grouping : {
            placeholder : 'Gruplamak İstediğiniz Tablo Başlıklarını Buraya Sürükleyin',
            groupedBy : "Gruplanan : "
          },
          body: {
            emptyDataSourceMessage: "Gösterilecek Data Bulunamadı",
            filterRow: {
              filterTooltip: "Filtre",
            },
          },
        }}
        columns={[
          {
            title: "Sıralama",
            field: "siralama"
          },
          {
            title: "Kategori Sembolü",
            field: "icon",
            render: (rowData) => (

              <i className={rowData.icon} />
            )
          },
          { title: "Kategori adı ", field: "name" },
          {
            title: "Maya'da",
            field: "maya",
            render: (rowData) =>
            rowData.maya == 1 ? <span class="badge badge-pill badge-success-light">Gösteriliyor</span> : <span class="badge badge-pill badge-danger-light">Gösterilmiyor</span>
          },
          {
            title: "Web'de",
            field: "web",
            render: (rowData) =>
            rowData.web == 1 ? <span class="badge badge-pill badge-success-light">Gösteriliyor</span> : <span class="badge badge-pill badge-danger-light">Gösterilmiyor</span>
          },
          {
            title: "App'de",
            field: "app",
            render: (rowData) =>
            rowData.app == 1 ? <span class="badge badge-pill badge-success-light">Gösteriliyor</span> : <span class="badge badge-pill badge-danger-light">Gösterilmiyor</span>
          },
          {
            title: "Kolayiptal'de",
            field: "kolayiptal",
            render: (rowData) =>
            rowData.kolayiptal == 1 ? <span class="badge badge-pill badge-success-light">Gösteriliyor</span> : <span class="badge badge-pill badge-danger-light">Gösterilmiyor</span>
          },
          {
            title: "Durumu",
            field: "status",
            render: (rowData) =>
            rowData.status == 1 ? <span class="badge badge-pill badge-success-light">Aktif</span> : <span class="badge badge-pill badge-danger-light">Pasif</span>
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
 
            if (!query.orderDirection) {
              query.orderBy = { field: "siralama" };
              query.orderDirection = "asc";
            }
            let url = config().BASE_URL + "/api/productsCategorys?";
            url += "limit=" + query.pageSize;
            url += "&order[" + query.orderBy.field + "]=" + query.orderDirection;
            url += "&page=" + (query.page + 1);
            fetch(url)
              .then((response) => response.json())
              .then((result) => {

                if(result.error)
                {
                  cogoToast.warn('Webservis Kaynaklı Sorun Oluşmuştur, Lütfen Sistem Yöneticisine Bilgi Veriniz...',{position : 'bottom-right'});

                }
            
            
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                });
              });
          })
        }
      />
    );
  };



  handleChange = (e) => {
        console.log(e.target.name);

        var someProperty = this.state.formdata ;
        someProperty[e.target.name] = e.target.value;
       
        this.setState({someProperty});
      }
      UpdateChange = (e) => {
      
        var someProperty = this.state.edit ;
        someProperty[e.target.name] = e.target.value;
       
        this.setState({someProperty});
      }
      

      handleSubmit = (e) => {
          e.preventDefault();
 
          this.setState({
            load_detail: true,
          });


          fetch(this.state.BASE_URL + "/api/create_meeting", {
              method: 'POST',
              body: JSON.stringify(this.state.formdata)
            })
            .then((response) => {
              return response.json();
            })
            .then((result) => {
              this.setState({
                load_detail: false,
              });
              if(result.success) {
            
            
                this.setState({
                  create: {status : false},
                 
                });
                tableRef.current && tableRef.current.onQueryChange() ;
                                 
                  cogoToast.success('Toplantı Başarılı Bir Şekilde Oluşturuldu',{position : 'top-right'});             	
            
            } 
            else 
            {
              cogoToast.warn('Toplantı Oluşturulurken Hata Oluştu',{position : 'top-right'});    
            }


             
            });
      
      }


      UpdateSubmit = (e) => {
        e.preventDefault();

        this.setState({
          load_detail: true,
        });


        fetch(this.state.BASE_URL + "/api/edit_meeting", {
            method: 'POST',
            body: JSON.stringify(this.state.edit)
          })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              load_detail: false,
            });
            if(result.success) {
          
          
              this.setState({
                edit: {status : false},
               
              });
              tableRef.current && tableRef.current.onQueryChange() ;
                               
                cogoToast.success('Toplantı Başarılı Bir Şekilde Güncellendi',{position : 'top-right'});             	
          
          } 
          else 
          {
            cogoToast.warn('Toplantı Güncellenirken Hata Oluştu',{position : 'top-right'});    
          }


           
          });
    
    }





  render() {
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="../assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                   Tüm Ürün Kategorileri
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Atlas</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Tüm Ürün Kategorileri
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                      onClick={this.create_meet}
                     
                    >
                      <i className="fe fe-download mr-2" /> Yeni Kategori Ekle
                    </button>
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                    <div className="card-body pt-0">{this.RemoteData()}</div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card custom-card">
                    <BlockUi
                    loader={
                    <div className="loader-img-page-div">
                      <img src="../assets/img/loader.svg" />
                      <div className="LoaderText-div">Lütfen Bekleyiniz...</div>
                    </div>}
                      tag="div"
                      className="card-body"
                      blocking={this.state.load_detail}
                    >

                  {this.state.create.status == true ?  (
                        <div>
                          <div class="card-header pb-0 border-bottom-0">
                            <label class="main-content-label mb-2 pt-1">
                              Yeni Toplantı Ekle
                            </label>
                            <p class="tx-12 mb-0 text-muted">
                              Yeni Toplantı Eklemek İçin Formu Doldurun
                            </p>
                          </div>
                          <div class="card-body">
                            

                    
                  

                          <div class="d-flex flex-column">
                          <form
                    id="main-login"
                    action={this.state.BASE_URL + "/api/create_meeting"}
                    method="post"
                    onSubmit={this.handleSubmit}> 
											<div class="form-group">
												<input class="form-control" name="topic"   defaultValue={this.state.formdata.topic || ''}   onBlur={this.handleChange}  placeholder="Toplantı Başlığı" type="text" />
											</div>

											<div class="form-group">
												<input class="form-control" name="start_time"  type="datetime-local" id="meeting-time"
                     min={this.state.mindate}   defaultValue={this.state.formdata.start_time || ''}  onBlur={this.handleChange}  placeholder="Başlama Zamanı"  />
											</div>
											<div class="form-group">

                      <div class="input-group mb-3">
													<input name="duration" defaultValue={this.state.formdata.duration || '40'} type="number" max="40" class="form-control"  onBlur={this.handleChange} placeholder="Toplantı Süresi" />
													<div class="input-group-append">
														<span class="input-group-text" id="basic-addon2">Dakika</span>
													</div>
												</div>
                </div>
                      <div class="form-group">
												<input class="form-control" name="password" defaultValue={this.state.formdata.password || ''}  onBlur={this.handleChange}  placeholder="Toplantı Şifresi" type="text" />
											</div> 
                     
											<button class="btn ripple btn-main-primary btn-block" disabled={!this.state.formdata.topic || !this.state.formdata.start_time || !this.state.formdata.duration || !this.state.formdata.password }  >Toplantıyı Ekle</button>
                      </form>
										</div>

                      

                          </div>
                        </div>
                      ) : (<></>)} 


                      {!this.state.edit.id ? (
                        <div class="bg-white box-shadow custom-card card">
                          <div class="alert text-center fade show p-3">
                      
                            <i class="fe fe-download-cloud fs-50 text-primary"></i>
                            <h5 class="mt-2 mb-1">Henüz Seçim Yapmadınız!</h5>
                            <p class="mb-3 mb-3 tx-inverse">
                              Lütfen Önce Tablodan Bir Kayıt Seçiniz
                            </p>
                          </div>
                        </div>
                      ) : (

                        <div>
                        <div class="card-header pb-0 border-bottom-0">
                          <label class="main-content-label mb-2 pt-1">
                            Toplantıyı Düzenle
                          </label>
                          <p class="tx-12 mb-0 text-muted">
                          Toplantıyı Düzenlemek İçin Formu Doldurun
                          </p>
                        </div>
                        <div class="card-body">
                        <div class="d-flex flex-column">
                        <form
                  id="main-login"
                  action={this.state.BASE_URL + "/api/edit_meeting"}
                  method="post"
                  onSubmit={this.UpdateSubmit}> 
                    <div class="form-group">
                      <input class="form-control" name="topic"   defaultValue={this.state.edit.topic || ''}   onBlur={this.UpdateChange}  placeholder="Toplantı Başlığı" type="text" />
                    </div>

                    <div class="form-group">
                      <input class="form-control" name="start_time"  type="datetime-local" id="meeting-time"
                   min={this.state.mindate}   defaultValue={this.state.edit.start_time || ''}  onBlur={this.UpdateChange}  placeholder="Başlama Zamanı"  />
                    </div>
                    <div class="form-group">

                    <div class="input-group mb-3">
                        <input name="duration" defaultValue={this.state.edit.duration || '40'} type="number" max="40" class="form-control"  onBlur={this.UpdateChange} placeholder="Toplantı Süresi" />
                        <div class="input-group-append">
                          <span class="input-group-text" id="basic-addon2">Dakika</span>
                        </div>
                      </div>
              </div>
                    <div class="form-group">
                      <input class="form-control" name="password" defaultValue={this.state.edit.password || ''}  onBlur={this.UpdateChange}  placeholder="Toplantı Şifresi" type="text" />
                    </div> 
                   
                    <button class="btn ripple btn-main-primary btn-block" disabled={!this.state.edit.topic || !this.state.edit.start_time || !this.state.edit.duration || !this.state.edit.password }  >Toplantıyı Güncelle</button>
                    </form>
                  </div>
                  </div>
                  </div>
                      )}



                    </BlockUi>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsCategorys;
