import React, { Component } from 'react';
class Footer extends Component {
  render() {
    return (
     <></>
      
    )
  }
}
 
 
export default Footer;