import React , {button , useState} from "react"; 
import { Link } from "react-router-dom";
import Ustkisim from "../inc/Header";
import Footer from "../inc/Footer";
import { seo } from "../../helpers";
import { config } from "../../config";
import Moment from "moment";
import "moment/locale/tr"; 
import MaterialTable from 'material-table';
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Loader from 'react-loaders'

class Passive extends React.Component {
  constructor(props) {
    super(props);
    this.state = { faks: {} ,  load_detail : false };
    this.state.BASE_URL = config().BASE_URL;
  
  
  }

  componentDidMount() {
 

    seo({
      title: "Kolay İptal Kullanıcıları | Maya",
      metaDescription: "Maya Ana Ekranı",
    });
  }

  getfaks = (id) => {
    
    this.setState({
      load_detail : true,
    });
    fetch(this.state.BASE_URL + "/api/get_single_faks/"+id)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      
      this.setState({
        faks: result,
        load_detail : false,
      });
    });
  }

  RemoteData = () => {

   
    

    return (
      <MaterialTable
        title="Tüm Üyeler"
        options={{
          pageSizeOptions: [10, 100, 200],
          pageSize : 10 ,
          search: true,
          filtering: false,
          headerStyle: {
       
            height: "25px !important",
            padding: "5px 20px"
          }
        
        }}

              
            localization={{
              pagination: {
                  labelDisplayedRows: '{count} sonuçtan {from}-{to} arası sonuçlar',
                  labelRowsSelect : "data göster "
              },
              toolbar: {
                  nRowsSelected: '{0} row(s) selected',
                  searchPlaceholder : "Arama",
                  searchTooltip : "Arama"
              },
              header: {
                  actions: 'Actions'
              },
              body: {
                  emptyDataSourceMessage: 'Gösterilecek Data Bulunamadı',
                  filterRow: {
                      filterTooltip: 'Filtre'
                  }
              }
              

          }}

        columns={[
          { title: 'Tc Kimlik', field: 'tckimlik' },
          {
            title: 'İsim Soyisim',
            field: 'first_name',
            render: rowData => (
              rowData.first_name +" "+rowData.last_name
            ),
          },
          { title: 'Telefon Numarası', field: 'username' },
          {
            title: 'Üyelik Tarihi',
            field: 'created',
            render: rowData => (
              Moment(rowData.created).format(
                "D MMM YYYY hh:mm"
              )
            ),
          },
          {
            title: "Durumu",
            field: "banned",
            render: (rowData) =>
            rowData.banned == "0" ? <span className="badge badge-pill badge-success-light">Aktif</span> : <span className="badge badge-pill badge-danger-light">Pasif</span>
          },
          {
            title: 'Son Giriş Tarihi',
            field: 'last_login',
            render: rowData => (
              Moment(rowData.last_login).format(
                "D MMM YYYY hh:mm"
              )
            ),
          },
          {
            title: 'İşlem', 
            field: 'id',
            render: rowData => (
              
              <button className="btn btn-primary btn-sm" onClick={(e) => this.getfaks(rowData.id)}  >
              Göster
            </button>
          
            ),
          },

        ]}
        data={query =>
          new Promise((resolve, reject) => {
            console.log(query);

              if(!query.orderDirection)
              {
                query.orderBy = {field : 'id' };
                query.orderDirection = 'asc';
              }
        
            let url = config().BASE_URL + '/api/ki_users?'
            url += 'limit=' + query.pageSize
            url += '&search=' + query.search
            url += '&order['+query.orderBy.field+']=' + query.orderDirection
            url += '&page=' + (query.page + 1)
            fetch(url)
              .then(response => response.json())
              .then(result => {
                resolve({
                  data: result.data,
                  page: result.page - 1,
                  totalCount: result.total,
                })
              })
          })
        }
      />
    )
  }

  render() {

   
    
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="../assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    Kolay İptal Kullanıcıları
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Maya</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Kolay İptal Kullanıcıları
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-download mr-2" /> Faks Gönder
                    </button>
               
                  </div>
                </div>
              </div>
              {/* End Page Header */}
              {/* Row */}
              <div className="row row-sm">
                <div className="col-md-12 col-xl-9">
                  <div className="card custom-card overflow-hidden">
                   
                    <div className="card-body pt-0">
                      {this.RemoteData()}
                  
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                  <div className="card custom-card">
                
                    <BlockUi
                    loader={
                    <div className="loader-img-page-div">
                      <img src="../assets/img/loader.svg" />
                      <div className="LoaderText-div">Lütfen Bekleyiniz...</div>
                    </div>}
                      tag="div"
                      className="card-body"
                      blocking={this.state.load_detail}
                    >

                      
            
                        {!this.state.faks.id ? (
                         <div className="bg-white box-shadow custom-card card">
                         <div className="alert text-center fade show p-3">
                           <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                             <span aria-hidden="true">×</span>
                           </button>
                           <i className="fe fe-download-cloud fs-50 text-primary"></i>
                           <h5 className="mt-2 mb-1">Henüz Seçim Yapmadınız!</h5>
                           <p className="mb-3 mb-3 tx-inverse">Lütfen Önce Tablodan Bir Kayıt Seçiniz</p>
                         
                         </div>
                       </div>
                        ) : (
                          <div>
                            <div className="card-header pb-0 border-bottom-0">
										<label className="main-content-label mb-2 pt-1">Faks Detayları</label>
										<p className="tx-12 mb-0 text-muted">Faks Detaylarına Aşağıdan Ulaşabilirsiniz.</p>
									</div>
                  <div className="card-body">
                          <ul className="visitor mb-0 d-block users-images list-unstyled list-unstyled-border">
                          <li className="media d-flex mb-3 mt-0 pt-0">
                          
                            <div className="media-body mb-1">
                              <div className="float-right">{this.state.faks.created_at ?  Moment(this.state.faks.created_at).format("d MMM YYYY hh:mm") : '' }</div>
                              <h5 className="media-title tx-15 mb-0">Gönderme Tarihi : </h5>
                            
                            </div>
                          </li>
                          <li className="media d-flex mb-3">
                
                            <div className="media-body mb-1">
                              <div className="float-right">{this.state.faks.sender}</div>
                              <h5 className="media-title tx-15 mb-0"> Gönderen : </h5>
                            
                            </div>
                          </li>
                          <li className="media d-flex mb-3">
                        
                            <div className="media-body mb-1">
                              <div className="float-right"><a href={this.state.faks.attachment}  target="_blank" className="btn btn-sm btn-success">Belgeyi İndir</a></div>
                              <h5 className="media-title tx-15 mb-0">Belge</h5>
                             
                            </div>
                          </li>
                         
                        </ul>
                        </div>
                    </div>
                        )}
                      
                    </BlockUi>
                  </div>
                </div>
          </div>
       </div>
          </div>
        </div>
     
      </div>
    );
  }
}

export default Passive;
 