import React , {button , useState , useCallback , useReducer} from "react"; 

import { Link } from "react-router-dom";
import Ustkisim from "./inc/Header";
import { seo } from "../helpers";
import { config } from "../config";
import Moment from "moment";
import "moment/locale/tr"; 
import "react-block-ui/style.css";
import 'react-responsive-modal/styles.css';
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Loader from 'react-loaders'
import cogoToast from 'cogo-toast';
import InputMask from "react-input-mask";

import { Modal } from 'react-responsive-modal';

const tableRef = React.createRef();
class Talep extends React.Component {
  constructor(props) {
    super(props);
   
    this.state = {fax : false , documents: []  , faxdetail : {} ,  formdata : {fax_number : '' , key : this.props.talepid},  load_detail : false , load_detail_fax : false ,pdfmodal : false, talepid : this.props.talepid ,   talep: {first_name : "" ,las_tname : "" } , card : {
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
      }};
    this.state.BASE_URL = config().BASE_URL;
    this.handleChange = this.handleChange.bind(this);
  

  }


  componentDidMount() {
 
    fetch(this.state.BASE_URL + "/api/get_single_talep/" + this.state.talepid)
    .then(response => {
      return response.json();
    }).then(result => {
 
      this.setState({
        talep:result , 
        formdata : {fax_number : result.brand_detail.fax_number , key : this.state.talepid}
      });


      if(result.faxid)
      {
        fetch(this.state.BASE_URL + "/api/sender_get_single_faks/" + result.faxid)
        .then((response) => {
          return response.json();
        })
        .then((faxdetail) => {
          console.log(faxdetail);
          this.setState({
            faxdetail: faxdetail,
          });
        });

      }


      seo({
        title:  this.state.talep.name + "    " + this.state.talep.lastname,
        metaDescription: "Maya Ana Ekranı",
      });

    });

    this.setState({
        load_detail : true,
      });
      fetch(this.state.BASE_URL + "/api/get_talep_docs/" + this.state.talepid)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        
        this.setState({
          documents: result,
          load_detail : false,
        });
      });
 
  }
  statusKontrol = (id) => {
    var durum = {};
    if (id == "sent") {
      durum = {
        title: "Başarıyla Gönderildi",
        color: "card bg-success custom-card card-box",
        error : false
      };
    } else if (id == "waiting") {
      durum = {
        title: "Gönderilmeye Hazırlanıyor",
        color: "card bg-info custom-card card-box",
        error : false
      };
    } else if (id == "error") {
      durum = {
        title: "Gönderim Başarısız",
        color: "card bg-danger custom-card card-box",
        error : true
      };
    } else {
      durum = {
        title: "Bilinmiyor",
        color: "card bg-danger custom-card card-box",
        error : true
      };
    }
    return durum;
  };
  handleChange = (e) => {
    console.log(e);
      var someProperty = this.state.formdata ;
    someProperty[e.target.name] = e.target.value;
    this.setState({someProperty});
  }

  send_fax_button = () => 
  {
    this.setState({fax : true}) ;

  }


  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({
      load_detail_fax: true,
    });


    fetch(this.state.BASE_URL + "/api/send_faks", {
        method: 'POST',
        body: JSON.stringify(this.state.formdata)
      })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          load_detail_fax: false,
        });
        if(result.success) {
                            
            cogoToast.success('Faks Başarılı Bir Şekilde Gönderildi',{position : 'bottom-center'});             	
      
      } 
      else 
      {
        cogoToast.warn('Faks Gönderilirken Hata Oluştu',{position : 'bottom-center'});    
      }


       
      }).catch((err) => {
        this.setState({
          load_detail_fax: false,
        });
        cogoToast.warn('Faks Gönderilirken Hata Oluştu , '+err,{position : 'bottom-center'});  
      });

}

  render() {

  

    const onOpenModal = () => 
    {
     //   this.setState({
      //      pdfmodal: true
      //    });
    }
    const onCloseModal = () => 
    {
        this.setState({
            pdfmodal: false
          });
    }


    


   
      
    return (
      <div>
        <Ustkisim />

        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div className="inner-body">
              {/* Page Header */}
              <div className="page-header">
                <div>
                  <h2 className="main-content-title tx-24 mg-b-5">
                    {this.state.talep.name + "    " + this.state.talep.lastname}
                  </h2>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Kolay İptal</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {this.state.talep.name +
                        "    " +
                        this.state.talep.lastname}
                    </li>
                  </ol>
                </div>
                <div className="d-flex">
                  <div className="justify-content-center">
                    <button
                      type="button"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-download mr-2" /> Müşteriye Sms Gönder
                    </button>
                    <button
                      type="button"
                      className="btn btn-white btn-icon-text my-2 mr-2"
                    >
                      <i className="fe fe-filter mr-2" /> Talebi Sil
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary my-2 btn-icon-text"
                    >
                      <i className="fe fe-download-cloud mr-2" /> Durum Değiştir
                    </button>
                  </div>
                </div>
              </div>
         
              <div className="row row-sm">
                <div className="col-xxl-4 col-xl-12 col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-header border-bottom-0 mb-0">
                      <label className="main-content-label mb-0">
                        Kurum Detayı
                      </label>
                    </div>
                    <div className="card-body pt-1">
                      <div className="d-flex">
                        <img
                          src={
                            this.state.talep.brand_detail
                              ? this.state.talep.brand_detail.makeImage
                              : ""
                          }
                          className="wd-40 ht-40 mr-3 my-auto"
                          alt=""
                        />
                        <div className>
                          <div className="d-flex my-auto">
                            <h2 className="mt-1 mb-0">
                              {this.state.talep.brand_detail
                                ? this.state.talep.brand_detail.makename
                                : ""}
                            </h2>
                          </div>
                        </div>
                      </div>

                      <ul className="list-group mt-4 tx-13">
                        {this.state.talep.created_date ? (
                          <li className="list-group-item pd-x-0 d-flex justify-content-between">
                            <div className="d-flex">
                              <span className="my-auto font-weight-semibold tx-15">
                                Oluşturma Tarihi
                              </span>
                            </div>
                            <span className="font-weight-semibold my-auto  tx-15">
                              {this.state.talep.created_date}{" "}
                            </span>
                          </li>
                        ) : (
                          <></>
                        )}
                        {this.state.talep.account_id ? (
                          <li className="list-group-item pd-x-0 d-flex justify-content-between">
                            <div className="d-flex">
                              <span className="my-auto font-weight-semibold tx-15">
                                Müşteri No
                              </span>
                            </div>
                            <span className="font-weight-semibold my-auto  tx-15">
                              {this.state.talep.account_id}{" "}
                            </span>
                          </li>
                        ) : (
                          <></>
                        )}
                        {this.state.talep.card_number ? (
                          <li className="list-group-item pd-x-0 d-flex justify-content-between">
                            <div className="d-flex">
                              <span className="my-auto font-weight-semibold tx-15">
                                Kart No
                              </span>
                            </div>
                            <span className="font-weight-semibold my-auto  tx-15">
                              {this.state.talep.card_number}{" "}
                            </span>
                          </li>
                        ) : (
                          <></>
                        )}

                        {this.state.talep.tckimlik ? (
                          <li className="list-group-item pd-x-0 d-flex justify-content-between">
                            <div className="d-flex">
                              <span className="my-auto font-weight-semibold tx-15">
                                Tc Kimlik No
                              </span>
                            </div>
                            <span className="font-weight-semibold my-auto  tx-15">
                              {this.state.talep.tckimlik}{" "}
                            </span>
                          </li>
                        ) : (
                          <></>
                        )}

                        {this.state.talep.phone ? (
                          <li className="list-group-item pd-x-0 d-flex justify-content-between">
                            <div className="d-flex">
                              <span className="my-auto font-weight-semibold tx-15">
                                Telefon No
                              </span>
                            </div>
                            <span className="font-weight-semibold my-auto  tx-15">
                              {this.state.talep.phone}{" "}
                            </span>
                          </li>
                        ) : (
                          <></>
                        )}

                        {this.state.talep.why ? (
                          <li className="list-group-item pd-x-0 d-flex justify-content-between">
                            <div className="d-flex">
                              <span className="my-auto font-weight-semibold tx-15">
                                İptal Nedeni
                              </span>
                            </div>
                            <span className="font-weight-semibold my-auto  tx-15">
                              {this.state.talep.why}{" "}
                            </span>
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>

                      <div className="row mt-4">
                        <div className="col-6">
                          <a
                            href={
                              "https://www.abonesepeti.com/api/faks_pdf_get/" +
                              this.state.talepid
                            }
                            target="_blank"
                            className="btn btn-block btn-primary"
                          >
                            Pdf'indir{" "}
                          </a>
                        </div>
                        <div className="col-6">
                          <button
                            className="btn btn-block btn-success"
                            onClick={this.send_fax_button}
                          >
                            Faks Gönder
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card custom-card">
                    <BlockUi
                      loader={
                        <div className="loader-img-page-div">
                          <img src="../assets/img/loader.svg" />
                          <div className="LoaderText-div">
                            Lütfen Bekleyiniz...
                          </div>
                        </div>
                      }
                      tag="div"
                      className="card-body"
                      blocking={this.state.load_detail_fax}
                    >
                      {this.state.fax == true ? (
                        <div>
                          <div className="card-header pb-0 border-bottom-0">
                            <label className="main-content-label mb-2 pt-1">
                              {this.state.talep.brand_detail.makename} Kurumuna
                              İlet
                            </label>
                            <p className="tx-12 mb-0 text-muted">
                              {this.state.talep.brand_detail.makename}{" "}
                              Kurumunuda Faksı Göndermek İçin Lütfen Faks
                              Numarasını Yazınız ve Gönder Butonuna Tıklayınız
                            </p>
                          </div>
                          <div className="card-body">
                            <div className="d-flex flex-column">
                              <form
                                id="main-login"
                                action={
                                  this.state.BASE_URL + "/api/create_meeting"
                                }
                                method="post"
                                onSubmit={this.handleSubmit}
                              >
                                <div className="form-group">
                                  <InputMask
                                    mask="+\90 999 999 99 99"
                                    className="form-control pd-r-80"
                                    value={this.state.formdata.fax_number}
                                    placeholder="Faks Numarası"
                                    name="fax_number"
                                    onChange={this.handleChange}
                                  />
                                </div>

                                <button className="btn ripple btn-main-primary btn-block">
                                  Faksı Şimdi Gönder
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </BlockUi>
                  </div>
                </div>
                {this.state.talep.status  == 1  ?
                <div className="col-xxl-8 col-xl-12 col-lg-12 col-md-12">
                  <div className="card custom-card pt-4">
                    <div className="row row-sm pl-4 pr-4">
                      <div className="col-lg-4 col-xl-4">
                        <div className="card border custom-card">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className>
                                <p className="text-muted mb-1 text-center">
                                  İptal Dilekçesi
                                </p>
                                <img src={this.state.talep.sozlesme} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-xl-4">
                        <div className="card border custom-card">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className>
                                <p className="text-muted mb-1 text-center">
                                  Kimlik Ön
                                </p>
                                <img src={this.state.talep.kimlik_on} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-xl-4">
                        <div className="card border custom-card">
                          <div className="card-body">
                            <div className="d-flex">
                              <div className>
                                <p className="text-muted mb-1 text-center">
                                  Kimlik Arka
                                </p>
                                <img src={this.state.talep.kimlik_arka} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 : 
                 this.state.faxdetail.recipients ? 
                 <div className="col-xxl-8 col-xl-12 col-lg-12 col-md-12">
                  <div className="row row-sm ">
                    {" "}
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                      {" "}
                      <div className= {this.statusKontrol(this.state.faxdetail.recipients[0].state).color}>
                        {" "}
                        <div className="card-body p-4">
                          {" "}
                          <div className="row align-items-center">
                            {" "}
                            <div className="offset-xl-3 offset-sm-6 col-xl-8 col-sm-6 col-12 img-bg ">
                              {" "}
                              <h4 className="d-flex  mb-3">
                                {" "}
                                <span className="font-weight-bold text-white ">
                                  Faks {this.statusKontrol(this.state.faxdetail.recipients[0].state).title}
                                </span>{" "}
                              </h4>{" "}
                              <p className="tx-white-7 mb-1">
                              <b> Faks {Moment(this.state.faxdetail.created_at).format("DD MMM YYYY HH:mm")} </b> Tarihinde  {this.statusKontrol(this.state.faxdetail.recipients[0].state).title}
                               
                              </p>
                            </div>{" "}
                            <img
                              src="../../assets/img/pngs/work3.png"
                              alt="user-img"
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div></div>: <></>
                 }
              </div>
              {/* Row End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Talep;
 