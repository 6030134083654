import React from 'react'

export const CheckBox = props => {
    return (
      <label className="ckbox mb-2">
       <input key={props.id} onChange={props.handleCheckChieldElement} type="checkbox" checked={props.isChecked}  value={props.value}  /> 
       <span> {props.name} {props.isChecked} </span>
      
      </label>
    )
}

export default CheckBox