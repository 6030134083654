import React, { button } from "react";
import { Link, withRouter } from "react-router-dom";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader";
import Select from "react-select";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import lang from "suneditor/src/lang";
import EasyEdit, { Types } from "react-easy-edit";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InputTags } from "react-bootstrap-tagsinput";
import Switch from "react-switch";
import SelectSearch from "react-select-search";
import CurrencyInput from 'react-currency-input-field';
import Dropzone from "react-dropzone";
class addProduct extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      body: "",
      tags: [],
      content: [],
      promos: [],
      vas: [],
      categorys: {},
      brands: [],
      inputs: [],
      features: [],
      products_category: "",
      payment_after_code : "",
      link: "",
      external_link: "",
      normal: "",
      payment_after_action : '',
      indirimli: "",
      title: "",
      file: {},
      resim : '',
      form: {},
      taksit : 0 ,
      addBlockLoad: false,
      isDraft: true,
      onecikan: false,
      producttype: 0,
      tahsilat: false,
      is_address: true,
      maya: true,
      web: true,
      app: false,
      resim: "",
      is_cargo : false,
    };
    this.onDrop = (file) => {
      this.setState({
        file: file,
      }, () => {
        this.addImage();
    });
     
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.degerchange = this.degerchange.bind(this);
    this.setBrand = this.setBrand.bind(this);
    this.linkVer = this.linkVer.bind(this);
    this.icerikver = this.icerikver.bind(this);
    this.handleChangeCheck = this.handleChangeCheck.bind(this);
    this.handleChangeOnecikan = this.handleChangeOnecikan.bind(this);
    this.handleChangeproducttype = this.handleChangeproducttype.bind(this);
    this.handleChangetahsilat = this.handleChangetahsilat.bind(this);
    this.handleChangeaddress = this.handleChangeaddress.bind(this);
    
    this.setValue = this.setValue.bind(this);
    

  
    this.handleChangeMaya = this.handleChangeMaya.bind(this);
    this.handleChangeweb = this.handleChangeweb.bind(this);
    this.handleChangeapp = this.handleChangeapp.bind(this);

  
    this.handleChangeprice = this.handleChangeprice.bind(this);
    this.handleChangeis_cargo = this.handleChangeis_cargo.bind(this);
  }

  componentDidMount() {
    fetch(this.state.BASE_URL + "/api/products_categorys_all")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          categorys: result,
        });

        fetch(this.state.BASE_URL + "/api/promoimages")
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              promos: result,
            });
          });
      });
  }
  handleOnChange(event) {
    this.setState({
      short_description: event.target.value,
    });
  }
  handleChangeCheck(isDraft) {
    this.setState({ isDraft });
  }

  handleChangeOnecikan(onecikan) {
    this.setState({ onecikan });
  }
  handleChangeproducttype(producttype) {
    this.setState({ producttype });

    this.setState({ tahsilat : false , taksit : 0 , external_link : ''  });

  }
  handleChangetahsilat(tahsilat) {
    this.setState({ tahsilat });
  }

  handleChangeaddress(is_address) {
    this.setState({ is_address });
  }
  


  addImage = () => {

    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/products", {
       method: 'POST',
       body: data
         }).then((response) => {
          return response.json();
        })
        .then((result) => {



          if (!result.success) {
            cogoToast.warn(
              "Resim Yüklenirken Sorun Oluştu.",
              { position: "top-right" }
            );
          }
          else 
          {
            cogoToast.success(
              "Resim Başarılı Bir Şekilde Yüklendi.",
              { position: "top-right" }
            );
    
            this.setState({
              resim : result.url
            });
            
          }
          this.setState({
            loadImage: false,
          });
            console.log(result);
        });
    }
  setValue(event) {
    var value = event.target.value;
    var name = event.target.name;
    const stateim = this.state;
    stateim[name] = value;
    this.setState({ stateim });
  }



  handleChangeweb(web) {
    this.setState({ web: web });
  }
  handleChangeapp(app) {
    this.setState({ app: app });
  }
  

  handleChangeMaya(maya) {
    this.setState({ maya: maya });
  }
  handleChangeis_cargo(is_cargo) {
    this.setState({ is_cargo: is_cargo });
  }
  getInputs(isDraft) {
    fetch(this.state.BASE_URL + "/api/ProductsFeatures/" + isDraft)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          features: result,
        });
      });
  }

  getBrands(brandid) {
    fetch(this.state.BASE_URL + "/api/getCategoryBrands/" + brandid)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          brands: result.brands,
          inputs: result.inputs,
        });
      });
  }

  handleMultiChange(option) {
    this.getBrands(option.id);
    this.getInputs(option.id);

    this.setState((state) => {
      return {
        products_category: option.id,
      };
    });
  }

  addContent(verim) {
    const stateim = this.state.content;
    const tip = verim;
    if (verim == "list") {
      stateim.push({ tip: tip, data: [{ value: "" }] });
    } else {
      stateim.push({ tip: tip, value: "" });
    }

    this.setState({ stateim });
    console.log(tip);
  }

  addvas() {
    const stateim = this.state.vas;

    stateim.push({ image: "", name: "", value: "" });

    this.setState({ stateim });
  }

  degerchange(i, e) {
    const stateim = this.state.features[i];
    stateim.deger = e.target.value;
    this.setState({ stateim });

    console.log(this.state.features[i], e);
  }

  SetValue(i, e) {
    const stateim = this.state.features[i];
    stateim.value = e.target.value;
    this.setState({ stateim });
    console.log(this.state.features[i], e);
  }

  setFromInput(i, e) {
    const stateim = this.state.inputs[i];
    stateim.option_value = e;
    this.setState({ stateim });
  }

  setBrand(option) {
    this.setState((state) => {
      return {
        products_brand: option.id,
      };
    });
  }

  removeItems = (e, is) => {
    const stats = this.state.content;
    stats[e].data.splice(is, 1);
    this.setState({ content: stats });
  };

  removeItem = (e) => {
    const stats = this.state.content;
    stats.splice(e, 1);
    this.setState({ content: stats });
  };

  removevas = (id) => {
    const vas = this.state.vas;
    let res = [];
    for (let i = 0; i < vas.length; i++) {
      if (i !== id) {
        console.log(vas[i]);
        res.push(vas[i]);
      }
    }
    this.setState({
      vas: res,
    });
  };

  addMadde = (i) => {
    const stateim = this.state.content;
    stateim[i].data.push({ value: "Deneme Yazısı" });
    this.setState({ stateim });
  };

  kaldir = () => {
  
    this.setState({
      resim : ""
    });

  }

  renderimage = (props, option, snapshot, className) => {
    const imgStyle = {
      borderRadius: "50%",
      verticalAlign: "middle",
      marginRight: 10,
      border: "0 !important",
    };

    return (
      <button {...props} className={className} type="button">
        <span>
          <img
            alt=""
            style={imgStyle}
            width="32"
            height="32"
            src={option.value ? option.value : "assets/avatar.png"}
          />
        </span>
      </button>
    );
  };

  renderimagevalue = (valueProps, snapshot, className) => {
    const imgStyle = {
      borderRadius: "50%",
      verticalAlign: "middle",
      marginRight: 10,
      width: "74px",
      height: "32px",
    };

    return (
      <input
        type="image"
        {...valueProps}
        className={className}
        style={imgStyle}
        src={snapshot.displayValue}
      />
    );
  };

  InsertProduct = () => {
    this.setState(
      {
        addBlockLoad: true,
        form: {
          tags: this.state.tags,
          content: this.state.content,
          slug: this.state.link,
          slug: this.state.link,
          title: this.state.title,
          image: this.state.resim,
          status: this.state.isDraft,
          price: this.state.normal,
          price_sale: this.state.indirimli,
          status: this.state.isDraft,
          onecikan: this.state.onecikan,
          producttype : this.state.producttype,
          external_link : this.state.external_link,
          tahsilat : this.state.tahsilat,
          is_address :this.state.is_address,
          payment_after_code : this.state.payment_after_code,
          payment_after_action : this.state.payment_after_action,
          taksit : this.state.taksit,
          description: this.state.short_description,
          category: this.state.products_category,
          features: this.state.features,
          inputs: this.state.inputs,
          brand: this.state.products_brand,
          vas: this.state.vas,
          maya: this.state.maya,
          web: this.state.web,
          app: this.state.app,
          is_cargo : this.state.is_cargo
        },
      },

      () => {
        fetch(this.state.BASE_URL + "/api/addProduct", {
          method: "POST",
          body: JSON.stringify(this.state.form),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              addBlockLoad: false,
            });
            if (result.success) {
              cogoToast.success("Kampanya Başarılı Bir Şekilde Oluşturuldu", {
                position: "top-right",
              });

            //  this.props.history.push("/content/products/all");
              this.setState({
                body: "",
                tags: [],
                categorys: {},
                products_category: "",
                products_brand: "",
                link: "",
                title: "",
                file: {},
                form: {},
                addBlockLoad: false,
                isDraft: true,
                price: "",
                price_sale: "",
                is_cargo : false
              });
            } else {
              cogoToast.warn("Kampanya Oluşturulurken Hata Oluştu", {
                position: "top-right",
              });
            }
          });
      }
    );
  };

  icerikver = (value, index) => {
    const stateim = this.state.content;
    stateim[index].value = value;
    this.setState({ stateim });
  };

  icerikvertext = (e, index) => {
    const stateim = this.state.content;
    stateim[index].value = e.target.value;
    this.setState({ stateim });
    console.log(stateim);
  };

  icerikvermadde = (e, index, altindex) => {
    const stateim = this.state.content;
    stateim[index].data[altindex].value = e.target.value;
    this.setState({ stateim });
    console.log(stateim);
  };

  linkVer = (e) => {
    this.setState({
      title: e.target.value,
    });

    var str = e.target.value;
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    this.setState((state) => {
      return {
        link: str,
      };
    });
  };

  linkDegis = (str) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ığşåàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "igsaaaaaaeeeeiiiioooouuuunc------";

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    this.setState((state) => {
      return {
        link: str,
      };
    });
  };

  handleChange = (e) => {
    this.setState({ tags: e });
  };

  handleOnChangeBody(index, event) {
    const stat = this.state.vas;
    const value = event.target.value;
    const name = event.target.name;
    stat[index][name] = value;
    this.setState({ stat });
  }

  handleOnChangeSelect(index, event) {
    const stat = this.state.vas;
    stat[index].image = event;
    this.setState({ stat });
    console.log(stat);
  }

  handleChangeprice = (event, name)=> {

    this.setState({[name]: event});
} 

  render() {
    const RenderHTML = (props) => (
      <label dangerouslySetInnerHTML={{ __html: props.HTML }}></label>
    );



  


    return (
      <div>
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" alt="Loader" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Yeni Kampanya Ekle"
                html={
                  <Link
                    to="/content/products/all"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> Kampanyalara Geri
                    Dön
                  </Link>
                }
              />
              <BlockUi
                loader={
                  <div className="loader-img-page-div">
                    <img alt="Resim" src="/assets/img/loader.svg" />
                    <div className="LoaderText-div">
                      Kampanya Eklenirken Lütfen Bekleyiniz...
                    </div>
                  </div>
                }
                tag="div"
                className="row row-sm"
                blocking={this.state.addBlockLoad}
              >
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-6">
                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Kampanya Ayarları
                                </legend>
                                <div className="form-group checks">
                                  <div>Yayın Durumu</div>
                                  <div className="">
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.isDraft}
                                      onChange={this.handleChangeCheck}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                          Taslak Olarak Kaydet
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                          Hemen Yayınla
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>
                                </div>
                                <div className="form-group checks">
                                  <div>Öne Çıkarılsın mı?</div>

                                  <div>
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.onecikan}
                                      onChange={this.handleChangeOnecikan}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                          Hayır
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                          Evet
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>
                                </div>

                                <div className="form-group checks">
                                  <div>Android ve Web'de Gösterim</div>

                                  <div>
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.web}
                                      onChange={this.handleChangeweb}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                          Hayır
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                          Evet
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>
                                </div>
                                <div className="form-group checks">
                                  <div>Ios'ta Gösterim</div>

                                  <div>
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.app}
                                      onChange={this.handleChangeapp}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                          Hayır
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                          Evet
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>
                                </div>
                                <div className="form-group checks">
                                  <div>Maya'da Gösterim</div>

                                  <div>
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.maya}
                                      onChange={this.handleChangeMaya}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                          Hayır
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                          Evet
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>
                                </div>

                                {this.state.maya && 
                                <div className="form-group checks">
                                  <div>Adres İstensin mi ?</div>

                                  <div>
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.is_address}
                                      onChange={this.handleChangeaddress}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                         Evet 
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                         Hayır
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>

                                </div> 
                              }

                                
                              
                                <div className="form-group checks">
                                  <div>Kuryeli Ürün mü?</div>

                                  <div>
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.is_cargo}
                                      onChange={this.handleChangeis_cargo}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                          Evet
                                          
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                          Hayır
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>
                                </div>
                             
                                 
                                <div className="form-group checks">
                                  <div>Kampanya Tipi</div>

                                  <div>
                                  <select
                                                      className="form-control"
                                                      name="producttype"
                                                      onChange={(e) => this.setValue(e)}
                                                   
                                                    >
                                                      <option value="0">
                                                      Aylık Abonelik 
                                                      </option>
                                                      <option value="1">
                                                      Tahsilatlı Kampanya
                                                      </option>
                                                      <option value="2">
                                                      Affiliation Kampanya
                                                      </option>
                                                      
                                                    </select>
                                  </div>

                                </div>
                                        
                                    {this.state.producttype == 1 ? 
                                    <div>
                                <div className="form-group checks">
                                  <div>Tahsilat Tipi</div>

                                  <div>
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.tahsilat}
                                      onChange={this.handleChangetahsilat}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                         Peşin Satış
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                         Taksitli Satış
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>

                                </div> 




                                <div className="form-group checks">
                                  <div>Tahsilat Sonrası</div>

                                  <div>
                                  <select
                                                      className="form-control"
                                                      name="payment_after_action"
                                                      onChange={(e) => this.setValue(e)}
                                                    >
                                                      <option value="">
                                                        Seçim Yapınız
                                                      </option>
                                                      <option value="1">
                                                        Dijital Kod Gönder
                                                      </option>
                                                      <option value="2">
                                                        Başarılı Mesajı Göster
                                                      </option>
                                                      
                                                    </select>
                                  </div>

                                </div> 
                                {this.state.payment_after_action == 1 ? 
                                <div className="form-group checks">
                                  <div>Tahsilat Sonrası</div>

                                  <div>
                                  <select
                                                      className="form-control"
                                                   name="payment_after_code"
                                                   value={this.state.payment_after_code}
                                                   onChange={(e) => this.setValue(e)}
                                                    >
                                                      <option value="">
                                                        Seçim Yapınız
                                                      </option>
                                                      <option value="1">
                                                       Bein Connect Kodu
                                                      </option>
                                                      <option value="2">
                                                        ReBee Kodu
                                                      </option>
                                                      
                                                    </select>
                                  </div>

                                </div>  :<></> }


                                {this.state.tahsilat ? 
                                <div className="form-group checks">
                                  <div>Taksit Sayısı</div>

                                  <div>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    name="taksit"
                                    type="number"
                                    placeholder="Taksit Sayısı"
                                    value={this.state.taksit}
                                    onChange={(e) => this.setValue(e)}
                                  
                                  />

                                </div>
                                </div>
                                </div> : <></>}
                                </div>: <></>}
                              </fieldset>

                              {this.state.producttype == 2 ? 
                                <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                Gönderilecek URL
                                </legend>
                                <div className="form-group">
                                  <input
                                            className="form-control"
                                            name="external_link"
                                            placeholder="Gönderilecek Dış Link"
                                            onChange={(e) => this.setValue(e)}
                                  />
                                  </div>

                                  </fieldset>
                                               : <></>}    

                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Kategori ve Marka
                                </legend>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="pos-relative">
                                        <Select
                                          name="categorys"
                                          options={this.state.categorys}
                                          getOptionLabel={(option) =>
                                            `${option.name}`
                                          }
                                          getOptionValue={(option) =>
                                            `${option.id}`
                                          }
                                          onChange={this.handleMultiChange}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          placeholder="Lütfen Kategori Seçiniz"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="pos-relative">
                                        <Select
                                          name="brand"
                                          options={this.state.brands}
                                          getOptionLabel={(option) =>
                                            `${option.makename}`
                                          }
                                          getOptionValue={(option) =>
                                            `${option.id}`
                                          }
                                          onChange={this.setBrand}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          placeholder="Lütfen Kategori Seçiniz"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>

                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Kampanya Resmi
                                </legend>
                                <div className="form-group">
                                <BlockUi
                              loader={
                                <div className="loader-img-page-div">
                                  <img
                                    alt="Resim"
                                    src="/assets/img/loader.svg"
                                  />
                                  <div className="LoaderText-div">
                                    Resim Yüklenirken Lütfen Bekleyiniz...
                                  </div>
                                </div>
                              }
                              tag="div"
                          
                              blocking={this.state.loadImage}
                            >
                              <div className="form-group">
                             
                                <div className="row">
                                <div className="pos-relative col-lg-6">
                                <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="container">
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p>
                                            Kampanya Resmini Yüklemek İçin Tıklayın <br /> veya <br /> Resmi Buraya Sürükleyin
                                          </p>
                                        </div>
                                     
                                      </section>
                                    )}
                                  </Dropzone>
                                </div>
                                {this.state.resim ? (
                                  <div className="col-lg-4">
                                        <div class="text-center">
                                          <img
                                            src={this.state.resim}
                                            className="wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0"
                                          />
                                          <button className="btn btn-warning wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 " onClick={this.kaldir}>Resmi Kaldır</button>
                                        </div></div>) : (<></>
                                        )}
                                </div>
                              </div>
                            </BlockUi>
                                  </div>

                                  </fieldset>



                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Kampanya Adı
                                </legend>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    required
                                    type="text"
                                    placeholder="Kampanya Adı"
                                    onChange={this.linkVer}
                                  />
  {this.state.producttype != 2 ? 
                                  this.state.link ? (
                                    <div className="row">
                                      <div className="col-lg-12 mt-3">
                                        <span className="abonesepeticom">
                                          <i class="fe fe-chrome"></i>{" "}
                                          https://www.abonesepeti.com/kampanya-detay/{" "}
                                        </span>
                                        <EasyEdit
                                          type={Types.TEXT}
                                          value={this.state.link}
                                          placeholder="Düzenlemek İçin Tıklayın"
                                          saveButtonLabel="Kaydet"
                                          cancelButtonLabel="Vazgeç"
                                          onSave={(val) => this.linkDegis(val)}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  ) : <></>}
                                </div>
                              </fieldset>

                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Kampanya Fiyatı
                                </legend>
                                <div className="form-group checks">
                                <CurrencyInput   onValueChange={(value, name) => this.handleChangeprice(value, name)}  name="normal"  inputType="tel"  placeholder="Normal Fiyatı" prefix=" "  suffix=" ₺" className="form-control mr-2" intlConfig={{ locale: 'en-US', currency: 'TRY' }} />
                                 
                             
                                <CurrencyInput  onValueChange={(value, name) => this.handleChangeprice(value, name)}  name="indirimli"  inputType="tel" placeholder="Satış Fiyatı" prefix=" " suffix=" ₺" className="form-control ml-2 has-error" intlConfig={{ locale: 'en-US', currency: 'TRY' }} />
                                 
                                </div>
                              </fieldset>
                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Anahtar Kelimeler
                                </legend>
                                <div className="form-group">
                                  <div className="pos-relative bootstrap-tagsinput">
                                    <InputTags
                                      placeholder="Etiketi Yazın Enter Tuşuna Başınız"
                                      values={this.state.tags}
                                      onTags={(value) =>
                                        this.handleChange(value.values)
                                      }
                                    />
                                  </div>
                                </div>
                              </fieldset>
                              <fieldset className="scheduler-border">
                                <legend className="scheduler-border">
                                  Kısa Açıklama
                                </legend>
                                <div className="form-group">
                                  <div>
                                    <textarea
                                      id="short_desc"
                                      name="short_desc"
                                      class="form-control"
                                      onChange={(event) =>
                                        this.handleOnChange(event)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </fieldset>
                              {this.state.inputs.length ? (
                                <fieldset className="scheduler-border">
                                  <legend className="scheduler-border">
                                    Müşteriden İstenecek Ek Bilgiler(Bayi)
                                  </legend>
                                  {this.state.inputs.map((x, i) => (
                                    <div className="form-group checks">
                                      <RenderHTML HTML={x.option_name} />

                                      <div>
                                        <Switch
                                          onColor="#e0f7ea"
                                          onHandleColor="#00d057"
                                          handleDiameter={30}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={20}
                                          width={48}
                                          checked={x.option_value}
                                          onChange={(event) =>
                                            this.setFromInput(i, event)
                                          }
                                          uncheckedIcon={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                paddingRight: 2,
                                                width: "180px",
                                              }}
                                            >
                                              Pasif
                                            </div>
                                          }
                                          checkedIcon={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                paddingRight: 2,
                                                width: "200px",
                                                color: "#00d057",
                                              }}
                                            >
                                              Aktif
                                            </div>
                                          }
                                          className="react-switch"
                                          id="icon-switch"
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </fieldset>
                              ) : (
                                <></>
                              )}
                              <div className="form-group">
                                {this.state.features.length ? (
                                  <label className>Kampanya Özellikleri</label>
                                ) : (
                                  <></>
                                )}
                                <div className="pos-relative">
                                  <div className="row">
                                    {this.state.features.map((x, i) => (
                                      <div className="col-lg-6">
                                        <fieldset className="scheduler-border">
                                          <legend className="scheduler-border">
                                            <i className={x.icon}></i>{" "}
                                            {x.option_name}
                                          </legend>
                                          <div class="row">
                                            {x.type != "select" &&
                                            x.function == 1 ? (
                                              <div class="col-lg-5">
                                                <div className="form-group">
                                                  <div className="pos-relative">
                                                    <select
                                                      className="form-control"
                                                      onChange={(event) =>
                                                        this.degerchange(
                                                          i,
                                                          event
                                                        )
                                                      }
                                                    >
                                                      <option value="">
                                                        Seçim Yapınız
                                                      </option>
                                                      <option value="1">
                                                        Evet
                                                      </option>
                                                      <option value="0">
                                                        Hayır
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}

                                            {x.type != "select" &&
                                            x.deger == 1 &&
                                            x.function == 1 ? (
                                              <div class="col-lg-5">
                                                <div className="form-group">
                                                  <div className="pos-relative">
                                                    <input
                                                      type={x.type}
                                                      className="form-control form-control-danger"
                                                      placeholder={
                                                        x.placeholder
                                                      }
                                                      onChange={(event) =>
                                                        this.SetValue(i, event)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}

                                            {x.type != "select" &&
                                            x.function == 1 &&
                                            x.deger == 1 ? (
                                              <div className="col-lg-2">
                                                <div className="input-group unitim">
                                                  {x.unit}
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}

                                            {x.type != "select" &&
                                            x.function == 0 ? (
                                              <div class="col-lg-6">
                                                <div className="form-group">
                                                  <div className="pos-relative">
                                                    <input
                                                      type={x.type}
                                                      name="value"
                                                      className="form-control form-control-danger"
                                                      placeholder={
                                                        x.option_name
                                                      }
                                                      onChange={(event) =>
                                                        this.SetValue(i, event)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}

                                            {x.type != "select" &&
                                            x.function == 0 ? (
                                              <div className="col-lg-6">
                                                <div className="input-group unitim">
                                                  {x.unit}
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}

                                            {x.type == "select" ? (
                                              <div class="col-lg-12">
                                                <div className="form-group">
                                                  <div className="pos-relative">
                                                    <select
                                                      className="form-control"
                                                      onChange={(event) =>
                                                        this.SetValue(i, event)
                                                      }
                                                    >
                                                      <option value="">
                                                        YOK
                                                      </option>
                                                      {x.subvalues.map(
                                                        (item, index) => {
                                                          return (
                                                            <option
                                                              value={item.name}
                                                              key={index}
                                                            >
                                                              {item.name}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </fieldset>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <div>
                                  <button
                                    className="btn btn-primary mr-2 text-white"
                                    onClick={() => this.addContent("list")}
                                  >
                                    {" "}
                                    Madde Kutusu Ekle
                                  </button>
                                  <button
                                    className="btn btn-primary  mr-2 text-white"
                                    onClick={() => this.addContent("textarea")}
                                  >
                                    Yazı Ekle
                                  </button>
                                  <button
                                    className="btn btn-primary text-white  mr-2"
                                    onClick={() => this.addContent("editor")}
                                  >
                                    Html Editör Ekle
                                  </button>
                                  <button
                                    className="btn btn-primary  text-white"
                                    onClick={() => this.addContent("deepnote")}
                                  >
                                    Dipnot Ekle
                                  </button>
                                </div>
                              </div>

                              <div
                                className={
                                  !this.state.content.length ? (
                                    "urunozellik"
                                  ) : (
                                    <></>
                                  )
                                }
                              >
                                {!this.state.content.length ? (
                                  <div className="icim">
                                    Üst Menüden İçerik Ekleyiniz
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {this.state.content.map((x, i) => (
                                  <div className="flexim">
                                    <div className="form-group altflex" key={i}>
                                      {console.log(x)}
                                      {x.tip == "textarea" ? (
                                        <div>
                                          <label className>Yazı Alanı</label>
                                          <textarea
                                            id="short_desc"
                                            name="short_desc"
                                            class="form-control"
                                            onChange={(event) =>
                                              this.icerikvertext(event, i)
                                            }
                                          ></textarea>
                                        </div>
                                      ) : x.tip == "deepnote" ? (
                                        <div>
                                          <label className>Dip Not</label>
                                          <textarea
                                            id="short_desc"
                                            name="short_desc"
                                            class="form-control"
                                            onChange={(event) =>
                                              this.icerikvertext(event, i)
                                            }
                                          ></textarea>
                                        </div>
                                      ) : x.tip == "list" ? (
                                        <div>
                                          {x.data.map((madde, index) => (
                                            <div>
                                              <label className>
                                                Madde Kutusu
                                              </label>
                                              <div className="madde flexim mb-3">
                                                <input
                                                  id="short_desc"
                                                  name="short_desc"
                                                  class="form-control"
                                                  value={madde.value}
                                                  onChange={(event) =>
                                                    this.icerikvermadde(
                                                      event,
                                                      i,
                                                      index
                                                    )
                                                  }
                                                />

                                                <button
                                                  className="ml-2 btn btn-danger"
                                                  onClick={() =>
                                                    this.removeItems(i, index)
                                                  }
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </div>
                                          ))}
                                          <button
                                            className="btn btn-success mt-2 right pull-right"
                                            onClick={() => this.addMadde(i)}
                                          >
                                            Yeni Ekle
                                          </button>
                                        </div>
                                      ) : (
                                        <div>
                                          <label className>HTML Editör</label>
                                          <SunEditor
                                            height="500"
                                            placeholder="Kampanya içeriğini buraya Kampanyanız..."
                                            setOptions={{
                                              buttonList: [
                                                [
                                                  "undo",
                                                  "redo",
                                                  "font",
                                                  "fontSize",
                                                  "formatBlock",
                                                  "paragraphStyle",
                                                  "blockquote",
                                                  "bold",
                                                  "underline",
                                                  "italic",
                                                  "strike",
                                                  "subscript",
                                                  "superscript",
                                                  "fontColor",
                                                  "hiliteColor",
                                                  "textStyle",
                                                  "removeFormat",
                                                  "outdent",
                                                  "indent",
                                                  "align",
                                                  "horizontalRule",
                                                  "list",
                                                  "lineHeight",
                                                  "table",
                                                  "link",
                                                  "image",
                                                  "video",
                                                  "audio",
                                                  "fullScreen",
                                                  "showBlocks",
                                                  "codeView",
                                                  "preview",
                                                ],
                                              ],
                                              lang: lang.tr,
                                            }}
                                            setContents={x.value}
                                            onChange={(event) =>
                                              this.icerikver(event, i)
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>

                                    <div className="kaldir">
                                      <a
                                        className="btn btn-warning"
                                        onClick={() => this.removeItem(i)}
                                      >
                                        Kaldır
                                      </a>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div className="form-group">
                                <button
                                  className="btn btn-primary mr-2 text-white yesilbutton"
                                  onClick={() => this.addvas()}
                                >
                                  <i className="fa fa-plus"></i> Yeni VAS Ekle
                                </button>
                              </div>
                              {this.state.vas.map((x, i) => (
                                <div className="form-group row" key={i}>
                                  <div className="col-lg-2">
                                    <SelectSearch
                                      className="select-search select-avatar"
                                      options={this.state.promos}
                                      renderOption={this.renderimage}
                                      renderValue={this.renderimagevalue}
                                      placeholder="İkon Seçiniz"
                                      onChange={(event) =>
                                        this.handleOnChangeSelect(i, event)
                                      }
                                      emptyMessage={() => (
                                        <div
                                          style={{
                                            textAlign: "center",
                                            fontSize: "0.8em",
                                          }}
                                        >
                                          Not found renderer
                                        </div>
                                      )}
                                    />
                                  </div>

                                  <div className="col-lg-4">
                                    <input
                                      className="form-control"
                                      name="name"
                                      onChange={(event) =>
                                        this.handleOnChangeBody(i, event)
                                      }
                                      defaultValue={x.name}
                                      placeholder="Başlık"
                                    />
                                  </div>

                                  <div className="col-lg-4">
                                    <input
                                      className="form-control"
                                      name="value"
                                      onChange={(event) =>
                                        this.handleOnChangeBody(i, event)
                                      }
                                      defaultValue={x.value}
                                      placeholder="Açıklama"
                                    />
                                  </div>
                                  <div className="col-lg-2">
                                    <button
                                      className="ml-2 btn btn-danger"
                                      onClick={() => this.removevas(i)}
                                    >
                                      X
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="col-lg-12">
                              <button
                                className="btn ripple btn-main-primary pull-right"
                                onClick={this.InsertProduct}
                              >
                                Kampanyayı Ekle
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BlockUi>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(addProduct);
