import { Component } from "react";
import { connect } from "react-redux";
import { login, logout } from "./actions/authAction";
class LoginForm extends Component {
  componentDidMount() {
    this.props.dispatch(logout());


    
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      auth : {loading : false }
    });
  }

  

  componentDidUpdate(nextProps)
  {
      if(this.state.auth.loading === true) {
        if(nextProps.loading === false )
        {
            this.setState({
                auth : {loading : false}
              });
        }
      }
   
  }

  state = {
    username: "",
    password: "",
    loading: false,
    show: false,
    auth : {loading : false }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
        auth : {loading : true},
      });
    const { dispatch } = this.props;
    const { username, password } = this.state;
         dispatch(login(username, password))

      



    
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  showChange = () => {
    var statem = this.state.show ;
    var veri = true ;
    if(statem === true )
    {
        veri = false ;
    }

    this.setState({
      show : veri,
    });
  };
  render() {
    const { isAuthenticated, error, errorMessage } = this.props;

    if (isAuthenticated) window.location.href = "/";
    
    return (
        
      <div className="page main-signin-wrapper">
        <div className="row signpages text-center">
          <div className="col-md-12">
            <div className="card">
              <div className="row row-sm">
                <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                  <div className="mt-5 pt-4 p-2 pos-absolute">
                    <img
                      src="/assets/img/brand/logo-light.png"
                      className="header-brand-img mb-4"
                      alt="logo"
                    />
                    <div className="clearfix" />
                    <img
                      src="/assets/img/fingerprint.svg"
                      className="ht-100 mb-0"
                      alt="user"
                    />
                    <h5 className="mt-4 text-white">Giriş Ekranı </h5>
                    <span className="tx-white-6 tx-13 mb-5 mt-xl-0">
                      Giriş Yapmak İçin Kullancı Adı ve Şifrenizi Girmeniz
                      Gerekmektedir.
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                <div className="block-ui" aria-busy={this.state.auth.loading ? 'true' : "false" }>
        <div tabIndex={0} />

                  <div className="container-fluid">
                    <div className="row row-sm">
                      <div className="card-body mt-2 mb-2">
                        <img
                          src="/assets/img/brand/logo-light.png"
                          className=" d-lg-none header-brand-img text-left float-left mb-4"
                          alt="logo"
                        />
                        <div className="clearfix" />
                        
                        <form onSubmit={this.handleSubmit} className="mb-5">
                          <h3 className="text-left mb-2">
                            Hesabınıza Giriş Yapın
                          </h3>
                          <p className="mb-4 text-muted tx-14 ml-0 text-left">

                            Giriş Yapmak İçin Kullancı Adı ve Şifrenizi Girmeniz
                            Gerekmektedir.
                          </p>
                          <div className="form-group text-left">
                            <label>Kullanıcı Adı</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  style={{
                                    "backgroundColor": "#ffffff",
                                    "fontSize": "20px",
                                    "color":" #b3b3b3"
                                  }}
                                  id="basic-addon1"
                                >
                                  <i className="fe fe-user"></i>{" "}
                                </span>
                              </div>{" "}
                              <input
                                className="form-control"
                                placeholder="Kullanıcı Adı"
                                onChange={this.handleChange}
                                required
                                name="username"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="form-group text-left mb-5">
                            <label>Şifre</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  style={{
                                    "backgroundColor": "#ffffff",
                                    "fontSize": "20px",
                                    "color":" #b3b3b3"
                                  }}
                                  id="basic-addon1"
                                >
                                  <i className="fe fe-lock"></i>{" "}
                                </span>
                              </div>{" "}
                              <input
                                className="form-control"
                                type={this.state.show ? 'text' : 'password'  }
                                name="password"
                                required
                                placeholder="Şifre"
                                onChange={this.handleChange}
                              />
                              <span className="input-group-btn"><button className="btn ripple  goster-gizle-button" 
                                onClick={this.showChange}
                              type="button">
													<span className="input-group-btn"><i className={this.state.show ? 'fe fe-eye' : 'fe fe-eye-off'  }></i></span></button></span>
                             
                            </div>
                          </div>
                          <button className="btn ripple login-button btn-block mt-3 mb-3">
                            GİRİŞ YAP
                          </button>
                          {isAuthenticated ? (
                            <div
                              className="alert alert-success  alert-dismissible fade show"
                              role="alert"
                            >
                              <span className="alert-inner--icon">
                                <i className="fe fe-unlock" />
                              </span>
                              <span className="alert-inner--text">
                                <strong> Giriş Başarılı ! </strong> Başarılı Bir
                                Şekilde Giriş Yapıldı{" "}
                              </span>
                            </div>
                          ) : null}
                          {error && !isAuthenticated ? (
                            <div
                              className="alert alert-warning alert-dismissible fade show"
                              role="alert"
                            >
                              <span className="alert-inner--icon">
                                <i className="fe fe-lock" />
                              </span>
                              <span className="alert-inner--text">
                                <strong> Hata Oluştu! </strong>
                                {errorMessage}{" "}
                              </span>
                            </div>
                          ) : null}
                        </form>
                      </div>
                    </div>
                  </div>
              
                  {this.state.auth.loading ?     
        <div className="block-ui-container" tabIndex={0}>
          <div className="block-ui-overlay" />
          <div className="block-ui-message-container">
            <div className="block-ui-message">
              <div className="loader-img-page-div">
                <img alt="Resim" src="/assets/img/loader.svg" />
                <div className="LoaderText-div">
                  Giriş Yapılırken Lütfen Bekleyiniz...
                </div>
              </div>
            </div>
          </div>
        </div>
   : <></>
    }   
                </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated, error, errorMessage, user , loading } = state.auth;
  return {
    isAuthenticated,
    error,
    errorMessage,
    user,
    loading
  };
};


export default connect(mapStateToProps)(LoginForm);
