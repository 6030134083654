import React, { button } from "react";
import { Link, withRouter } from "react-router-dom";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import lang from "suneditor/src/lang";
import EasyEdit, { Types } from "react-easy-edit";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InputTags } from "react-bootstrap-tagsinput";
import Dropzone from "react-dropzone";
import Switch from "react-switch";

const tableRef = React.createRef();

class addBlog extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      file : {},
      slide: {
        slide_id : this.props.slide_id,
        title: "",
        altaciklama: "",
        button_text: "",
        button_href: "",
        aciklama_1: "",
        aciklama_2: "",
        resim: "",
        target : '',
      },
    };
    this.state.BASE_URL = config().BASE_URL;
    this.onDrop = (file) => {
      this.setState({
        file: file,
      }, () => {
        this.addImage();
    });
     
    };
  }

  
  componentDidMount() {
    fetch(
      this.state.BASE_URL + "/api/get_slide_single/" + this.props.slide_id
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {

        this.setState({
          slide : result
        });
      
      });
  }

  handleOnChange(event) {
    const slidem = this.state.slide ; 
    slidem[event.target.name] = event.target.value ; 
    this.setState({
      slidem
    });
  }

  kaldir = () => {
    const slidem = this.state.slide ; 
    slidem.resim = "" ; 
    this.setState({
      slidem
    });


  }


handleChangeCheck = (isDraft) => {
  const slidem = this.state.slide ; 
  slidem.status = isDraft ; 
  this.setState({
    slidem
  });


} 
  
  addImage = () => {

    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/slide", {
       method: 'POST',
       body: data
         }).then((response) => {
          return response.json();
        })
        .then((result) => {



          if (!result.success) {
            cogoToast.warn(
              "Resim Yüklenirken Sorun Oluştu.",
              { position: "top-right" }
            );
          }
          else 
          {
            cogoToast.success(
              "Resim Başarılı Bir Şekilde Yüklendi.",
              { position: "top-right" }
            );
            const slider = this.state.slide ;
            slider.resim = result.url ;
            this.setState({
              slider
            });
            
          }
          this.setState({
            loadImage: false,
          });
            console.log(result);
        });
    }

    updateSlider = () => {

      this.setState({
        addBlockLoad : true ,
        form : this.state.slide ,
      }, () => {
     
      fetch(this.state.BASE_URL + "/api/updateSlider", {
        method: "POST",
        body: JSON.stringify(this.state.form),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          this.setState({
            addBlockLoad: false,
          });
          if (result.success) {
       
            cogoToast.success("Slider Başarılı Bir Şekilde Güncellendi", {
              position: "top-right",
            });
  
            this.props.history.push("/content/sliders/all");
  
          } else {
            cogoToast.warn("Slider Güncellenirken Hata Oluştu", {
              position: "top-right",
            });
          }
        }).catch((error) => {
          this.setState({
            addBlockLoad: false,
          });
          cogoToast.warn("Slider Güncellenirken Hata Oluştu", {
            position: "top-right",
          });

        })
  
  
  
    });
      
  
    }
    
  render() {
    return (
      <div>
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Slider'ı Güncelle"
                html={
                  <Link
                    to="/content/sliders/all"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> Slidelara Geri Dön
                    Dön
                  </Link>
                }
              />
              <BlockUi
                loader={
                  <div className="loader-img-page-div">
                    <img alt="Resim" src="/assets/img/loader.svg" />
                    <div className="LoaderText-div">
                      Slider Eklenirken Lütfen Bekleyiniz...
                    </div>
                  </div>
                }
                tag="div"
                className="row row-sm"
                blocking={this.state.addBlockLoad}
              >
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card ">
                    <div className="card-body">

                   
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-8">
                            <label className="row">
                                  <div className="col-lg-6"> </div> 
                                  <div className="col-lg-6">
                                    <Switch
                                      onColor="#e0f7ea"
                                      onHandleColor="#00d057"
                                      handleDiameter={30}
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                      height={20}
                                      width={48}
                                      checked={this.state.slide.status}
                                      onChange={this.handleChangeCheck}
                                      uncheckedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "180px",
                                          }}
                                        >
                                          Pasif
                                        </div>
                                      }
                                      checkedIcon={
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            height: "100%",
                                            fontSize: 15,
                                            paddingRight: 2,
                                            width: "200px",
                                            color: "#00d057",
                                          }}
                                        >
                                          Aktif
                                        </div>
                                      }
                                      className="react-switch"
                                      id="icon-switch"
                                    />
                                  </div>
                                </label>

                              <div className="row">
                              <div className="form-group col-lg-6">
                                <label className="row">
                                  <div className="col-lg-6">Slide Başlığı</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Slider Başlığı"
                                  name="title"
                                  value={this.state.slide.title}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>

                              <div className="form-group col-lg-6">
                                <label className="row">
                                  <div className="col-lg-6">Fiyat ve Döngü</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Fiyat ve Döngü"
                                  name="altaciklama"
                                  value={this.state.slide.altaciklama}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>


                              <div className="form-group col-lg-6">
                                <label className="row">
                                  <div className="col-lg-6">Küçük Yuvarlak</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Küçük Yuvarlak"
                                  name="aciklama_1"
                                  value={this.state.slide.aciklama_1}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>

                              
                              <div className="form-group col-lg-6">
                                <label className="row">
                                  <div className="col-lg-6">Büyük Yuvarlak</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Büyük Yuvarlak"
                                  name="aciklama_2"
                                  value={this.state.slide.aciklama_2}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>


                              <div className="form-group col-lg-4">
                                <label className="row">
                                  <div className="col-lg-6">Buton Yazısı</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Buton Yazısı"
                                  name="button_text"
                                  value={this.state.slide.button_text}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>

                              <div className="form-group col-lg-4">
                                <label className="row">
                                  <div className="col-lg-6">Buton Bağlantısı</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Bağlantısı Bağlantısı"
                                  name="button_href"
                                  value={this.state.slide.button_href}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>


                              
                              <div className="form-group col-lg-4">
                                <label className="row">
                                  <div className="col-lg-6">Sayfa Açılış Tipi</div>
                                </label>
                                <select className="form-control"  placeholder="Bağlantısı Bağlantısı"
                                  name="target"
                                  value={this.state.slide.target}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }>
                                  <option value="external">Yeni Sayfada Aç</option>
                                  <option value="">Aynı Sayfada Aç</option>
                                </select>
                               
                              </div>

                              </div>
                            
                            </div>

                            <BlockUi
                              loader={
                                <div className="loader-img-page-div">
                                  <img
                                    alt="Resim"
                                    src="/assets/img/loader.svg"
                                  />
                                  <div className="LoaderText-div">
                                    Resim Yüklenirken Lütfen Bekleyiniz...
                                  </div>
                                </div>
                              }
                              tag="div"
                              className="col-lg-4"
                              blocking={this.state.loadImage}
                            >
                              <div className="form-group">
                                <label className>Slider Resmi</label>
                                <div className="pos-relative">
                                <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="container">
                                      
                                        {this.state.slide.resim ? (
                                        <div class="media d-block mt-3 text-center">
                                          <img
                                            src={this.state.slide.resim}
                                            className="wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0"
                                          />
                                          <button className="btn btn-warning wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 " onClick={this.kaldir}>Resmi Kaldır</button>
                                        </div>) : (  <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p>
                                            Slide Resmini Yüklemek İçin Tıklayın <br /> veya <br /> Resmi Buraya Sürükleyin
                                          </p>
                                        </div>
                                        )}
                                      </section>
                                    )}
                                  </Dropzone>


                                </div>
                              </div>
                            </BlockUi>

                            <div className="col-lg-12">
                              <button className="btn ripple btn-main-primary pull-right" onClick={this.updateSlider}>
                                Slider'ı Günncelle
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.slide.resim ? 
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card mavi">
                    <div className="card-body">
                      <div className="form-group">
                        <label className="row">
                          <div className="col-lg-6">Önizleme</div>
                        </label>
                        <div className="row row-sm">
                          <div className="col-md-12 col-lg-12 col-xl-12">
                            <section className="section home-slider">
                              <div className="container">
                                <div className="slide-list slick-initialized slick-slider slick-dotted">
                                  <div className="item">
                                    <div className="content">
                                      <h2>
                                      <c dangerouslySetInnerHTML={{__html: this.state.slide.title}} />
                                 
                                          <span dangerouslySetInnerHTML={{__html: this.state.slide.altaciklama}} />
                                      
                                      </h2>
                                      <a href={this.state.slide.button_href} rel="external" data-rel={this.state.slide.target ? this.state.slide.target : '' } rel={this.state.slide.target ? this.state.slide.target : '' } >
                                        {this.state.slide.button_text}
                                      </a>
                                    </div>
                                    <div className="mini-content">
                                   
                                      <div dangerouslySetInnerHTML={{__html: this.state.slide.aciklama_1}} />
                                      <div dangerouslySetInnerHTML={{__html: this.state.slide.aciklama_2}} />
                                      
                                    </div>
                                    <div className="img">
                                      <img src={this.state.slide.resim} />
                                    </div>
                                  </div>
                                </div>
                                <div className="resimArkasi">
                                  <img
                                    src="./assets/tabs.png"
                                    className="tabsImage"
                                  />
                                </div>

                                <img
                                  src="./assets/header.png"
                                  className="tabsImageHeader"
                                />
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <></>}
             </BlockUi>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(addBlog);
