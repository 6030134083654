import React, { button } from "react";
import { Link, withRouter } from "react-router-dom";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "react-block-ui/style.css";
import Loader from "react-loaders";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader";
import GlobalLoader from "../../bilesenler/GlobalLoader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import lang from "suneditor/src/lang";
import EasyEdit, { Types } from "react-easy-edit";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InputTags } from "react-bootstrap-tagsinput";
import Dropzone from "react-dropzone";
import Switch from "react-switch";

const tableRef = React.createRef();

class addBlog extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      faqid: this.props.faqid,
      categorys: [],
      form: {},
      addBlockLoad: false,
    };
    this.state.BASE_URL = config().BASE_URL;
    this.handleMultiChange = this.handleMultiChange.bind(this);
  }

  componentDidMount() {
    fetch(this.state.BASE_URL + "/api/faq_Categorys_all")
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({
          categorys: result,
        });

        fetch(this.state.BASE_URL + "/api/get_faq_single/" + this.state.faqid)
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              cat_id : result.data.cat_id,
              ques_title: result.data.ques_title,
              kategori: result.data.cat_id,
              ques_description: result.data.ques_description,
            });
          });
      });
  }
  handleOnChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleMultiChange(option) {
    this.setState((state) => {
      return {
        cat_id: option.id,
        kategori: option.id,
      };
    });
  }

  ekleblog = () => {
    this.setState(
      {
        addBlockLoad: true,
        form: {
          id: this.state.faqid,
          ques_title: this.state.ques_title,
          ques_description: this.state.ques_description,
          cat_id: this.state.cat_id,
        },
      },
      () => {
        fetch(this.state.BASE_URL + "/api/addFaq", {
          method: "POST",
          body: JSON.stringify(this.state.form),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.setState({
              addBlockLoad: false,
            });
            if (result.success) {
              cogoToast.success("S.S.S. Başarılı Bir Şekilde Oluşturuldu", {
                position: "top-right",
              });

              this.props.history.push("/content/faqs/all");
              this.setState({
                categorys: {},
                loadImage: false,
                form: {},
                addBlockLoad: false,
              });
            } else {
              cogoToast.warn("S.S.S. Oluşturulurken Hata Oluştu", {
                position: "top-right",
              });
            }
          });
      }
    );
  };
  render() {
    const previewStyle = {
      display: "inline",
      width: 100,
      height: 100,
    };

    return (
      <div>
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Yeni S.S.S. Güncelle"
                html={
                  <Link
                    to="/content/faqs/all"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> S.S.S. Geri Dön
                  </Link>
                }
              />
              <BlockUi
                loader={
                  <div className="loader-img-page-div">
                    <img alt="Resim" src="/assets/img/loader.svg" />
                    <div className="LoaderText-div">
                      S.S.S. Güncellenirken Lütfen Bekleyiniz...
                    </div>
                  </div>
                }
                tag="div"
                className="row row-sm"
                blocking={this.state.addBlockLoad}
              >
                <div className="col-lg-6 col-md-6">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label className="row">
                                  <div className="col-lg-12">Soru</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Soru"
                                  id="ques_title"
                                  name="ques_title"
                                  defaultValue={this.state.ques_title}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>

                              <div className="form-group">
                                <label className>S.S.S. Kategorisi</label>
                                <div className="pos-relative">
                                  <Select
                                    name="categorys"
                                    options={this.state.categorys}
                                    getOptionLabel={(option) =>
                                      `${option.name}`
                                    }
                                    getOptionValue={(option) => `${option.id}`}
                                    value={this.state.categorys.filter(
                                      (option) =>
                                        option.id === this.state.kategori
                                    )}
                                    onChange={this.handleMultiChange}
                                    className="basic-single-select"
                                    classNamePrefix="select"
                                    placeholder="Lütfen Kategori Seçiniz"
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label className>Soru Cevabı</label>
                                <div>
                                  <textarea
                                    rows="10"
                                    id="ques_description"
                                    name="ques_description"
                                    className="form-control"
                                    defaultValue={this.state.ques_description}
                                    onChange={(event) =>
                                      this.handleOnChange(event)
                                    }
                                  ></textarea>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <button
                                className="btn ripple btn-main-primary pull-right"
                                onClick={this.ekleblog}
                              >
                                S.S.S. Güncelle
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BlockUi>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(addBlog);
