import React, { Component } from 'react'

import { ZoomMtg } from "@zoomus/websdk";
import $ from 'jquery';
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import ZoomMeetingList from './ZoomMeetingList';
import { BrowserRouter as Router, Route , Redirect } from 'react-router-dom';
import { config } from "../config";
// Add this, never use it client side in production
const API_KEY = 'aQjhdPF7QM6kNwenU7DrAw';
// Add this, never use it client side in production
const API_SECRET = '6GSbCGBkhyuu2ZF9fbX0D45hmsGpCkFetJjd';
// This can be your Personal Meeting ID
const MEETING_NUMBER = "";

const meetConfig = {
    apiKey: API_KEY,
    apiSecret: API_SECRET,
    meetingNumber: MEETING_NUMBER,
    userName: 'test user',
    passWord: '123456',
    leaveUrl: '/',
    role: 0,
    width: 0, 
    height: 0 
};

class Meeting extends React.Component {

	constructor(props) {
		super(props);
        this.state = {
            meetingLaunched: false,
            redirect : false
        }
        meetConfig.meetingNumber = props.meetid ;
        this.state.BASE_URL = config().BASE_URL;
	}

  
    updateDimensions = () => {
     
            console.log("Değişti");
        $("#zmmtg-root").width(window.innerWidth - $(".main-sidebar").width());
            $("#zmmtg-root").height(window.innerHeight - 64);

            $(".meeting-client-inner").width($("#zmmtg-root").width());
            $(".meeting-client-inner").height($("#zmmtg-root").height());
            
            $(".meeting-client-inner").css({left : $(".main-sidebar").width(), top : $(".main-header").height()});
            $("#zmmtg-root").css({left : $(".main-sidebar").width() , top : $(".main-header").height() , display : 'block',"z-index" : '2 !important' });


      };
    

      

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);

        
		ZoomMtg.setZoomJSLib("https://source.zoom.us/1.8.3/lib", "/av");
		ZoomMtg.preLoadWasm();
        ZoomMtg.prepareJssdk();
        
   
            
        fetch(this.state.BASE_URL + "/api/getSingleMeet/" + meetConfig.meetingNumber)
        .then(response => {
            return response.json();
        }).then(sonuc => {
            window.$.i18n.load("/tr-TR.json", "turkce");   
            
            var that = this;
       
           
    
            if(sonuc.id) {
            ZoomMtg.generateSignature({
                meetingNumber: sonuc.id,
                apiKey: meetConfig.apiKey,
                apiSecret: meetConfig.apiSecret,
                role: meetConfig.role,
                success(res) {
              
                    ZoomMtg.init({
                        leaveUrl: window.origin,
                        isShowJoiningErrorDialog: false,
                        isSupportAV: true,
                        success() {
                            
                            ZoomMtg.join(
                                {
                                    meetingNumber: sonuc.id,
                                    userName: meetConfig.userName,
                                    signature: res.result,
                                    apiKey: meetConfig.apiKey,
                                    userEmail: 'cemalkuller@gmail.com',
                                    passWord: sonuc.password,
                                    success() {
                                       
                                       
                                        $("#zmmtg-root").width(window.innerWidth - $(".main-sidebar").width());
                                        $("#zmmtg-root").height(window.innerHeight - 64);
    
                                        $(".meeting-client-inner").width($("#zmmtg-root").width());
                                        $(".meeting-client-inner").height($("#zmmtg-root").height());
                                        
                                        $(".meeting-client-inner").css({left : $(".main-sidebar").width(), top : $(".main-header").height()});
                                        $("#zmmtg-root").css({left : $(".main-sidebar").width() , top : $(".main-header").height() , display : 'block'  ,"z-index" : '2 !important'});
    
                                        $(".show-participants").width($("#zmmtg-root").width() - 480);
    
                                        that.setState({ meetingLaunched: true });
    
                                    },
                                    error(res) {
                                       
                                        confirmAlert({
                                            title: 'Hata Oluştu',
                                            message: res.errorMessage,
                                            buttons: [
                                              {
                                                label: 'Tamam',
                                                 onClick: () => that.setState({ redirect: true })
                                               
                                              }
                                            ]
                                          });
    
    
                                    }
                                }
                            );
                        },
                        error(res) {
                            console.log(res);
                        }
                    });
                }
            });
       
        }
            
        });


    
    }


    componentWillUnmount() {
        $("#zmmtg-root").hide();
        ZoomMtg.leaveMeeting({});
        window.removeEventListener('resize', this.updateDimensions);
      }

    render() {
    
        const { meetingLaunched , redirect} = this.state;
      
        if (redirect) {
            window.close();
            return <Redirect to='/meeting/ZoomMeetingList'/>;
          }
        return (
            <>
                {!meetingLaunched ? 
                      <div id="global-loader-page">
                      <div className="loader-img-page">
                      <img src="/assets/img/loader.svg" />
                      <div className="LoaderText">Toplantı Yükleniyor..</div>
                      </div>
                          
                      </div>
                : 
                    <></>
                }
            </>
        )
    }
}

export default Meeting;