import React, { button } from "react";
import { Link, withRouter } from "react-router-dom";
import Ustkisim from "../inc/Header";
import { config } from "../../config";
import "react-block-ui/style.css";
import cogoToast from "cogo-toast";
import PageHeader from "../../bilesenler/PageHeader";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import lang from "suneditor/src/lang";
import EasyEdit, { Types } from "react-easy-edit";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InputTags } from "react-bootstrap-tagsinput";
import Dropzone from "react-dropzone";
import Switch from "react-switch";

const tableRef = React.createRef();

class addBlog extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      file : {},
      happy_customers: {
        comment: "",
        name: "",
        position: "",
        image: ""
      },
    };
    this.state.BASE_URL = config().BASE_URL;
    this.onDrop = (file) => {
      this.setState({
        file: file,
      }, () => {
        this.addImage();
    });
     
    };
  }

  
  handleOnChange(event) {
    const happy_customers = this.state.happy_customers ; 
    happy_customers[event.target.name] = event.target.value ; 
    this.setState({
      happy_customers
    });
  }

  kaldir = () => {
    const happy_customers = this.state.happy_customers ; 
    happy_customers.image = "" ; 
    this.setState({
      happy_customers
    });


  }
    
  addImage = () => {

    this.setState({
      loadImage: true,
    });
    const file = this.state.file[0];
    const file_name = file.name;
    let data = new FormData();
    data.append('image', file);
    data.append('image_name', file_name);

    fetch(this.state.BASE_URL + "/api/upPro/happy_customers", {
       method: 'POST',
       body: data
         }).then((response) => {
          return response.json();
        })
        .then((result) => {



          if (!result.success) {
            cogoToast.warn(
              "Resim Yüklenirken Sorun Oluştu.",
              { position: "top-right" }
            );
          }
          else 
          {
            cogoToast.success(
              "Resim Başarılı Bir Şekilde Yüklendi.",
              { position: "top-right" }
            );
            const happy_customers = this.state.happy_customers ;
            happy_customers.image = result.url ;
            this.setState({
              happy_customers
            });
            
          }
          this.setState({
            loadImage: false,
          });
            console.log(result);
        });
    }

    ekleComment = () => {

      this.setState({
        addBlockLoad : true ,
        form : this.state.happy_customers ,
      }, () => {
     
      fetch(this.state.BASE_URL + "/api/addComment", {
        method: "POST",
        body: JSON.stringify(this.state.form),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          this.setState({
            addBlockLoad: false,
          });
          if (result.success) {
       
            cogoToast.success("Müşteri Yorumu Başarılı Bir Şekilde Oluşturuldu", {
              position: "top-right",
            });
  
            this.props.history.push("/content/comments/all");
  
          } else {
            cogoToast.warn("Müşteri Yorumu Oluşturulurken Hata Oluştu", {
              position: "top-right",
            });
          }
        }).catch((error) => {
          this.setState({
            addBlockLoad: false,
          });
          cogoToast.warn("Müşteri Yorumu Oluşturulurken Hata Oluştu", {
            position: "top-right",
          });

        })
  
  
  
    });
      
  
    }
    
  render() {
    return (
      <div>
        <Ustkisim />
        <div className="main-content side-content pt-0">
          <div className="container-fluid">
            <div id="global-loader-page">
              <div className="loader-img-page">
                <img src="/assets/img/loader.svg" />
                <div className="LoaderText">Lütfen Bekleyiniz...</div>
              </div>
            </div>
            <div className="inner-body">
              <PageHeader
                title="Müşteri Yorumu  Ekle"
                html={
                  <Link
                    to="/content/comments/all"
                    className="btn btn-white btn-icon-text my-2 mr-2"
                  >
                    <i className="fe fe-arrow-left mr-2" /> Müşteri Yorumlarına Geri Dön
                    Dön
                  </Link>
                }
              />
              <BlockUi
                loader={
                  <div className="loader-img-page-div">
                    <img alt="Resim" src="/assets/img/loader.svg" />
                    <div className="LoaderText-div">
                    Müşteri Yorumu Eklenirken Lütfen Bekleyiniz...
                    </div>
                  </div>
                }
                tag="div"
                className="row row-sm"
                blocking={this.state.addBlockLoad}
              >
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card ">
                    <div className="card-body">
                      <div className="row row-sm">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="row">
                            <div className="col-lg-8">
                              <div className="row">
                              <div className="form-group col-lg-12">
                                <label className="row">
                                  <div className="col-lg-6">Müşteri Yorumu</div>
                                </label>
                                <textarea
                                  rows="4"
                                  className="form-control"
                                  required
                                  placeholder="Müşteri Yorumu"
                                  name="comment"
                                  value={this.state.happy_customers.comment}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>

                              <div className="form-group col-lg-6">
                                <label className="row">
                                  <div className="col-lg-6">Müşteri Adı Soyadı</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Müşteri Adı Soyadı"
                                  name="name"
                                  value={this.state.happy_customers.name}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>


                              <div className="form-group col-lg-6">
                                <label className="row">
                                  <div className="col-lg-6">Pozisyonu</div>
                                </label>
                                <input
                                  className="form-control"
                                  required
                                  type="text"
                                  placeholder="Pozisyonu"
                                  name="position"
                                  value={this.state.happy_customers.position}
                                  onChange={(event) =>
                                    this.handleOnChange(event)
                                  }
                                />
                              </div>


                              </div>
                            
                            </div>

                            <BlockUi
                              loader={
                                <div className="loader-img-page-div">
                                  <img
                                    alt="Resim"
                                    src="/assets/img/loader.svg"
                                  />
                                  <div className="LoaderText-div">
                                    Resim Yüklenirken Lütfen Bekleyiniz...
                                  </div>
                                </div>
                              }
                              tag="div"
                              className="col-lg-4"
                              blocking={this.state.loadImage}
                            >
                              <div className="form-group">
                                <label className>Müşteri Resmi </label>
                                <div className="pos-relative">
                                <Dropzone onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (
                                      <section className="container">
                                        <div
                                          {...getRootProps({
                                            className: "dropzone",
                                          })}
                                        >
                                          <input {...getInputProps()} />
                                          <p>
                                            Müşteri Resmini Yüklemek İçin Tıklayın <br /> veya <br /> Resmi Buraya Sürükleyin
                                          </p>
                                        </div>
                                        {this.state.happy_customers.image ? (
                                        <div class="media d-block mt-3 text-center">
                                          <img
                                            src={this.state.happy_customers.image}
                                            className="wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0"
                                          />
                                          <button className="btn btn-warning wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 " onClick={this.kaldir}>Resmi Kaldır</button>
                                        </div>) : (<></>
                                        )}
                                      </section>
                                    )}
                                  </Dropzone>


                                </div>
                              </div>
                            </BlockUi>

                            <div className="col-lg-12">
                              <button className="btn ripple btn-main-primary pull-right" onClick={this.ekleComment}>
                                Yorumu Ekle
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                {this.state.happy_customers.image ? 
                <div className="col-lg-12 col-md-12">
                  <div className="card custom-card mavi">
                    <div className="card-body">
                      <div className="form-group">
                        <label className="row">
                          <div className="col-lg-6">Önizleme</div>
                        </label>
                        <div className="row row-sm">
                          <div className="col-md-12 col-lg-12 col-xl-12">
                         
                          <section class="section customers">
            <div class="container">
                <div class="wrapper">
                    <h2><b>X</b>’den fazla insan <b>abonesepeti</b>’ni kullanıyor.</h2>
                    <div class="customer-slider">
                        <div class="item">
                            <img src={this.state.happy_customers.image} alt="Ahmet Taner" />
                            <p>{this.state.happy_customers.comment}</p>
                            <div class="customer-name">
                            {this.state.happy_customers.name}
                                <span>{this.state.happy_customers.position}</span>
                            </div>
                        </div>
                        <div class="item">
                            <img src="https://loremflickr.com/640/360" alt="Ahmet Taner" />
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            <div class="customer-name">
                                Mehmet Pala
                                <span>Yönetici</span>
                            </div>
                        </div>
                        <div class="item">
                            <img src="https://loremflickr.com/640/360" alt="Ahmet Taner" />
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            <div class="customer-name">
                                Mehmet Pala
                                <span>Yönetici</span>
                            </div>
                        </div>
                      
                    </div>
                    <div class="customer-action">
                        <div class="dots">
                        </div>
                    </div>
                </div>
            </div>
        </section>
                         
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : <></>}

             </BlockUi>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(addBlog);
