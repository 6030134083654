import axios from 'axios';
import { setAuthorizationToken } from '../helpers/setAuthorizationToken';
import { config } from "../../config";




const login = (username, password) => {
    return axios.post(config().BASE_URL+"/api/logintest", { username, password })
        .then(user => {
            //eğer kullanıcı bulunursa (user.data.status = true) 
         
      
            if (user.data.status) {
            
                const item = {
                    value: user.data.data,
                    expiry: user.data.expiry,
                }
          
                localStorage.setItem("AtlasToken", JSON.stringify(item))


                 setAuthorizationToken(user.data.data);
            }
          
            return user.data;
        })
        .catch(err => console.log(err));
}

const logout = () => {
    localStorage.removeItem("AtlasToken");
    setAuthorizationToken(false);
}

export default { login, logout };