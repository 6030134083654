import React, { Component } from 'react';
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import {reactLocalStorage} from 'reactjs-localstorage';
import { Link,Redirect, Switch, Route, } from 'react-router-dom';
import { messaging } from "../../init-fcm";
import { config } from "../../config";
import ReactDom from 'react-dom';
import Notification  from 'react-web-notification';
import Yankisim from './Sidemenu';
import SelectSearch from 'react-select-search';
import { connect } from "react-redux";
import { login, logout } from "../../auth/actions/authAction";

import 'react-select-search/style.css';

window.React = React;



class Header extends Component {


    state  = {
      isDisconnected: false,
      BASE_URL   : config().BASE_URL,
      music : config().BASE_URL+"/sound.mp3" ,
      music2 :  config().BASE_URL+"/sound.ogg" ,
      ignore: true,
      islogout: false,
    title: ''
    }
  

  
  
  


  async componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);

    messaging.requestPermission()
      .then(async function() {
        const token = await messaging.getToken();
        console.log(token);
      })
      .catch(function(err) {
        console.log("Unable to get permission to notify.", err);
      });

  navigator.serviceWorker.addEventListener("message", payload => {
    
    if(this.state.ignore) {
      return;
    }

    let objs = payload.data;
    console.log(objs);
    let obj = {} ;
    if (typeof objs["firebase-messaging-msg-data"] === "undefined")
    {
       obj = objs.data ;
    }
    else 
    {
       obj = objs["firebase-messaging-msg-data"].data ;
    }


    const title = obj.title;
    const now = Date.now();
    const tag = now;
    const icon = 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png';
    let options = {
      body: obj.message,
      timestamp: +new Date,
      tag: tag,
      badge: obj.badge,
      icon: icon,
      sound: config().BASE_URL+"/sound.mp3", 
      data: {
          payloadr: {
      memberNotificationId: obj.memberNotificationId,		        click_action: obj.click_action
          }
      },
      path: obj.click_action
    }
    if (!!obj.imageUrl) {
      options["image"] = obj.imageUrl;
    }
    

    this.setState({
      title: title,
      options: options
    });
    
  });





  }

  


  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }


  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('//google.com', {
            mode: 'no-cors',
            })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing)
            });
          }).catch(() => this.setState({ isDisconnected: true }) )
        }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  }

  toggleMenu = () => {

    console.log(reactLocalStorage.get('sidemenu'));
    if(reactLocalStorage.get('sidemenu') ==  "1" )
    {
      reactLocalStorage.set('sidemenu',  "0");
      setTimeout(function(){ 
        window.dispatchEvent(new Event('resize'));
      
      }, 250);

  
    }
    else 
    {
      reactLocalStorage.set('sidemenu',  "1");
      setTimeout(function(){ 
        window.dispatchEvent(new Event('resize'));
      
      }, 250);
     

    } 
    if (window.matchMedia('(min-width: 768px)').matches) {
      $('body').toggleClass('main-sidebar-hide');
    } else {
      $('body').toggleClass('main-sidebar-show');
    }
  }


  handlePermissionGranted(){
    console.log('Permission Granted');
    this.setState({
      ignore: false
    });
  }
  handlePermissionDenied(){
    console.log('Permission Denied');
    this.setState({
      ignore: true
    });
  }
  handleNotSupported(){
    console.log('Web Notification not Supported');
    this.setState({
      ignore: true
    });
  }

  handleNotificationOnClick(e, tag){
    console.log(e, 'Notification clicked tag:' + tag);
  }

  handleNotificationOnError(e, tag){
    console.log(e, 'Notification error tag:' + tag);
  }

  handleNotificationOnClose(e, tag){
    console.log(e, 'Notification closed tag:' + tag);
  }

  handleNotificationOnShow(e, tag){
    this.playSound();
    console.log(e, 'Notification shown tag:' + tag);
  }

  playSound(filename){
    document.getElementById('sound').play();
  }

  handleButtonClick() {

    if(this.state.ignore) {
      return;
    }

    const now = Date.now();

    const title = 'React-Web-Notification' + now;
    const body = 'Hello' + new Date();
    const tag = now;
    const icon = 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png';
    // const icon = 'http://localhost:3000/Notifications_button_24.png';

    // Available options
    // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: 'en',
      dir: 'ltr',
      sound: './sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
    }
    this.setState({
      title: title,
      options: options
    });
  }

  handleButtonClick2() {
    this.props.swRegistration.getNotifications({}).then(function(notifications) {
      console.log(notifications);
    });
  }

   renderFriend =  (props, option, snapshot, className) =>{
    const imgStyle = {
        borderRadius: '50%',
        verticalAlign: 'middle',
        marginRight: 10,
    };
    console.log(option);
    return (
        <Link  {...props}   to={"/kolayiptal/talep/" + option.value}  className={className} type="button">
            <span><img alt="Resim" alt="" style={imgStyle} width="25" height="25" src={option.photo} /><span>{option.name}</span>
          
            </span>
        </Link>
    );
}

signOut = () => {
  this.props.dispatch(logout());
  this.setState({
    islogout: true
  });
};

  RemoteData = () => {
    return (
        <SelectSearch
            options={[]}
            
            getOptions={(query) => {
                return new Promise((resolve, reject) => {
                    fetch(config().BASE_URL+`/api/ki_users_search?=${query}`)
                        .then(response => response.json())
                        .then(({ data }) => {
                            resolve(data.map(({ id, full_name , type , photo }) => ({ value: id, name: full_name , type : type ,photo : photo})))
                            return false ;
                        })
                        .catch(reject);
                });
            }}
            renderOption={this.renderFriend}
            search
            closeOnSelect={false}
            printOptions="auto"
            emptyMessage={() => <div style={{ textAlign: 'center', fontSize: '0.8em' }}>Herhangi Bir Sonuç Bulunamadı</div>}
            placeholder="Arama Yapın"
        />
    );
   
          }

  render() {

    if (this.state.islogout) {
      return <Redirect to="/login" />;
    }
    const { match } = this.props;

   if(!reactLocalStorage.get('sidemenu'))
   {
    reactLocalStorage.set('sidemenu', "1")
   }


   if(reactLocalStorage.get('sidemenu') == "0")
   {
    if (window.matchMedia('(min-width: 768px)').matches) {
      $('body').removeClass('main-sidebar-hide');
    } else {
      $('body').addClass('main-sidebar-show');
    }
   }
   else 
   {
    if (window.matchMedia('(min-width: 768px)').matches) {
      $('body').addClass('main-sidebar-hide');
    } else {
      $('body').removeClass('main-sidebar-show');
    }
   }



    const { isDisconnected , music , music2} = this.state;
    
    return (
        <span>
         
         <Notification
          ignore={this.state.ignore && this.state.title !== ''}
          notSupported={this.handleNotSupported.bind(this)}
          onPermissionGranted={this.handlePermissionGranted.bind(this)}
          onPermissionDenied={this.handlePermissionDenied.bind(this)}
          onShow={this.handleNotificationOnShow.bind(this)}
          onClick={this.handleNotificationOnClick.bind(this)}
          onClose={this.handleNotificationOnClose.bind(this)}
          onError={this.handleNotificationOnError.bind(this)}
          timeout={5000}
          title={this.state.title}
          options={this.state.options}
          swRegistration={this.props.swRegistration}
        />
        <audio id='sound' preload='auto' muted>
          <source src='./sound.mp3' type='audio/mpeg' />
          <source src='./sound.ogg' type='audio/ogg' />
          <embed hidden={true} autostart='false' loop={false} muted src='./sound.mp3' />
        </audio>

        <Yankisim />
        <div className="main-header side-header sticky">
        <div className="container-fluid">

          { isDisconnected && (confirmAlert({
              title: 'İnternet Bağlantısı',
              message: 'İnternet bağlantınız stabil değildir, lütfen kontrol ederek tekrar deneyiniz...',
              buttons: [
                {
                  label: 'Tekrar Dene',
                  onClick: () => window.location.reload(false)
                }
              ]
            }))
          }

          <div className="main-header-left">
          {reactLocalStorage.get('sidemenu') ==  "1" ?  <Link  className="main-header-menu-icon"   onClick={() =>  this.toggleMenu()} ><span /></Link> : null}
           
          </div>
          <div className="main-header-center">
            <div className="responsive-logo">
              <a href="/"><img alt="Resim" src="/assets/img/brand/logo.png" className="mobile-logo" title="logo" /></a>
              <a href="/"><img alt="Resim" src="/assets/img/brand/logo.png" className="mobile-logo-dark" title="logo" /></a>
            </div>
            
            <div className="input-group">
            <select className="form-control aramakategori" style={{"width" :  "auto !important",    "borderRight": "0 !important"}}>
									<option label="Heryerde Ara" value="0">
                  Heryerde Ara
									</option>
									<option value="maya" data-select2-id="14">
									Satışlarda Ara
									</option>
									<option value="potansiyel" data-select2-id="15">
									Potansiyellerde Ara
									</option>
									<option value="ulak" data-select2-id="16">
									Gönderilerde Ara
									</option>
									<option value="kolayiptal" data-select2-id="17">
									Kolay İptal'de
									</option>
							
								</select>
              <div className="input-group-btn search-panel">
      
              {this.RemoteData()}
             
              </div>
              
             
            </div>
          </div>
          <div className="main-header-right">
            <div className="dropdown header-search">
              <a className="nav-link icon header-search">
                <i className="fe fe-search header-icons" />
              </a>
              <div className="dropdown-menu">
                <div className="main-form-search p-2">
                  <div className="input-group">
                    <div className="input-group-btn search-panel">
                      <select className="form-control select2-no-search">
                        <option label="All categories">
                        </option>
                        <option value="IT Projects">
                          IT Projects
                        </option>
                        <option value="Business Case">
                          Business Case
                        </option>
                        <option value="Microsoft Project">
                          Microsoft Project
                        </option>
                        <option value="Risk Management">
                          Risk Management
                        </option>
                        <option value="Team Building">
                          Team Building
                        </option>
                      </select>
                    </div>
                    <input type="search" className="form-control" placeholder="Search for anything..." />
                    <button className="btn search-btn"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx={11} cy={11} r={8} /><line x1={21} y1={21} x2="16.65" y2="16.65" /></svg></button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown d-md-flex">
              <a className="nav-link icon full-screen-link" >
                <i className="fe fe-maximize fullscreen-button fullscreen header-icons" />
                <i className="fe fe-minimize fullscreen-button exit-fullscreen header-icons" />
              </a>
            </div>
            <div className="dropdown main-header-notification">
      
              <a className="nav-link icon" href="true">
                <i className="fe fe-bell header-icons" />
                <span className="badge badge-danger nav-link-badge">4</span>
              </a>
              <div className="dropdown-menu">
                <div className="header-navheading">
                  <p className="main-notification-text">You have 1 unread notification<span className="badge badge-pill badge-primary ml-3">View all</span></p>
                </div>
                <div className="main-notification-list">
                  <div className="media new">
                    <div className="main-img-user online"><img alt="Resim" title="avatar" src="/assets/img/users/5.jpg" /></div>
                    <div className="media-body">
                      <p>Congratulate <strong>Olivia James</strong> for New template start</p><span>Oct 15 12:32pm</span>
                    </div>
                  </div>
                  <div className="media">
                    <div className="main-img-user"><img alt="Resim" title="avatar" src="/assets/img/users/2.jpg" /></div>
                    <div className="media-body">
                      <p><strong>Joshua Gray</strong> New Message Received</p><span>Oct 13 02:56am</span>
                    </div>
                  </div>
                  <div className="media">
                    <div className="main-img-user online"><img alt="Resim" title="avatar" src="/assets/img/users/3.jpg" /></div>
                    <div className="media-body">
                      <p><strong>Elizabeth Lewis</strong> added new schedule realease</p><span>Oct 12 10:40pm</span>
                    </div>
                  </div>
                </div>
                <div className="dropdown-footer">
                  <a href="true">View All Notifications</a>
                </div>
              </div>
            </div>
            <div className="main-header-notification">
              <a className="nav-link icon" href="true">
                <i className="fe fe-message-square header-icons" />
                <span className="badge badge-success nav-link-badge">6</span>
              </a>
            </div>
            <div className="dropdown main-profile-menu">
              <a className="d-flex" href="true">
                <span className="main-img-user"><img alt="Resim" title="avatar" src="/assets/img/users/1.jpg" /></span>
              </a>
              <div className="dropdown-menu">
                <div className="header-navheading">
                  <h6 className="main-notification-title">Sonia Taylor</h6>
                  <p className="main-notification-text">Web Designer</p>
                </div>
                <a className="dropdown-item border-top" href="true">
                  <i className="fe fe-user" /> My Profile
                </a>
                <a className="dropdown-item" href="true">
                  <i className="fe fe-edit" /> Edit Profile
                </a>
                <a className="dropdown-item" href="true">
                  <i className="fe fe-settings" /> Account Settings
                </a>
                <a className="dropdown-item" href="true">
                  <i className="fe fe-settings" /> Support
                </a>
                <a className="dropdown-item" href="true">
                  <i className="fe fe-compass" /> Activity
                </a>
                <Link className="dropdown-item" onClick={this.signOut} >
                  <i className="fe fe-power" /> Çıkış Yap
                </Link>
              </div>
            </div>
            <div className="dropdown d-md-flex header-settings">
              <a href="true" className="nav-link icon" data-toggle="sidebar-right" data-target=".sidebar-right">
                <i className="fe fe-align-right header-icons" />
              </a>
            </div>
            <button className="navbar-toggler navresponsive-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
              <i className="fe fe-more-vertical header-icons navbar-toggler-icon" />
            </button>
          </div>
        </div>
      </div>
      <div className="mobile-main-header">
    <div className="mb-1 navbar navbar-expand-lg  nav nav-item  navbar-nav-right responsive-navbar navbar-dark  ">
      <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
        <div className="d-flex order-lg-2 ml-auto">
          <div className="dropdown header-search">
            <a className="nav-link icon header-search">
              <i className="fe fe-search header-icons" />
            </a>
            <div className="dropdown-menu">
              <div className="main-form-search p-2">
                <div className="input-group">
                  <div className="input-group-btn search-panel">
                    <select className="form-control select2-no-search">
                      <option label="All categories">
                      </option>
                      <option value="IT Projects">
                        IT Projects
                      </option>
                      <option value="Business Case">
                        Business Case
                      </option>
                      <option value="Microsoft Project">
                        Microsoft Project
                      </option>
                      <option value="Risk Management">
                        Risk Management
                      </option>
                      <option value="Team Building">
                        Team Building
                      </option>
                    </select>
                  </div>
                  <input type="search" className="form-control" placeholder="Search for anything..." />
                  <button className="btn search-btn"><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx={11} cy={11} r={8} /><line x1={21} y1={21} x2="16.65" y2="16.65" /></svg></button>
                </div>
              </div>
            </div>
          </div>
          <div className="dropdown main-header-notification flag-dropdown">
            <a className="nav-link icon country-Flag">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx={256} cy={256} r={256} fill="#f0f0f0" /><g fill="#0052b4"><path d="M52.92 100.142c-20.109 26.163-35.272 56.318-44.101 89.077h133.178L52.92 100.142zM503.181 189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075 89.076h133.176zM8.819 322.784c8.83 32.758 23.993 62.913 44.101 89.075l89.074-89.075H8.819zM411.858 52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177l89.076-89.075zM100.142 459.079c26.163 20.109 56.318 35.272 89.076 44.102V370.005l-89.076 89.074zM189.217 8.819c-32.758 8.83-62.913 23.993-89.075 44.101l89.075 89.075V8.819zM322.783 503.181c32.758-8.83 62.913-23.993 89.075-44.101l-89.075-89.075v133.176zM370.005 322.784l89.075 89.076c20.108-26.162 35.272-56.318 44.101-89.076H370.005z" /></g><g fill="#d80027"><path d="M509.833 222.609H289.392V2.167A258.556 258.556 0 00256 0c-11.319 0-22.461.744-33.391 2.167v220.441H2.167A258.556 258.556 0 000 256c0 11.319.744 22.461 2.167 33.391h220.441v220.442a258.35 258.35 0 0066.783 0V289.392h220.442A258.533 258.533 0 00512 256c0-11.317-.744-22.461-2.167-33.391z" /><path d="M322.783 322.784L437.019 437.02a256.636 256.636 0 0015.048-16.435l-97.802-97.802h-31.482v.001zM189.217 322.784h-.002L74.98 437.019a256.636 256.636 0 0016.435 15.048l97.802-97.804v-31.479zM189.217 189.219v-.002L74.981 74.98a256.636 256.636 0 00-15.048 16.435l97.803 97.803h31.481zM322.783 189.219L437.02 74.981a256.328 256.328 0 00-16.435-15.047l-97.802 97.803v31.482z" /></g></svg>
            </a>
            <div className="dropdown-menu">
              <a href="true" className="dropdown-item d-flex ">
                <span className="avatar  mr-3 align-self-center bg-transparent"><img alt="Resim" src="/assets/img/flags/french_flag.jpg" title="img" /></span>
                <div className="d-flex">
                  <span className="mt-2">French</span>
                </div>
              </a>
              <a href="true" className="dropdown-item d-flex">
                <span className="avatar  mr-3 align-self-center bg-transparent"><img alt="Resim" src="/assets/img/flags/germany_flag.jpg" title="img" /></span>
                <div className="d-flex">
                  <span className="mt-2">Germany</span>
                </div>
              </a>
              <a href="true" className="dropdown-item d-flex">
                <span className="avatar mr-3 align-self-center bg-transparent"><img alt="Resim" src="/assets/img/flags/italy_flag.jpg" title="img" /></span>
                <div className="d-flex">
                  <span className="mt-2">Italy</span>
                </div>
              </a>
              <a href="true" className="dropdown-item d-flex">
                <span className="avatar mr-3 align-self-center bg-transparent"><img alt="Resim" src="/assets/img/flags/russia_flag.jpg" title="img" /></span>
                <div className="d-flex">
                  <span className="mt-2">Russia</span>
                </div>
              </a>
              <a href="true" className="dropdown-item d-flex">
                <span className="avatar  mr-3 align-self-center bg-transparent"><img alt="Resim" src="/assets/img/flags/spain_flag.jpg" title="img" /></span>
                <div className="d-flex">
                  <span className="mt-2">spain</span>
                </div>
              </a>
            </div>
          </div>
          <div className="dropdown ">
            <a className="nav-link icon full-screen-link">
              <i className="fe fe-maximize fullscreen-button fullscreen header-icons" />
              <i className="fe fe-minimize fullscreen-button exit-fullscreen header-icons" />
            </a>
          </div>
          <div className="dropdown main-header-notification">
            <a className="nav-link icon" >
              <i className="fe fe-bell header-icons" />
              <span className="badge badge-danger nav-link-badge">4</span>
            </a>
            <div className="dropdown-menu">
              <div className="header-navheading">
                <p className="main-notification-text">You have 1 unread notification<span className="badge badge-pill badge-primary ml-3">View all</span></p>
              </div>
              <div className="main-notification-list">
                <div className="media new">
                  <div className="main-img-user online"><img alt="Resim" title="avatar" src="/assets/img/users/5.jpg" /></div>
                  <div className="media-body">
                    <p>Congratulate <strong>Olivia James</strong> for New template start</p><span>Oct 15 12:32pm</span>
                  </div>
                </div>
                <div className="media">
                  <div className="main-img-user"><img alt="Resim" title="avatar" src="/assets/img/users/2.jpg" /></div>
                  <div className="media-body">
                    <p><strong>Joshua Gray</strong> New Message Received</p><span>Oct 13 02:56am</span>
                  </div>
                </div>
                <div className="media">
                  <div className="main-img-user online"><img alt="Resim" title="avatar" src="/assets/img/users/3.jpg" /></div>
                  <div className="media-body">
                    <p><strong>Elizabeth Lewis</strong> added new schedule realease</p><span>Oct 12 10:40pm</span>
                  </div>
                </div>
              </div>
              <div className="dropdown-footer">
                <a href="true">View All Notifications</a>
              </div>
            </div>
          </div>
          <div className="main-header-notification mt-2">
            <a className="nav-link icon" href="chat.html">
              <i className="fe fe-message-square header-icons" />
              <span className="badge badge-success nav-link-badge">6</span>
            </a>
          </div>
          <div className="dropdown main-profile-menu">
            <a className="d-flex" href="true">
              <span className="main-img-user"><img alt="Resim" title="avatar" src="/assets/img/users/1.jpg" /></span>
            </a>
            <div className="dropdown-menu">
              <div className="header-navheading">
                <h6 className="main-notification-title">Sonia Taylor</h6>
                <p className="main-notification-text">Web Designer</p>
              </div>
              <a className="dropdown-item border-top" href="profile.html">
                <i className="fe fe-user" /> My Profile
              </a>
              <a className="dropdown-item" href="profile.html">
                <i className="fe fe-edit" /> Edit Profile
              </a>
              <a className="dropdown-item" href="profile.html">
                <i className="fe fe-settings" /> Account Settings
              </a>
              <a className="dropdown-item" href="profile.html">
                <i className="fe fe-settings" /> Support
              </a>
              <a className="dropdown-item" href="profile.html">
                <i className="fe fe-compass" /> Activity
              </a>
              <a className="dropdown-item" href="signin.html">
                <i className="fe fe-power" /> Sign Out
              </a>
            </div>
          </div>
          <div className="dropdown  header-settings">
            <a href="true" className="nav-link icon" data-toggle="sidebar-right" data-target=".sidebar-right">
              <i className="fe fe-align-right header-icons" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
     </span>
    )
  }
}
const mapStateToProps = state => {
  const { isAuthenticated, error, errorMessage, user } = state.auth;
  return {
      isAuthenticated,
      error,
      errorMessage,
      user
  }
}

export default connect(mapStateToProps)(Header);
 
