import React, { Component, createRef } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import Dropzone from "react-dropzone";

const dropzoneRef = createRef();
const open = () => {
  if (dropzoneRef.current) {
    dropzoneRef.current.open();
  }
};

class ArrayItems extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const SortableItem = sortableElement(
      ({ value, collection, index, sira, ust, handleClick, alt }) => (
        <li>
          <div className="card custom-card p-0" key={index}>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <label className>Başlık </label>
                  <input
                    className="form-control"
                    required
                    type="text"
                    name="name"
                    onBlur={(event) =>
                      this.props.guncelle(ust, event, sira + 1, alt)
                    }
                    defaultValue={value.name}
                    placeholder={value.name}
                  />
                </div>
                <div className="col-lg-4">
                  <label className>Açıklama</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    name="description"
                    onBlur={(event) =>
                      this.props.guncelle(ust, event, sira + 1, alt)
                    }
                    defaultValue={value.description}
                    placeholder={value.description}
                  />
                </div>
                <div className="col-lg-3 arraicon">
                  <label className>İkon</label>
                  <Dropzone
                    onDrop={(files) => this.props.upload(files, ust, alt, sira)}
                  >
                    {({ getRootProps, getInputProps }) =>
                      value.icon ? (
                        <div className="media d-block mt-3 text-center">
                          <img
                            src={value.icon}
                            className="wd-50p wd-sm-50 mg-sm-r-20 mg-b-20 mg-sm-b-0"
                          />
                          <button
                            className="btn btn-dark wd-100p wd-sm-200  mg-sm-r-20 mg-b-20 mg-sm-b-0 mt-3 btn-sm"
                            onClick={(files) =>
                              this.props.deleteIcon(ust, alt, sira)
                            }
                          >
                            Resmi Kaldır
                          </button>
                        </div>
                      ) : (
                        <div
                          {...getRootProps({
                            className: "dropzone",
                          })}
                        >
                          <input {...getInputProps()} />

                          <button type="button" onClick={open}>
                            Resim Dosyası Seçiniz
                          </button>
                        </div>
                      )
                    }
                  </Dropzone>
                </div>
                <div className="col-lg-1">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={(event) => this.props.delete(ust, sira, alt)}
                  >
                    <i className="fe fe-trash-2"></i> Sil
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      )
    );

    const SortableContainer = sortableContainer(({ children }) => {
      return <div>{children}</div>;
    });

    return (
      <SortableContainer onSortEnd={this.props.onSortEnd}>
        <ul className="SortableList">
          {this.props.data.map((alt_template, is) => (
            <SortableItem
              className="SortableItem"
              key={"item" + is}
              value={alt_template}
              index={is}
              sira={is}
              ust={this.props.ust}
              alt={this.props.alt}
              collection={{
                alt: this.props.alt,
                ust: this.props.ust,
                sira: is,
              }}
            ></SortableItem>
          ))}
        </ul>
      </SortableContainer>
    );
  }
}

export default ArrayItems;
